import React, { useState, useEffect } from "react";
import { EyeInvisibleOutlined, EyeTwoTone, ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import {
    Form,
    Row,
    Col,
    Input,
    Button,
    Modal,
    Spin,
    Tooltip,
    Radio,
    Upload,
    message
} from "antd";
import axios from "../../utils/axios";
import assembler from "url-assembler";
import CopyToClipboard from "react-copy-to-clipboard";
import { API_GET_KEY_CONFIG, API_SAVE_KEY_CONFIG } from "../../utils/webConstants";
import { useSelector } from "react-redux";
import { USERTYPE } from "../../utils/appConstants";


const KeyConfirmationModel = ({ showKeyManagementConfirmationModal, setShowKeyManagementConfirmationModal, serviceRequestId, setSelectedServiceRequestId, selectedServiceRequestName, setSelectedServiceRequestName, userType }) => {

    const loginedUser = useSelector((state) => state.userLogin);
    let userToken = "";
    if (loginedUser.userInfo)
        userToken = loginedUser.userInfo.userToken
    const [loading, setLoading] = useState(true);
    const [keyConfirmationForm] = Form.useForm();
    const [userManagedKeys, setUserManagedkeys] = useState(false);
    const [publicKeyFile, setPublicKeyFile] = useState([]);
    const [publicKey, setPublicKey] = useState(null);

    const handleModalCancel = () => {
        setShowKeyManagementConfirmationModal(false);
    };

    const handleModalOK = (values) => {
        keyConfirmationForm.validateFields().then(async (values) => {
            setLoading(true);
            let url = assembler(API_SAVE_KEY_CONFIG).param({ serviceRequestId });
            const requestBody = {
                keyManagement: values.keyManagement,
                publicKey,
            }
            await axios.post(url, requestBody, { headers: { authorization: userToken } });
            message.success(`Key Management Configuration Successfully Saved`);
            setLoading(false);
            setShowKeyManagementConfirmationModal(false);
        })
    };

    const getKeyConfig = async (serviceRequestId) => {
        // try {
        //     setLoading(true);
        //     let url = assembler(API_GET_KEY_CONFIG).param({ serviceRequestId });
        //     const response = await axios.get(url, { headers: { authorization: userToken } });
        //     keyForm.setFieldsValue({ iv: response.data.payload.encryptedIv })
        //     keyForm.setFieldsValue({ secretkey: response.data.payload.encryptedSecret })
        //     setLoading(false);
        //     setSecretKey(response.data.payload.encryptedSecret);
        //     setIV(response.data.payload.encryptedIv);
        // } catch (error) {
        //     console.log("Error :: ", error.message);
        // }
    };


    useEffect(() => {
        // getKeyConfig(serviceRequestId);
        setLoading(false);
    }, []);

    const onRadioButtonChange = (e) => {
        if (e.target.value === "userManaged") {
            setUserManagedkeys(true);
            // keyConfirmationForm.setFieldsValue({LNPublicKey: LNPublicKey, keyManagement: "userManaged"})
        } else {
            setUserManagedkeys(false);
        }
    }

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const handleKeyUploadChange = (info) => {
        console.log("setPublicKey ::: handleKeyUploadChange==============")
        setPublicKeyFile(info.fileList);
        let reader = new FileReader();
        reader.onload = (e) => {
            // keyConfirmationForm.setFieldsValue({uploadPublicKey: e.target.result.toString()})
            setPublicKey(e.target.result.toString());
            console.log("pkey :: ", e.target.result.toString())
            // dataTypeForm.setFieldsValue({uploadPublicKey: e.target.result.toString()})
        }
        reader.readAsText(info.file.originFileObj);
    };

    const customRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return (
        <Modal
            open={showKeyManagementConfirmationModal}
            title={selectedServiceRequestName}
            form="keyConfirmationForm"
            onCancel={handleModalCancel}
            onOk={handleModalOK}
            footer={[
                <Button key="back" onClick={handleModalCancel}>
                    Cancel
                </Button>,
                <Button key="back" onClick={handleModalOK}>
                    Submit
                </Button>
            ]}
        >
            <Spin spinning={loading} tip="Loading...">
                <Form id="keyConfirmationForm" form={keyConfirmationForm} layout="vertical" initialValues={{ keyManagement: "systemManaged" }}>
                    {userType === USERTYPE.CUSTOMER && <Form.Item name="keyManagement" label={
                        <div>
                            {"Manage Keys "}
                            <Tooltip title={`Files are encrypted before upload. By selecting System Managed Keys, the system will manage the encryption/decryption keys. By selecting User Managed Keys, you must provide encryption/decryption key details.`} color="blue">
                                <ExclamationCircleOutlined
                                    className="information-icon" />
                            </Tooltip>
                        </div>
                    } rules={[
                        {
                            required: true,
                            message: "Please select",
                        },
                    ]}>
                        <Radio.Group onChange={onRadioButtonChange}>
                            <Radio value={"systemManaged"} name="systemManagedKey">System Managed Keys</Radio>
                            <Radio value={"userManaged"} name="userManagedKey">User Managed Keys</Radio>
                        </Radio.Group>
                    </Form.Item>}
                    {userType === USERTYPE.CUSTOMER && userManagedKeys && <Form.Item
                        name="uploadPublicKey"
                        label=""
                        getValueFromEvent={normFile}
                        className="fileUploadLabel"
                        rules={[
                            {
                                required: true,
                                message: "Please upload",
                            },
                        ]}
                    >

                        <Upload
                            onChange={handleKeyUploadChange}
                            multiple={false}
                            fileList={publicKeyFile}
                            customRequest={customRequest}
                        >
                            <Button icon={<UploadOutlined />} style={{ fontWeight: "bold" }}>
                                Click to Upload Public Key
                            </Button>
                        </Upload >
                    </Form.Item>}



                </Form>
            </Spin>
        </Modal>
    );
};
export default KeyConfirmationModel;

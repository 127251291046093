import React, { useState, useEffect } from "react";
import { FileOutlined, DownloadOutlined } from "@ant-design/icons";
import { Row, Col, Button, Table, message, Spin } from "antd";
import { useParams, Link, useHistory } from "react-router-dom";
import Pagination from "../components/Pagination";
import { useSelector } from "react-redux";
import FileDownload from '../components/FileDownload';
import EmptyTable from '../components/EmptyTable';

//import ViewFile from "../components/ViewFile";

import {
    getFormattedDateTime, getRoundedFileSize
} from '../../utils/sharedUtils';


import {
    REQUEST_DETAIL_TABS, FILES_TO_APPROVE_COMPONENT
} from "../../utils/appConstants";

const {
    NO_FILES, INVALID_FILE_ID, DEACTIVATE_USER_REQUEST, DOWNLOAD, STATUSES, TASKS
} = FILES_TO_APPROVE_COMPONENT;

/*const extDoc = [
    'pdf', 'xml', 'json', 'csv', 'html', 'pdf', 'png', 'jpg', 'jpeg', 'gif',
    'txt', 'docx', 'doc', 'ppt', 'pptx', 'xls', 'xlsx'
];*/

const FilesToApprove = (props) => {
    const {
        requestId, /*taskId,*/ email, userType, customerEmailData, fileType,
        setSelectedFiles, setCanApprove, associatedEmployees, isClosedRequest,
        isServiceReqExternal, managerFiles, setFolderList, filesToApprove,
        fileRetentionPeriod, setRecordType, setSelectedApproveFiles
    } = props;

    const { userInfo } = useSelector((state) => state.userLogin);
    const userToken = userInfo?.userToken ?? '';

    let history = useHistory();
    const [loadingFilesTab, setLoadingFilesTab] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const params = useParams();
    const [selectedFilesForDownload, setSelectedFilesForDownload] = useState([]);
    const [tasksToApproveFiles, setTasksToApproveFiles] = useState([]);
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
    const [fileIdForDownload, setFileIdForDownload] = useState([]);

    /*const [isOpenViewFile, setIsOpenViewFile] = useState(false);
    const [fileId, setFileId] = useState(null);
    const [fileName, setFileName] = useState("");*/

    useEffect(() => {
        setTasksToApproveFiles(filesToApprove.filter(
            e => Object.values(STATUSES).includes(e.status)
        ));
    }, []);

    const fileSizeColumn = {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        width: 105,
        render: (text, record) => getRoundedFileSize(record.size)
    };

    const actionColumn = {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: 200,
        render: (text, record) => {
            return (
                <Row>
                    {/*
                        extDoc.indexOf(record.name.split('.').pop()) !== -1
                            ? (
                                <Col span="10">
                                    <Link
                                        hidden={true} to="#"
                                        style={{ textDecoration: "none" }}
                                        onClick={() => {
                                            setFileId(record.id);
                                            setFileName(record.name);
                                            setIsOpenViewFile(true);
                                        }}
                                    >
                                        <FileOutlined className="fileIcon" />&nbsp;Preview
                                    </Link>
                                </Col>
                            )
                            : ''
                    */}
                    <Col span="13">
                        <Link
                            to="#" style={{ textDecoration: "none" }}
                            onClick={() => handleDownload(record.id)}
                        >
                            <DownloadOutlined />&nbsp;{DOWNLOAD}
                        </Link>
                    </Col>
                </Row>
            );
        }
    };

    const fileColumns = [
        {
            title: "Task Required",
            dataIndex: "TaskRequired",
            key: "TaskRequired",
            width: "40%",
            render: (text, record) => {
                if (record?.type === DEACTIVATE_USER_REQUEST) {
                    return TASKS.APPROVE_EXTERNAL_USER_DEACTIVATION;
                }
                else if (record?.status === STATUSES.TO_BE_REVIEWED_EXEMPT_FILE_RETENTION_PERIOD) {
                    const fileRetentionPeriodValue = (fileRetentionPeriod)
                        ? fileRetentionPeriod
                        : "";

                    const taskValue = TASKS.APPROVE_FILE_RETENTION_PERIOD_EXTENSION.replace(
                        '<FILE_RETENTION_PERIOD_VALUE>', fileRetentionPeriodValue
                    );

                    return taskValue;
                }
                else if (record?.status === STATUSES.TO_BE_REVIEWED) {
                    return TASKS.REVIEW_FILE_FOR_EXTERNAL_USER;
                }

                return '';
            }
        },
        {
            title: "File Name/User Email",
            dataIndex: "name",
            key: "name",
            render: (name, record) => {
                if (record?.type === DEACTIVATE_USER_REQUEST) {
                    return `${record.customerEmail}`;
                }
                else {
                    return (
                        <div className="deleteAction">
                            <Link
                                to="#" style={{ textDecoration: "none" }}
                                onClick={() => handleDownload(record.id)}
                            >
                                <FileOutlined style={{ height: "17px" }} /> {record.name}
                            </Link>
                        </div>
                    );
                }
            }
        },
        {
            title: "Last Modified On",
            dataIndex: "modifiedOn",
            key: "modifiedOn",
            className: "formatDate",
            render: (modifiedOn, record) => getFormattedDateTime(record.modifiedOn)
        },
        {
            title: "Last Modified By",
            dataIndex: "modifiedBy",
            key: "modifiedBy",
            render: (text, record) => {
                if (record?.type === DEACTIVATE_USER_REQUEST) {
                    return `${record.modifiedByName}`;
                }

                let userName = record.modifiedBy;

                const customerObject = customerEmailData.find(
                    o => o.email === userName
                );

                if (customerObject?.user) {
                    const { firstName, lastName } = customerObject.user;
                    userName = `${firstName} ${lastName}`;
                }
                else {
                    const employeeObject = associatedEmployees.find(
                        o => o.email === userName
                    );

                    if (employeeObject) userName = employeeObject?.name;
                }

                return `${userName}`;
            }
        },
    ];

    const rowSelection = {
        type: 'radio',
        selectedRowKeys,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedFilesForDownload(selectedRows);
            setSelectedApproveFiles(selectedRows);

            if (selectedRows?.[0]?.type === DEACTIVATE_USER_REQUEST) {
                setRecordType(DEACTIVATE_USER_REQUEST);
            }
        },
    };

    const onPaginate = (page, pageSize) => {
        setPage(page);
        setLimit(pageSize);
    };

    /*const handleViewFileModalChange = () => {
        setIsOpenViewFile(false);
    };*/

    const openDownloadModal = () => setIsDownloadModalOpen(true);

    const resetFileIdForDownload = () => setFileIdForDownload([]);

    const closeDownloadModal = () => {
        setIsDownloadModalOpen(false);
        resetFileIdForDownload();
    };

    const handleDownload = (fileId) => {
        const currentFileId = (Number.isInteger(fileId))
            ? fileId
            : selectedFilesForDownload?.[0]?.id;

        const isFileIdValid = filesToApprove.some(e => e.id === currentFileId);

        if (isFileIdValid) {
            setFileIdForDownload([currentFileId]);
            openDownloadModal();
        }
        else {
            message.error(INVALID_FILE_ID);
        }
    };

    const downloadModal = (
        <FileDownload
            closeDownloadModal={closeDownloadModal}
            isModalOpen={isDownloadModalOpen}
            fileIds={fileIdForDownload}
            requestId={requestId}
            parentTab={REQUEST_DETAIL_TABS.TASKS}
        />
    );

    const downloadModalContent = (isDownloadModalOpen) ? downloadModal : null;

    const tableColumnsContent = (tasksToApproveFiles?.length > 0)
        ? [...fileColumns, fileSizeColumn, actionColumn]
        : fileColumns;

    return (
        <>
            <Spin spinning={loadingFilesTab} tip="Loading..." size="large">
                <Row>
                    <Col
                        xs={filesToApprove.length > 0 ? 24 : 24}
                        sm={filesToApprove.length > 0 ? 20 : 24}
                        md={filesToApprove.length > 0 ? 24 : 24}
                        lg={filesToApprove.length > 0 ? 20 : 24}
                        xl={filesToApprove.length > 0 ? 24 : 24}
                    >
                        <Table
                            locale={{ emptyText: <EmptyTable text={NO_FILES} /> }}
                            columns={tableColumnsContent}
                            rowSelection={rowSelection}
                            dataSource={filesToApprove}
                            scroll={{ x: 800, y: 450 }}
                            pagination={false}
                            bordered={true}
                            rowKey="id"
                        />

                        <br />

                        <div align="right">
                            <Row>
                                <Col span="10" align="left">
                                    <Button
                                        size="small"
                                        type="default"
                                        onClick={handleDownload}
                                        className="buttonWithImage"
                                        style={{ fontWeight: "bold" }}
                                        disabled={selectedRowKeys.length > 0 ? false : true}
                                        icon={<DownloadOutlined style={{ verticalAlign: 'baseline' }} />}
                                    >
                                        {DOWNLOAD}
                                    </Button>
                                </Col>
                            </Row>
                        </div>

                        <div align="center">
                            <Pagination
                                total={total}
                                defaultPageSize={10}
                                defaultCurrent={page}
                                onChange={onPaginate}
                            />
                        </div>

                        {/*
                            (isOpenViewFile)
                                ? (
                                    <ViewFile
                                        isOpenViewFile={isOpenViewFile}
                                        onChange={handleViewFileModalChange}
                                        fileId={fileId}
                                        fileName={fileName}
                                        requestId={requestId}
                                    />
                                ) : null
                        */}

                        {downloadModalContent}
                    </Col>
                </Row>
            </Spin>
        </>
    );
};

export default FilesToApprove;

import React from "react";
import { useSelector } from "react-redux";
import { UI_ROUTES } from '../utils/webConstants';
import { Route, Redirect } from "react-router-dom";

const { BASE, LOGIN } = UI_ROUTES;

export default function UserRoute({ children, ...rest }) {
    const { userInfo } = useSelector((state) => state.userLogin);
    const activeRequestsRoute = '/requests/active?page=1&limit=10';

    if (!userInfo?.userToken) return <Redirect to={LOGIN} />;

    else if (rest.path === BASE) return <Redirect to={activeRequestsRoute} />;

    else return <Route {...rest} />;
}
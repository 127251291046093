import jwt_decode from "jwt-decode";
import axios from "../../utils/axios";
import ACTION_TYPES from "../actionTypes/userActionType";
import { msalApp, AUTH_SCOPES } from "../../utils/authUtils";
import { API_ROUTES } from "../../utils/webConstants";

import {
  SESSION_STORAGE_KEYS, AXIOS_CONFIG_VALUES
} from "../../utils/appConstants";

const { USER_LOGIN } = SESSION_STORAGE_KEYS;

const { CONTENT_TYPE, APPLICATION_JSON, AUTHORIZATION } = AXIOS_CONFIG_VALUES;

const contentHeader = {
  headers: { [CONTENT_TYPE]: [APPLICATION_JSON] }
};

export const register = (
  firstName, lastName, email, password, confirmPassword
) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_TYPES.USER_REGISTER_REQUEST });

    const config = contentHeader;

    const { data } = await axios.post(
      API_ROUTES.REGISTER,
      { firstName, lastName, email, password, confirmPassword },
      config
    );

    dispatch({
      type: ACTION_TYPES.USER_REGISTER_SUCCESS,
      payload: data
    });
  }
  catch (error) {
    dispatch({
      type: ACTION_TYPES.USER_REGISTER_FAIL,
      payload: error?.response?.data.message ?? error.message
    });
  }
};

export const verifyEmail = (email) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.VERIFY_EMAIL,
    emailToVerify: { email }
  });
};

export const login = (email, password, threatmatrixSessionId) => async (
  dispatch
) => {
  try {
    dispatch({ type: ACTION_TYPES.USER_LOGIN_REQUEST });

    const config = contentHeader;

    const { data } = await axios.post(
      API_ROUTES.LOGIN, { email, password, threatmatrixSessionId }, config
    );

    sessionStorage.setItem(USER_LOGIN, JSON.stringify(data.payload));

    try {
      // decoding the token
      const decoded = jwt_decode(data.payload.token);

      const payload = {
        token: data.payload.token,
        sessionId: data.payload.sessionId ? data.payload.sessionId : null,
        emailId: decoded.emailId,
        name: decoded.name
      };

      if (decoded.role) payload.role = decoded.role;

      dispatch({
        type: ACTION_TYPES.USER_LOGIN_SUCCESS,
        payload: payload,
      });
    }
    catch (error) {
      throw error;
    }
  }
  catch (error) {
    dispatch({
      type: ACTION_TYPES.USER_LOGIN_FAIL,
      payload: error?.response?.data.message ?? error.message
    });
  }
};

export const microsoftLogin = (email) => async (dispatch) => {
  try {
    const loginResponse = await msalApp.loginRedirect({
      scopes: [AUTH_SCOPES.OPENID, AUTH_SCOPES.PROFILE],
      extraQueryParameters: {
        ui_locales: sessionStorage.getItem('language') ?? 'sv',
      },
    });

    if (loginResponse) dispatch({ type: ACTION_TYPES.USER_LOGIN_REQUEST });
  }
  catch (error) {
    dispatch({
      type: ACTION_TYPES.USER_LOGIN_FAIL,
      payload: error?.response?.data.message ?? error.message
    });
  }
};

export const acquireToken = (request, redirect) => async (dispatch) => {
  try {
    const loginResponse = await msalApp.acquireTokenSilent(request);

    if (loginResponse) {
      sessionStorage.setItem("loginMedium", "MICROSOFT");

      dispatch({ type: ACTION_TYPES.USER_LOGIN_REQUEST });
    }
  }
  catch (error) {
    dispatch({
      type: ACTION_TYPES.USER_LOGIN_FAIL,
      payload: error?.response?.data.message ?? error.message
    });
  }
};

export const logout = () => async (dispatch) => {
  localStorage.clear();
  sessionStorage.clear();

  dispatch({ type: ACTION_TYPES.USER_LOGOUT });
};

export const forgotPassword = (emailId) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_TYPES.USER_FORGOT_PASSWORD_REQUEST });

    const config = contentHeader;

    const { data } = await axios.post(
      API_ROUTES.FORGOT_PASSWORD, { emailId }, config
    );

    dispatch({
      type: ACTION_TYPES.USER_FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });
  }
  catch (error) {
    dispatch({
      type: ACTION_TYPES.USER_FORGOT_PASSWORD_FAIL,
      payload: error?.response?.data.message ?? error.message
    });
  }
};

export const resetPassword = (token, password, sessionId) => async (
  dispatch
) => {
  try {
    dispatch({ type: ACTION_TYPES.USER_RESET_PASSWORD_REQUEST });

    const config = {
      headers: { [AUTHORIZATION]: token }
    };

    const { data } = await axios.post(
      API_ROUTES.RESET_PASSWORD, { password, sessionId }, config
    );

    dispatch({
      type: ACTION_TYPES.USER_RESET_PASSWORD_SUCCESS,
      payload: data
    });
  }
  catch (error) {
    dispatch({
      type: ACTION_TYPES.USER_RESET_PASSWORD_FAIL,
      payload: error?.response?.data.message ?? error.message
    });
  }
};

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import lodashUniqBy from 'lodash/uniqBy';
import axios from "../../utils/axios";
import jwt_decode from "jwt-decode";
import assembler from "url-assembler";
import { Link } from "react-router-dom";
import { API_ROUTES } from "../../utils/webConstants";
import DebounceSelect from "../components/DebounceSelect";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";

import {
    Form, Input, Button, Select, Table, Collapse, Space, Spin, AutoComplete,
    Popconfirm, message
} from "antd";

import {
    PARTICIPANTS_COMPONENT, USERTYPE, AXIOS_CONFIG_VALUES
} from "../../utils/appConstants";

const {
    PARENT_DL, DL_NOT_AVAILABLE, DL_TYPES, PLACEHOLDERS, LABELS, MESSAGES
} = PARTICIPANTS_COMPONENT;

const { Panel } = Collapse;
const { Option } = AutoComplete;

const Participants = (props) => {
    const {userType} = props;
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState(undefined);
    const [participantEmail, setParticipantEmail] = useState("");
    const [isParticipantLoading, setIsParticipantLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [emailOrDLList, setEmailOrDLList] = useState([]);

    const { userInfo } = useSelector((state) => state.userLogin);
    const userToken = userInfo?.userToken ?? '';
    const decodedToken = (userToken !== "") ? jwt_decode(userToken) : {};
    const userEmail = decodedToken?.emailId ? decodedToken.emailId : "";
    const axiosAuthHeader = { [AXIOS_CONFIG_VALUES.AUTHORIZATION]: userToken };

    const selectEmployeeLabelText = (props.isServiceReqExternal)
        ? LABELS.SELECT_EMPLOYEES_FOR_REQUEST.EXTERNAL
        : LABELS.SELECT_EMPLOYEES_FOR_REQUEST.INTERNAL;

    const getRoles = async () => {
        try {
            const url = assembler(API_ROUTES.GET_ROLES);
            const res = await axios.get(url, { headers: axiosAuthHeader });
            setRoles(res.data.payload);
        }
        catch (error) {
            message.error(MESSAGES.FAILED_TO_GET_ROLES);
            console.error(error);
        }
    }

    useEffect(() => {
        if (userType && userType !== USERTYPE.CUSTOMER) getRoles();
    }, []);

    const handleRoleChange = (value, option) => setRole(option);

    const checkEmailWithInitiatorEmail = (email, role) => {
        let isSameInitiatorEmail = false;
        const isInitiatorEmailId = (email === props.initiatorEmailId);

        for (let i = 0; i < role.length; i++) {
            if (isInitiatorEmailId && role[i].children === USERTYPE.FILE_APPROVER) {
                isSameInitiatorEmail = true;
            }
        }

        return !isSameInitiatorEmail;
    };

    const handleEmpRole = async () => {
        if (!participantEmail || participantEmail.length === 0) {
            setIsError(true);
            setErrorMessage(MESSAGES.ADD_AN_EMPLOYEE_AND_A_ROLE);
            return;
        }

        if (props.isServiceReqExternal && participantEmail && participantEmail.length > 0 && (!role || role.length === 0)) {
            setIsError(true);
            setErrorMessage(MESSAGES.SELECT_INTERNAL_EMPLOYEE_ROLE);
            return;
        }

        setIsParticipantLoading(false);
        setIsError(false);

        const obj = treeViewToArray(props.tableData);
        const upDatedTableData = obj;

        if (!participantEmail || participantEmail.length === 0) return;

        let count = 0;
        let sameAsInitiatorEmail = false;

        participantEmail.forEach(ele => {
            if (ele.value === "" || (props.isServiceReqExternal && !role)) {
                props.onChange({
                    ParticipantsList: lodashUniqBy(upDatedTableData, v => [v.email, v.roleId].join()),
                    error: isError
                });
            }
            else {
                if (!checkEmailWithInitiatorEmail(ele.value, role ? role : [])) {
                    setIsError(true);
                    setErrorMessage(MESSAGES.INITIATOR_CANNOT_BE_FILE_APPROVER);
                    return false;
                }

                setIsParticipantLoading(true);

                let fullfillerNetworkId = "null";
                let fullfillers;
                let message = "";

                if (props.isServiceReqExternal) {
                    const fulfiller = role.filter(
                        x => x.children === USERTYPE.FULFILLER
                    );

                    const reviewer = role.filter(
                        x => x.children === USERTYPE.FILE_APPROVER
                    );

                    if (fulfiller.length === 0 && reviewer) {
                        fullfillers = obj.filter(
                            x => x.roleName === USERTYPE.FULFILLER && x.distributionListName !== PARENT_DL
                        );

                        const fullfillerEmails = fullfillers.map(item => item.email);
                        fullfillerNetworkId = fullfillerEmails.join(",");

                        if (fullfillers.length === 0) fullfillerNetworkId = userEmail;

                        /*if (fullfillers.length === 0) {
                            setIsError(true);
                            setErrorMessage(MESSAGES.ADD_FULFILLER);
                            setIsParticipantLoading(false);
                            return false;
                        }*/
                    }
                }

                const filterObj = emailOrDLList.filter(function (e) {
                    return ((e.mail && e.mail === ele.value) || (e.cn && e.cn === ele.value));
                });

                const type = filterObj && filterObj[0]?.typeOfData ? filterObj[0].typeOfData : null;
                const domain = filterObj && filterObj[0]?.domain ? filterObj[0].domain : null;
                const url = assembler(API_ROUTES.GET_AD_USERS + "/" + ele.value + "/" + type + "/" + domain + "/" + fullfillerNetworkId);

                axios.get(url, { headers: axiosAuthHeader })
                    .then((response) => {
                        setIsParticipantLoading(false);
                        let distributionList = response.data.payload;

                        if (distributionList?.message && distributionList?.message !== "") {
                            /*if (fullfillers && fullfillers.length === 0)
                                message = MESSAGES.EMPLOYEE_NOT_UNDER_INITIATORS_MANAGER;
                            else*/
                            message = distributionList.message;
                        }
                        else {
                            if (distributionList?.length !== 0 && distributionList !== DL_NOT_AVAILABLE) {
                                if (type === DL_TYPES.GROUP) {
                                    if (props.isServiceReqExternal) {
                                        role.forEach(roleElement => {
                                            upDatedTableData.push({
                                                email: "",
                                                name: ele.value,
                                                roleId: roleElement.key,
                                                roleName: roleElement.children,
                                                distributionListName: PARENT_DL,
                                                emailToCommunicate: ""
                                            });
                                        });
                                    }
                                    else {
                                        upDatedTableData.push({
                                            email: "",
                                            name: ele.value,
                                            distributionListName: PARENT_DL,
                                            emailToCommunicate: ""
                                        });
                                    }
                                }

                                distributionList.forEach(element => {
                                    let userName = (element.givenName ? element.givenName : "") + " " + (element.sn ? element.sn : "");

                                    if (userName.replace(/\s/g, '') === "") userName = element.cn;

                                    if (checkEmailWithInitiatorEmail(element.userPrincipalName, role ? role : [])) {
                                        if (!props.isServiceReqExternal && element.userPrincipalName) {
                                            upDatedTableData.push({
                                                emailToCommunicate: element.mail ? element.mail : element.userPrincipalName,
                                                email: element.userPrincipalName,
                                                name: userName,
                                                distributionListName: type === DL_TYPES.GROUP ? ele.value : null
                                            });

                                            count++;
                                        }
                                        else if (role && ele.value && element.userPrincipalName) {
                                            role.forEach(roleElement => {
                                                upDatedTableData.push({
                                                    emailToCommunicate: element.mail ? element.mail : element.userPrincipalName,
                                                    email: element.userPrincipalName,
                                                    name: userName,
                                                    roleId: roleElement.key,
                                                    roleName: roleElement.children,
                                                    distributionListName: type === DL_TYPES.GROUP ? ele.value : null
                                                });
                                            });

                                            count++;
                                        }
                                    }
                                    else {
                                        sameAsInitiatorEmail = true;
                                    }
                                });
                            }
                            else {
                                setIsError(true);
                                setErrorMessage(MESSAGES.EMAIL_OR_DL_DOES_NOT_EXIST);
                            }
                        }
                    }).then((data) => {
                        if (message !== "") {
                            setIsError(true);
                            setErrorMessage(message);
                        }
                        else if (count === 0 && sameAsInitiatorEmail) {
                            setIsError(true);
                            setErrorMessage(MESSAGES.INITIATOR_CANNOT_BE_FILE_APPROVER);
                        }
                        else if (count === 0) {
                            setIsError(true);
                            setErrorMessage(MESSAGES.EMAIL_OR_DL_DOES_NOT_EXIST);
                        }
                        else {
                            props.form.setFieldsValue({
                                employeeRole: undefined,
                                employeeEmail: undefined
                            });

                            setParticipantEmail("");
                            setRole(undefined);
                        }

                        props.onChange({
                            ParticipantsList: lodashUniqBy(upDatedTableData, v => [v.email, v.roleId, v.name].join()),
                            error: isError
                        });
                    });
            }
        });
    };

    const renderRoles = (roles) => {
        if (props.isServiceReqExternal) {
            return roles.map(role => (
                <Option key={role.id}>
                    {role.name === USERTYPE.REVIEWER ? USERTYPE.FILE_APPROVER : role.name}
                </Option>
            ))
        }
        else {
            return roles.filter(role => role.name !== USERTYPE.REVIEWER).map(role => (
                <Option key={role.id}>{role.name}</Option>
            ))
        }
    };

    let columns = [];

    const nameColumn = {
        title: "Employee Name",
        dataIndex: "name",
        key: "name",
    };

    const roleColumn = {
        title: "Role",
        dataIndex: "roleName",
        key: "roleName",
        width: "160px",
    };

    const actionColumn = {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (e, row) => deleteParticipantAction(row),
        width: "80px"
    };

    if (props.disableFields === false && props.isServiceReqExternal && props.userType !== USERTYPE.CUSTOMER)
        columns.push(nameColumn, roleColumn, actionColumn);
    else if (props.disableFields && props.isServiceReqExternal && props.userType !== USERTYPE.CUSTOMER)
        columns.push(nameColumn, roleColumn);
    else if (props.disableFields === false && !props.isServiceReqExternal)
        columns.push(nameColumn, actionColumn);
    else if (props.disableFields && !props.isServiceReqExternal)
        columns.push(nameColumn);

    const deleteParticipantAction = (record) => {
        if ((record.distributionListName !== PARENT_DL && record.distributionListName !== "" && record.distributionListName !== null) || record.roleId === 1) {
            return <></>;
        }
        else {
            return (
                <div className="deleteAction">
                    <Popconfirm
                        title={MESSAGES.CONFIRM_EMPLOYEE_DELETION}
                        onConfirm={() => onDeleteClick(record)}
                        okText="Yes"
                        cancelText="No"
                        icon={<InfoCircleOutlined style={{ color: 'red' }} />}
                    >
                        <Link to="#" style={{ color: "red" }}>
                            <DeleteOutlined style={{ color: "red", height: "17px" }} />
                            &nbsp;{LABELS.DELETE}
                        </Link>
                    </Popconfirm>
                </div>
            );
        }
    };

    const onDeleteClick = (obj) => {
        if (props.isServiceReqExternal) {
            if (Number.parseInt(obj?.roleId) === 3) {
                if (props.tableData.filter(i => Number.parseInt(i?.roleId) === 3).length <= 1) {
                    message.error(MESSAGES.ONE_FILE_APPROVER_REQUIRED);
                    return true;
                }

            }
        }

        if (!props.isServiceReqExternal) {
            if (props.tableData.filter(i => Number.parseInt(i?.roleId) !== 1).length <= 1) {
                message.error(MESSAGES.ONE_PARTICIPANT_REQUIRED);
                return true;
            }
        }

        props.onChange({ deleteObj: obj });
    };

    async function handleSearchEmailorDL(value) {
        if (value.length > 3) {
            const url = assembler(API_ROUTES.GET_AD_USERS_AND_GROUPS);

            return await axios.get(url + "/" + value, { headers: axiosAuthHeader })
                .then((body) => {
                    const updatedEmailOrDLList = emailOrDLList.concat(body.data);
                    setEmailOrDLList(updatedEmailOrDLList);

                    return body.data.map((user) => {
                        const isUserDataType = (user.typeOfData === DL_TYPES.USER);

                        let userName = (user.givenName && user.givenName !== undefined ? user.givenName : "") + (user.sn && user.sn !== undefined ? " " + user.sn : "");

                        if (userName === "") userName = user.cn;

                        return (
                            {
                                label: isUserDataType ? (userName + "\n" + user.mail) : user.cn,
                                value: isUserDataType ? user.mail : user.cn,
                            }
                        )
                    })
                })
        }
    }

    const treeViewToArray = (value) => {
        if (value?.length > 0) {
            let obj = value;

            value.forEach(element => {
                if (element?.children?.length > 0) {
                    obj = [...obj, ...element.children];
                }
            });

            return obj;
        }
        else {
            return [];
        }
    };

    useEffect(() => {
        // For resetting the values of the Participants & Roles
        if (props.resetCountForParticipants > 0) resetValues();
    }, [props.resetCountForParticipants]);

    const resetValues = () => {
        setIsError(false);
        setErrorMessage("");
        setEmailOrDLList([]);
        setParticipantEmail("");
        setRole(undefined);

        props.form.setFieldsValue({
            employeeRole: undefined, employeeEmail: undefined
        });
    };

    return (
        <>
            <Spin spinning={isParticipantLoading} tip="Loading..." size="large">
                <div style={{ paddingTop: "10px" }}></div>

                <section className="fieldset" id="sectionId">
                    <h2 style={{ fontWeight: "bold" }}>
                        {LABELS.INTERNAL_EMPLOYEES}
                    </h2>

                    {!props.disableFields &&
                        <>
                            <label style={{ fontWeight: "bold" }}>
                                {selectEmployeeLabelText}
                            </label>

                            <Form.Item style={{ width: "100" }} label="">
                                <Input.Group compact style={{ display: "flex" }}>
                                    <Form.Item
                                        noStyle
                                        name="employeeEmail"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        <DebounceSelect
                                            mode="multiple"
                                            placeholder={PLACEHOLDERS.EMAIL}
                                            fetchOptions={handleSearchEmailorDL}
                                            value={participantEmail}
                                            onChange={(newValue) => {
                                                setParticipantEmail(newValue);
                                            }}
                                            style={{ width: '100%', overflow: "hidden" }}
                                            resetCountForDebounceSelect={props.resetCountForParticipants}
                                        />
                                    </Form.Item>

                                    {props.userType !== USERTYPE.CUSTOMER ? props.isServiceReqExternal ?
                                        <Form.Item
                                            noStyle
                                            name="employeeRole"
                                            style={{ fontWeight: "bold" }}
                                        >
                                            <Select disabled={props.disableFields}
                                                mode="multiple"
                                                style={{ width: "50%", fontWeight: "normal" }}
                                                placeholder={PLACEHOLDERS.ROLE}
                                                onChange={handleRoleChange}
                                            >
                                                {renderRoles(roles)}
                                            </Select>
                                        </Form.Item> : null : null}

                                    <Button onClick={handleEmpRole} disabled={props.disableFields}>
                                        {LABELS.ADD}
                                    </Button>
                                </Input.Group>

                                {props.associatedEmployeeErrorMessage === true ? <><label className="errorMessage">{MESSAGES.ADD_AN_EMPLOYEE_AND_A_ROLE}</label> <br /></> : null}

                                {props.reviewerErrorMessage === true ? <><label className="errorMessage">{MESSAGES.ADD_FILE_APPROVER}</label> <br /></> : null}

                                {isError && <label className="errorMessage">{errorMessage}</label>}
                            </Form.Item>
                        </>
                    }

                    {props.tableData && props.tableData.length > 0 &&
                        <Form.Item>
                            <Space direction="vertical">
                                <Collapse ghost defaultActiveKey={['1']}>
                                    <Panel
                                        header={LABELS.SELECTED_EMPLOYEES}
                                        style={{ fontWeight: "bold" }}
                                        key="1"
                                    >
                                        <Table
                                            style={{ fontWeight: "normal" }}
                                            dataSource={props.tableData}
                                            scroll={{ y: 450 }}
                                            pagination={false}
                                            columns={columns}
                                            bordered={true}
                                            size="small"
                                        />
                                    </Panel>
                                </Collapse>
                            </Space>
                        </Form.Item>
                    }
                </section>
            </Spin>
        </>
    );
};

export default Participants;

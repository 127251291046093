import React, { useState, useEffect } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import {
    Form,
    Input,
    Spin,
    message,
    Radio,
} from "antd";
import axios from "../../utils/axios";
import assembler from "url-assembler";
import { API_GET_SERVICE_REQ_KEY_CONFIG, API_UPDATE_KEY_CONFIG } from "../../utils/webConstants";
import { useSelector } from "react-redux";

const KeyConfigEdit = ({ keyConfig, serviceRequestId, onKeyManageCancel, onKeyManageEdit, keyConfigForm, loadingKeyConfigEditForm, setLoadingKeyConfigEditForm }) => {

    const loginedUser = useSelector((state) => state.userLogin);
    let userToken = "";
    if (loginedUser.userInfo)
        userToken = loginedUser.userInfo.userToken
    const [userGeneratedKeys, setUserGeneratedKeys] = useState(false);

    useEffect(() => {
        getKeyConfigDetail();
    }, []);

    const getKeyConfigDetail = async () => {
        try {
            setLoadingKeyConfigEditForm(true);
            let url = assembler(API_GET_SERVICE_REQ_KEY_CONFIG).param({ serviceRequestId });
            const { data } = await axios.get(url, { headers: { authorization: userToken } });
            keyConfigForm.setFieldsValue(data.payload);
            setUserGeneratedKeys(data.payload.keyManagement === "userManaged" ? true : false);
            setLoadingKeyConfigEditForm(false);   
        } catch (error) {
            message.error('Error while loading Key config');
        }
    }

    const onRadioButtonChange = (e) => {
        if (e.target.value === "userManaged") {
            setUserGeneratedKeys(true);
        } else {
            setUserGeneratedKeys(false);
        }
    }

    return (
        <div className="historyDiv" style={{ width: "1200px" }} >
            <br />
            <span>This section allows you to manage file encryption/decryption keys.</span>
            <br />
            <br />
            <Spin spinning={loadingKeyConfigEditForm} tip="Loading...">
                <Form id="keyConfigForm" form={keyConfigForm} layout="horizontal" labelAlign="left" style={{ paddingTop: "5px", width: "600px" }}>
                    <Form.Item name="keyManagement" label={
                        <b>Manage Keys </b>
                    }
                        rules={[
                            {
                                required: true,
                                message: "Please select",
                            },
                        ]} labelCol={{ span: 5 }}>
                        <Radio.Group onChange={onRadioButtonChange}>
                            <Radio value={"systemManaged"} name="systemManagedKey">System Managed Keys</Radio>
                            <Radio value={"userManaged"} name="userManagedKey">User Managed Keys</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {userGeneratedKeys && <Form.Item
                        name="iv"
                        label={<b>IV</b>}
                        labelCol={{ span: 5 }}
                        rules={[{ required: true, message: 'Please input your iv!' }]}
                    >
                        <Input.Password
                            id="iv"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>}
                    {userGeneratedKeys && <Form.Item
                        name="secretKey"
                        label={<b>Secret Key</b>}
                        labelCol={{ span: 5 }}
                        rules={[{ required: true, message: 'Please input your secret!' }]}
                    >
                        <Input.Password
                            id="secretKey"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>}
                    {userGeneratedKeys && <Form.Item
                        name="publicKey"
                        label={<b>Public Key</b>}
                        labelCol={{ span: 5 }}
                        rules={[{ required: true, message: 'Please input your public key!' }]}
                    >
                        <Input.Password
                            id="publicKey"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>}
                </Form>
            </Spin>
        </div>
    );
};
export default KeyConfigEdit;

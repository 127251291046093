import React, { useState, useEffect } from "react";
import { getFormattedDateTime } from '../../utils/sharedUtils';
import { API_ROUTES } from "../../utils/webConstants";
import Pagination from "../components/Pagination";
import EmptyTable from '../components/EmptyTable';
import { useSelector } from "react-redux";
import axios from "../../utils/axios";
import assembler from "url-assembler";
import { Table } from "antd";

const History = ({ requestId }) => {
    const { userInfo } = useSelector((state) => state.userLogin);
    const userToken = (userInfo) ? userInfo.userToken : '';

    const [historyTableData, setHistoryTableData] = useState([]);
    const [historyPage, setHistoryPage] = useState(1);
    const [historyLimit, setHistoryLimit] = useState(10);
    const [historyTotal, setHistoryTotal] = useState(0);

    useEffect(() => {
        getData({ historyPage, historyLimit });
    }, [historyPage, historyLimit, requestId]);

    const getData = async () => {
        try {
            const url = assembler(API_ROUTES.GET_TASKS).query({
                page: historyPage, limit: historyLimit, requestId
            });

            const res = await axios.get(url, {
                headers: { authorization: userToken }
            });

            const values = res.data.payload.map((elem, index) => {
                elem.key = elem.id;
                return elem;
            });

            setHistoryTableData(values);
            setHistoryTotal(res.data.total);
        }
        catch (error) {
            console.error('When getting Request History data. ', error);
        }
    };

    const historyTableColumns = [
        {
            title: "Task", dataIndex: "name", key: "name", width: "40%",
            render: (text, record) => getRecordTaskName(text, record)
        },
        {
            title: "External Party/Employee", dataIndex: "emailId",
            key: "emailId", width: "40%",
            render: (text, record) => getRecordUserName(record)
        },
        {
            title: "Creation Date", dataIndex: "createdOn", key: "createdOn",
            className: "formatDate",
            render: (createdOn) => getFormattedDateTime(createdOn)
        }
    ];

    const getRecordTaskName = (text, record) => {
        const { description } = record;

        return (description != null) ? `${description}` : `${text}`;
    };

    const getRecordUserName = (record) => {
        const {
            userEmailId, associatedEmployeeEmailId, serviceRequest
        } = record;

        const { customerEmail, associatedEmployees } = serviceRequest;
        let userName = "";

        if (userEmailId) {
            userName = userEmailId;

            customerEmail.forEach(({ email, user }) => {
                if (email === userEmailId) {
                    userName = (user)
                        ? `${user.firstName} ${user.lastName}`
                        : email;
                }
            });
        }
        else {
            userName = associatedEmployeeEmailId;

            associatedEmployees.forEach(({ email, name }) => {
                if (email === associatedEmployeeEmailId) userName = name;
            });
        }

        return `${userName}`;
    };

    const onHistoryPaginate = (page, pageSize) => {
        setHistoryPage(page);
        setHistoryLimit(pageSize);
    };

    return (
        <>
            <div className="historyDiv">
                <Table
                    columns={historyTableColumns} dataSource={historyTableData}
                    pagination={false} bordered={true} scroll={{ y: 450 }}
                    locale={{ emptyText: <EmptyTable text="No Request History" /> }}
                />
                <div align={"center"}>
                    <Pagination
                        total={historyTotal} defaultPageSize={historyLimit}
                        defaultCurrent={historyPage}
                        onChange={onHistoryPaginate}
                    />
                </div>
            </div>
        </>
    );
};

export default History;

import jwt_decode from "jwt-decode";
import { UI_ROUTES } from "../../utils/webConstants";
import ACTION_TYPES from "../actionTypes/userActionType";
import { SESSION_STORAGE_KEYS } from "../../utils/appConstants";

const { USER_LOGIN } = SESSION_STORAGE_KEYS;

const defaultIfStateIsNull = { loading: false, spinningText: "" };
const userData = JSON.parse(sessionStorage.getItem(USER_LOGIN));
const initialState = { failedLogins: 0 };
let decoded = null;

if (userData) {
  const token = userData.token;

  try {
    decoded = jwt_decode(token);
  }
  catch (error) {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = UI_ROUTES.LOGIN;
  }
}

const userInformation = (userData)
  ? ({
    userToken: userData.token,
    sessionId: userData.sessionId,
    emailId: decoded.emailId,
    name: decoded.name,
    role: decoded.role
  })
  : null;

export const userRegisterReducer = (state = defaultIfStateIsNull, action) => {
  switch (action.type) {
    case ACTION_TYPES.USER_REGISTER_REQUEST:
      return { loading: true, spinningText: "Registering..." };

    case ACTION_TYPES.USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };

    case ACTION_TYPES.USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userLoginReducer = (
  state = {
    loading: false,
    spinningText: "",
    userInfo: userInformation,
    failedLogins: initialState.failedLogins
  },
  action
) => {
  switch (action.type) {
    case ACTION_TYPES.USER_LOGIN_REQUEST:
      return { loading: true, spinningText: "logging in..." };

    case ACTION_TYPES.USER_LOGIN_SUCCESS:
      return {
        loading: false,
        userInfo: {
          userToken: action.payload.token,
          sessionId: action.payload.sessionId,
          emailId: action.payload.emailId,
          name: action.payload.name,
          role: action.payload.role
        }
      };

    case ACTION_TYPES.USER_LOGIN_FAIL:
      if (initialState.failedLogins < Number.MAX_SAFE_INTEGER) {
        initialState.failedLogins++
        state.failedLogins = initialState.failedLogins;
      }
      return {
        loading: false,
        error: action.payload,
        failedLogins: state.failedLogins
      };

    case ACTION_TYPES.USER_LOGOUT:
      return { loading: false, userInfo: {} };

    default:
      return state;
  }
};

export const userForgotPasswordReducer = (
  state = defaultIfStateIsNull, action
) => {
  switch (action.type) {
    case ACTION_TYPES.USER_FORGOT_PASSWORD_REQUEST:
      return { loading: true, spinningText: "loading..." };

    case ACTION_TYPES.USER_FORGOT_PASSWORD_SUCCESS:
      return { loading: false, data: action.payload };

    case ACTION_TYPES.USER_FORGOT_PASSWORD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userResetPasswordReducer = (
  state = defaultIfStateIsNull, action
) => {
  switch (action.type) {
    case ACTION_TYPES.USER_RESET_PASSWORD_REQUEST:
      return { loading: true, spinningText: "resetting the password..." };

    case ACTION_TYPES.USER_RESET_PASSWORD_SUCCESS:
      return { loading: false, data: action.payload };

    case ACTION_TYPES.USER_RESET_PASSWORD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

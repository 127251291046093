import { Table } from "antd";

const CTable = (props) => {
  const { columns, dataSource, locale } = props;

  const handleTableChange = (pagination, filters, sorter) => {
    props.handleTableChange(pagination, filters, sorter);
  };

  return (
    <Table
      columns={columns} dataSource={dataSource} pagination={false}
      bordered={true} scroll={{ y: 450 }} locale={locale}
      onChange={handleTableChange}
    />
  );
};

export default CTable;

import { combineReducers } from "redux";
import { requestReducer } from "./requestReducer";

import {
  userRegisterReducer, userLoginReducer, userForgotPasswordReducer,
  userResetPasswordReducer
} from "./userReducer";

const rootReducer = combineReducers({
  userRegister: userRegisterReducer,
  userLogin: userLoginReducer,
  request: requestReducer,
  userForgotPassword: userForgotPasswordReducer,
  userResetPassword: userResetPasswordReducer
});

export default rootReducer;

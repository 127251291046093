import React from "react";
import { Empty } from "antd";

/**
 * @param {String} text
 * @returns `Empty` component with `text` as description
 */
export default function EmptyTable({ text }) {
  return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={text}/>;
}

import React, { useEffect, useState, Fragment } from "react";
import InactivateRequests from "../inactivateRequests/InactivateRequests.js";
import FileDataTypes from "../fileDataTypes/FileDataTypes.js";
import { SETTINGS_COMPONENT } from "../../utils/appConstants";
import DeactivateUser from "../deactivateUsers/DeactivateUser.js";
import { UI_ROUTES, API_ROUTES } from "../../utils/webConstants";
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import AuditTrail from "../audit/AuditTrail";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "../../utils/axios";
import 'antd/dist/antd.min.css';

import {
  Form, message, InputNumber, Button, Divider, Tabs, Layout
} from "antd";

import {
  checkEmployeeIsManager, checkEmployeeIsAdmin, checkEmployeeIsInfosec
} from "../../utils/authUtils";

const { Content } = Layout;
const { TabPane } = Tabs;

const layout = { labelCol: { span: 10 }, wrapperCol: { span: 10 } };

const tailLayout = { wrapperCol: { span: 16 } };

const {
  PAGE_DESCRIPTION, TAB_NAMES, MAX_FILE_RETENTION_PERIOD_CANNOT_BE_EMPTY,
  FILE_RETENTION_PERIOD_CANNOT_BE_EMPTY, FORM_SUBMIT_ERROR, UPDATE_SUCCESS,
} = SETTINGS_COMPONENT;

const {
  FILE_RETENTION, DEACTIVATE_USERS, DEACTIVATE_REQUESTS, AUDIT_TRAIL,
  CONFIG_FILE_DATA_TYPES
} = TAB_NAMES;

const Settings = () => {
  const [form] = Form.useForm();

  const { userInfo } = useSelector((state) => state.userLogin);
  const userToken = (userInfo) ? userInfo.userToken : '';

  const employeeIsManager = checkEmployeeIsManager(userInfo);
  const employeeIsAdmin = checkEmployeeIsAdmin(userInfo);
  const employeeIsInfosec = checkEmployeeIsInfosec(userInfo);

  const [selectedTab, setSelectedTab] = useState(employeeIsAdmin ? FILE_RETENTION : DEACTIVATE_USERS);

  useEffect(() => {
    if (selectedTab === FILE_RETENTION) {
      axios.get(API_ROUTES.GET_SETTINGS, { headers: { authorization: userToken } })
      .then((response) => {
        if (response?.data) {
          form.setFieldsValue({
            defaultFileRetentionPeriod: response.data.payload[0].defaultFileRetentionPeriod,
            maxFileRetentionPeriod: response.data.payload[0].maxFileRetentionPeriod
          });
        }
      });
    }
  }, [selectedTab]);

  const onFinish = async (values) => {
    const reqBody = {
      defaultFileRetentionPeriod: values.defaultFileRetentionPeriod,
      maxFileRetentionPeriod: values.maxFileRetentionPeriod
    };

    await axios.post(
      API_ROUTES.SAVE_SETTINGS,
      reqBody,
      { headers: { authorization: userToken } }
    )
    .then((response) => {
      if (response?.data?.message === "Request Send To Initiator") {
        const deactivateRequestSentMessage = `Deactivate request has been send to Initiator.
          After Initiator approval User will get deactivated automatically`;

        message.success(deactivateRequestSentMessage);
      }
    });

    message.success(UPDATE_SUCCESS);
  };

  const onFinishFailed = (errorInfo) => message.error(FORM_SUBMIT_ERROR);

  const handleTabChange = (key) => setSelectedTab(key);

  const pageTitle = (
    <Divider
      orientation="left" className="dividerHeading" style={{ marginTop: 0 }}
    >
      Settings
    </Divider>
  );

  const pageDescription = <div><p>{PAGE_DESCRIPTION}</p></div>;

  return (
    <Layout>
      <AppHeader userName={userInfo.name} userEmail={userInfo.emailId} />
      <Layout className="layoutStyle">
        <Layout>
          <Content>
            <div
              className="site-layout-content contentStyle"
              style={{ background: "#fff", padding: '20px 25px 20px 25px' }}
            >
              <Fragment>
                {pageTitle}
                {pageDescription}
                <Tabs activeKey={selectedTab} onChange={handleTabChange}>

                  {employeeIsAdmin && <TabPane tab={FILE_RETENTION} key={FILE_RETENTION}>
                    {selectedTab === FILE_RETENTION && <div>
                      <Form
                        form={form} name="basic" layout="vertical" {...layout}
                        initialValues={{ remember: true }} onFinish={onFinish}
                        style={{ paddingTop: "5px" }}
                        onFinishFailed={onFinishFailed}
                      >
                        <Form.Item
                          label="Default File Retention Period (Days)"
                          name="defaultFileRetentionPeriod"
                          style={{ fontWeight: "bold" }}
                          rules={[{
                            required: true,
                            message: FILE_RETENTION_PERIOD_CANNOT_BE_EMPTY,
                          }]}
                        >
                          <InputNumber min={1} id="defaultFileRetentionPeriod" />
                        </Form.Item>
                        <Form.Item
                          label="Maximum File Retention Period (Days)"
                          name="maxFileRetentionPeriod"
                          style={{ fontWeight: "bold" }}
                          rules={[{
                            required: true,
                            message: MAX_FILE_RETENTION_PERIOD_CANNOT_BE_EMPTY,
                          }]}
                        >
                          <InputNumber min={1} id="maxFileRetentionPeriod" />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                          <Link to={UI_ROUTES.BASE}>
                            <Button style={{ margin: "0 8px" }}>
                              Cancel
                            </Button>
                          </Link>
                        </Form.Item>
                      </Form>

                    </div>}
                  </TabPane>}

                  <TabPane tab={DEACTIVATE_USERS} key={DEACTIVATE_USERS}>
                    {selectedTab === DEACTIVATE_USERS && <div>
                      <DeactivateUser />
                    </div>}
                  </TabPane>

                  {employeeIsAdmin && <TabPane tab={DEACTIVATE_REQUESTS} key={DEACTIVATE_REQUESTS}>
                    {selectedTab === DEACTIVATE_REQUESTS && <div>
                      <InactivateRequests />
                    </div>}
                  </TabPane>}

                  {employeeIsAdmin && <TabPane tab={CONFIG_FILE_DATA_TYPES} key={CONFIG_FILE_DATA_TYPES}>
                    {selectedTab === CONFIG_FILE_DATA_TYPES && <div>
                      <FileDataTypes />
                    </div>}
                  </TabPane>}

                  {(employeeIsAdmin || employeeIsInfosec || employeeIsManager) && <TabPane tab={!employeeIsAdmin && !employeeIsInfosec && employeeIsManager ? "Manager Audit" : "Audit Logs"} key={AUDIT_TRAIL}>
                    {selectedTab === AUDIT_TRAIL && <div>
                      <AuditTrail isManager={employeeIsManager} role={userInfo.role} />
                    </div>}
                  </TabPane>}

                </Tabs>
              </Fragment>
            </div>
          </Content>
          <AppFooter />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Settings;

import MainMenu from './MainMenu';
import { Layout, Space } from "antd";
import { Link } from "react-router-dom";
import { UI_ROUTES } from '../../utils/webConstants';
import { useWindowWidth } from '../../utils/customHooks';
import { checkEmailIsExternal } from "../../utils/authUtils";
import { APPHEADER_COMPONENT } from "../../utils/appConstants";

const {
    TITLE, LOGO, LOGO_ALT, STYLES, SMALL_DEVICE_WIDTH
} = APPHEADER_COMPONENT;

export default function AppHeader({ userName, userEmail }) {
    const windowWidth = useWindowWidth();
    const isNameValid = (userName?.length);
    const isEmailValid = (userEmail?.length);
    const isSmallDevice = (windowWidth <= SMALL_DEVICE_WIDTH);

    const isExternal = (isEmailValid)
        ? checkEmailIsExternal(userEmail)
        : false;

    const menuProps = { isExternal, isSmallDevice, isNameValid };

    return (
        <Layout.Header style={STYLES.HEADER}>
            <Space align="center" size='large' style={STYLES.SPACE}>
                <LogoContent isSmallDevice={isSmallDevice} />
                <TitleContent isSmallDevice={isSmallDevice} />
                <MenuContent menuProps={menuProps} />
            </Space>
        </Layout.Header>
    );
}

function LogoContent({ isSmallDevice }) {
    const logoContent = (isSmallDevice) ? <ShortLogo /> : <FullLogo />;

    const divStyle = (isSmallDevice)
        ? STYLES.LOGO_DIV.SHORT
        : STYLES.LOGO_DIV.FULL;

    return (
        <div style={divStyle}>
            <Link to={UI_ROUTES.BASE}>
                {logoContent}
            </Link>
        </div>
    );
}

function TitleContent({ isSmallDevice }) {
    const title = (isSmallDevice) ? TITLE.SHORT : TITLE.FULL;

    return <div style={STYLES.TITLE}>{title}</div>;
}

const MenuContent = ({ menuProps }) => (
    (menuProps.isNameValid) ? <Menu menuProps={menuProps} /> : <TransparentDiv />
);

const ShortLogo = () => (
    <img src={LOGO.SHORT} alt={LOGO_ALT} width={46} height={51} />
);

const FullLogo = () => (
    <img src={LOGO.FULL} alt={LOGO_ALT} width={170} height={51} />
);

const Menu = ({ menuProps: { isExternal, isSmallDevice } }) => (
    <div style={STYLES.MENU}>
        <MainMenu isExternal={isExternal} isSmallDevice={isSmallDevice} />
    </div>
);

const TransparentDiv = () => <div style={STYLES.TRANSPARENT_DIV}></div>;

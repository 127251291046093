import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import assembler from "url-assembler";
//import Pagination from "../components/Pagination";
import CommentsModal from "./CommentsModal";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
//import FileComment from "../components/FileComment";
//import ViewFile from "../components/ViewFile";
import jwt_decode from "jwt-decode";
import { checkEmailIsExternal } from "../../utils/authUtils";
import EmptyTable from '../components/EmptyTable';
import { getFormattedDateTime } from '../../utils/sharedUtils';

import {
    FILE_LIST_COMPONENT, AXIOS_CONFIG_VALUES
} from "../../utils/appConstants";

import {
    Row, Col, Button, Table, Modal, Comment, Spin, Avatar, message,
    Badge, List, Popconfirm
} from "antd";

import {
    API_DELETE_FILE, UI_ROUTES, API_ROUTES
} from "../../utils/webConstants";

import {
    UserOutlined, DeleteOutlined, /*FileOutlined,*/ CommentOutlined,
    InfoCircleOutlined
} from "@ant-design/icons";

const {
    CONTENT_TYPE, APPLICATION_JSON, AUTHORIZATION, DELETE_METHOD, PUT_METHOD
} = AXIOS_CONFIG_VALUES;

const Comments = ({
    requestId, /*taskId,*/ customerEmailData, associatedEmployees,
    isClosedRequest, previousTab, updateCommentCount, isServiceReqExternal
}) => {
    let history = useHistory();

    const { userInfo } = useSelector((state) => state.userLogin);
    const userToken = userInfo?.userToken ?? '';

    const decodedToken = userToken !== "" ? jwt_decode(userToken) : {};
    const userEmail = decodedToken?.emailId ?? '';

    const [commentsTableData, setCommentsTableData] = useState([]);
    const [commentsPage, setCommentsPage] = useState(1);
    const [commentsLimit, setCommentsLimit] = useState(10);
    const [commentsTotal, setCommentsTotal] = useState(0);
    const [isCommentsModalVisible, setIsCommentsModalVisible] = useState(false);
    //const [isfileCommentModalVisible, setIsfileCommentModalVisible] = useState(false);
    //const [fileCommentMessage, setFileCommentMessage] = useState("");
    /*const [fileId, setFileId] = useState(null);
    const [fileName, setFileName] = useState("");
    const [isOpenViewFile, setIsOpenViewFile] = useState(false);*/
    //const [selectedFileId, setSelectedFileId] = useState(null);
    //const [selectedFileName, setSelectedFileName] = useState(null);
    //const [fileCommentModalVisible, setFileCommentModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [nonReadCommentCount, setNonReadCommentCount] = useState(0);
    const isExternalEmail = checkEmailIsExternal(userEmail);

    const contentAndAuthHeaders = {
        [CONTENT_TYPE]: APPLICATION_JSON, [AUTHORIZATION]: userToken
    };

    const getData = async () => {
        try {
            setIsLoading(true);

            const url = assembler(API_ROUTES.GET_FILE_AND_REQUEST_COMMENTS).query({
                page: commentsPage, limit: commentsLimit, id: requestId
            });

            const res = await axios.get(url, {
                headers: { [AUTHORIZATION]: userToken }
            });

            setCommentsTableData(res.data.payload);
            setCommentsTotal(res.data.total);
            updateCommentCount(res.data.nonReadCommentsCount);
            setNonReadCommentCount(res.data.nonReadCommentsCount);
            setIsLoading(false);
        }
        catch (error) {
            console.error(JSON.stringify(error));

            if (error.response.status === 403) history.push(UI_ROUTES.BASE);
        }
    };

    useEffect(() => {
        if (!isCommentsModalVisible) getData({ commentsPage, commentsLimit });
    }, [
        commentsPage, commentsLimit, requestId, isCommentsModalVisible,
        userToken
    ]);

    const commentsColumns = [
        {
            title: "FileName/File Transfer Request",
            dataIndex: "",
            key: "",
            width: "100%",
            render: (text, record) =>
                <div>
                    {record.type === "file" ? <div>
                        <Row>
                            <Col span="14"><>{record.file.name} {record?.details?.findIndex(i => i?.isRead === null) !== -1 ? (
                                <sup data-show="true" className="ant-scroll-number ant-badge-dot" style={{ top: '12px', right: 'initial' }}></sup>) : ''}</></Col>
                            <Col span="8">
                                {(record.deletePermission && !isClosedRequest) ? <Popconfirm
                                    title={isExternalEmail ? FILE_LIST_COMPONENT.EXTERNAL_USER_DELETE_CONFIRM_MESSAGE : FILE_LIST_COMPONENT.DELETE_CONFIRM_MESSAGE}
                                    onConfirm={() => onDeleteFileClick(record.file.id)}
                                    okText="Yes"
                                    cancelText="No"
                                    icon={<InfoCircleOutlined style={{ color: 'red' }} />}
                                >  <Link to="#" style={{ color: "red", textDecoration: "none" }}>
                                        <DeleteOutlined style={{ color: "red" }} />&nbsp;Delete
                                    </Link>
                                </Popconfirm> : null}
                                {record.commentPermission && <>&nbsp; <Link to="#" style={{ textDecoration: "none" }} onClick={() => setIsCommentsModalVisible(true)}><CommentOutlined />&nbsp;Add Comment</Link></>}
                                {/*record.previewPermission && <>&nbsp;<Link to="#" style={{ textDecoration: "none" }} onClick={() => { setFileId(record.file.id); setFileName(record.file.name); setIsOpenViewFile(true); }} ><FileOutlined className="fileIcon" />&nbsp;Preview</Link></>*/}
                            </Col>
                        </Row>
                    </div>
                        : (<span>
                            <span> File Transfer Request</span>

                            {record?.details?.findIndex(i => i?.isRead === null) !== -1 ? (
                                <sup data-show="true" className="ant-scroll-number ant-badge-dot" style={{ top: '12px', right: 'initial' }}></sup>) : ''}
                        </span>)}
                </div>
        },
    ];

    //const onCommentsPaginate = (page, pageSize) => {
    //    setCommentsPage(page);
    //    setCommentsLimit(pageSize);
    //};

    const CommentsModalSubmit = () => setIsCommentsModalVisible(false);

    const commentClick = (text) => {
        Modal.info({ title: 'Comments', content: <div><p>{text}</p></div> });
    };

    const expandedRowRender = (data) => {
        const columns = [
            {
                title: "Comment",
                dataIndex: "description",
                key: "description",
                width: "70%",
                render: (text, record) => {
                    return (text.length < 80)
                        ? <div>{text}</div>
                        : <Link to="#" onClick={() => commentClick(text)}>
                            {(text.substring(0, 120) + "...")}
                        </Link>;
                }
            },
            {
                title: "Commented By",
                dataIndex: "createdBy",
                key: "createdBy",
                render: (text, record) => {
                    let userName = record.createdBy;

                    let customerObject = customerEmailData.find(
                        o => o.email === record.createdBy
                    );

                    if (customerObject?.user) {
                        userName = customerObject.user.firstName + " " + customerObject.user.lastName;
                    }
                    else {
                        let employeeObject = associatedEmployees.find(
                            o => o.email === record.createdBy
                        );

                        if (employeeObject) userName = employeeObject.name;
                    }

                    return `${userName}`;
                }
            },
            {
                title: "Date",
                dataIndex: "createdOn",
                key: "createdOn",
                className: "formatDate",
                render: (createdOn) => getFormattedDateTime(createdOn)
            }
        ];

        return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    const expandedCommentsRender = (record) => {
        let comments = record.details;
        let data = [];

        for (let comment of comments) {
            let userName = comment.createdBy;

            let customerObject = customerEmailData.find(
                o => o.email === comment.createdBy
            );

            if (customerObject?.user) {
                userName = customerObject.user.firstName + " " + customerObject.user.lastName;
            }
            else {
                let employeeObject = associatedEmployees.find(
                    o => o.email === comment.createdBy
                );

                if (employeeObject) userName = employeeObject.name;
            }

            let obj = {
                recordValue: { ...comment, recordId: record.id },
                actions: [<><span key="comment-list-reply-to-0" style={comment.isRead !== true && comment.createdBy !== userEmail ? { color: "blue" } : {}} >{getFormattedDateTime(comment.createdOn)}</span>
                    {userEmail === comment.createdBy && <Popconfirm
                        title="Are you sure you want to delete this comment?"
                        onConfirm={() => onDeleteCommentClick(comment.id, record.type)}
                        okText="Yes"
                        cancelText="No"
                        icon={<InfoCircleOutlined style={{ color: 'red' }} />}
                    >  <Link to="#" style={{ color: "red", textDecoration: "none" }}>
                            <DeleteOutlined style={{ color: "red" }} />&nbsp;Delete
                        </Link>
                    </Popconfirm>
                    }
                </>],
                author: (<p style={comment.isRead !== true && comment.createdBy !== userEmail ? { fontWeight: "bold" } : {}}> {userName}</p>),
                avatar: <Badge dot={comment.isRead !== true && comment.createdBy !== userEmail ? true : false}> <Avatar shape="square" size="large" icon={<UserOutlined />} /></Badge>,
                content: (
                    <p style={comment.isRead !== true && comment.createdBy !== userEmail ? { color: "blue" } : {}}>
                        {comment.description}
                    </p>
                ),
            };

            data.push(obj);
        }

        return <List
            className="comment-list"
            itemLayout="horizontal"
            dataSource={data}
            renderItem={item => (
                <li onMouseEnter={() => onClickComment(item)}>
                    <Comment onClick={() => onClickComment(item)}
                        actions={item.actions}
                        author={item.author}
                        avatar={item.avatar}
                        content={item.content}
                    />
                </li>
            )}
        />;
    };

    const onDeleteFileClick = (id) => {
        axios({
            method: DELETE_METHOD,
            url: API_DELETE_FILE + "/" + id,
            headers: contentAndAuthHeaders,
            data: JSON.stringify({ id: id, status: "DELETE" })
        })
        .then((response) => {
            if (response?.status === 200) {
                message.success('File deleted successfully');
                getData({ commentsPage, commentsLimit });
            }
        });
    };

    //const onFileListRowClick = (fileMetaData) => {
    //    setSelectedFileId(fileMetaData.file.id);
    //    setSelectedFileName(fileMetaData.file ? fileMetaData.file.name : null);
    //    setFileCommentModalVisible(true);
    //}

    //const handleViewFileModalChange = () => {
    //    setIsOpenViewFile(false);
    //}

    //const fileCommentModalOk = () => {
    //    setIsfileCommentModalVisible(false);
    //}

    //const fileCommentModalCancel = () => {
    //    setIsfileCommentModalVisible(false);
    //}

    const onDeleteCommentClick = (id, type) => {
        axios({
            method: DELETE_METHOD,
            url: API_ROUTES.DELETE_COMMENT + "/" + id + "/" + type,
            headers: contentAndAuthHeaders,
        })
        .then((response) => {
            if (response?.status === 200) {
                message.success('Comment deleted Successfully');
                getData({ commentsPage, commentsLimit });
            }
        });
    };

    const onClickComment = (record) => {
        if (record?.recordValue?.isRead !== true) {
            const type = (record.recordValue.fileId) ? 'file' : 'comment';

            axios({
                method: PUT_METHOD,
                url: API_ROUTES.UPDATE_COMMENT + "/" + record.recordValue.id + "/" + type,
                headers: contentAndAuthHeaders,
            })
            .then((response) => {
                if (response?.status === 200) {
                    const commentCount = nonReadCommentCount - 1;
                    updateCommentCount(commentCount);
                    setNonReadCommentCount(commentCount);

                    const recordObj = commentsTableData.find(
                        x => x.id === record.recordValue.recordId
                    );

                    const objIndex = commentsTableData.findIndex(
                        x => x.id === record.recordValue.recordId
                    );

                    if (recordObj?.details) {
                        const commentObjIndex = recordObj.details.findIndex(
                            x => x.id === record.recordValue.id
                        );

                        if (commentObjIndex >= 0) {
                            let obj = [...commentsTableData];
                            obj[objIndex].details[commentObjIndex].isRead = true;
                            setCommentsTableData(obj);
                        }
                    }
                }
            });
        }
    };

    return (
        <>
            <Spin spinning={isLoading} tip="Loading..." size="large">
                <div className="commentsDiv" >
                    <Table
                        className="components-table-demo-nested"
                        columns={commentsColumns}
                        dataSource={commentsTableData}
                        pagination={commentsTableData?.length > 10 ? true : false}
                        bordered={true}
                        scroll={{ y: 450 }}
                        locale={{ emptyText: <EmptyTable text="No Comments" /> }}
                        expandedRowRender={record => expandedCommentsRender(record)}
                    />

                    {/* <div align={"center"}>
                        <Pagination
                            total={commentsTotal}
                            defaultPageSize={commentsLimit}
                            defaultCurrent={commentsPage}
                            onChange={onCommentsPaginate}
                        />
                    </div> */}

                    {/* {commentsTableData  && commentsTableData.length>0?<>
                        {commentsTableData.forEach(element => {
                            <Comment
                                actions={[<span key="comment-nested-reply-to">Reply to</span>]}
                                author={<a>{element.id}</a>}
                                content={<p> test1</p>

                                }
                            >

                            </Comment>

                        })}
                    </>:null} */}

                    {/* {commentsTableData && commentsTableData.length > 0 ? <>

                        <Comment
                            actions={[<span key="comment-nested-reply-to">Reply to</span>]}
                            author={<a>{commentsTableData[0].id}</a>}
                            content={ <p> test1</p>
                            }
                        >
                        </Comment>

                    </> : null} */}

                    {
                        (!isClosedRequest)
                            ? <div align={"right"} style={{ paddingTop: "5px" }}>
                                <Row>
                                    <Col span="24" align="right">
                                        <Button
                                            style={{ fontWeight: "bold" }}
                                            onClick={() => setIsCommentsModalVisible(true)}
                                        >
                                            Add Comments
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            : null
                    }
                </div>
            </Spin>

            {isCommentsModalVisible && <CommentsModal
                requestId={requestId}
                showCustomerCheckbox={isServiceReqExternal ? true : false}
                isShowFiles={true}
                // taskId={taskId}
                isCommentsModalVisible={isCommentsModalVisible}
                type={"add"}
                onChange={CommentsModalSubmit}
            />}

            {/*selectedFileId && <FileComment setFileCommentModalVisible={setFileCommentModalVisible} fileCommentModalVisible={fileCommentModalVisible} selectedFileId={selectedFileId} selectedFileName={selectedFileName} onUpdate={() => getData()} isServiceReqExternal={isServiceReqExternal}/>*/}

            {/*isOpenViewFile ? <ViewFile isOpenViewFile={isOpenViewFile} onChange={handleViewFileModalChange} fileId={fileId} fileName={fileName} requestId={requestId} /> : null*/}

            {/*<Modal open={isfileCommentModalVisible} onOk={fileCommentModalOk} onCancel={fileCommentModalCancel}>
                <p>{fileCommentMessage}</p>
            </Modal>*/}
        </>
    );
};

export default Comments;

import React, { useEffect, useState } from "react";
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import assembler from "url-assembler";
import { v4 as uuidv4 } from 'uuid';
import { Link, useHistory } from 'react-router-dom';
import { UI_ROUTES } from '../../utils/webConstants';
import { useSelector, useDispatch } from "react-redux";
import { login, microsoftLogin } from "../../redux/actions/userActions";
import { Form, Input, Button, Spin, Alert, Row, Col, Layout } from "antd";

import {
  LOGIN_PAGE_COMPONENT, THREATMETRIX_COMPONENT, SESSION_STORAGE_KEYS,
  USER_GUIDE_URL
} from "../../utils/appConstants";

import {
  checkEmailIsExternal, checkEmailCanLogin
} from "../../utils/authUtils";

const {
  REGISTER, LOGIN, FORGOT_PASSWORD, FINAL_WARNING, RESET_PASSWORD, PASSWORD,
  HOW_TO_LOGIN, PROVIDE_VALID_EMAIL, ENTER_PASSWORD, CONTINUE,
  EMAIL_ADDRESS
} = LOGIN_PAGE_COMPONENT;

const { Item } = Form;

const Login = () => {
  let history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [showExternalFields, setShowExternalFields] = useState(false);
  const [threatMetrixSessionId, setThreatMetrixSessionId] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error, failedLogins, spinningText } = userLogin;

  const submitButtonLabel = (showExternalFields) ? LOGIN : CONTINUE;

  useEffect(() => {
    connectToThreatmetrix();

    if (userInfo?.sessionId) {
      window.location.href = UI_ROUTES.RESET_PWD;
      return;
    }

    if (userInfo?.userToken) {
      const requestUrl = sessionStorage.getItem(SESSION_STORAGE_KEYS.REQ_URL);

      if (requestUrl !== null && requestUrl !== "") {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.REQ_URL, '');
        window.location.href = requestUrl;
      }
      else {
        window.location.href = UI_ROUTES.BASE;
      }
    }
  }, [history, userInfo]);

  /**
   * Creates & stores ThreatMetrix session id, then connects to ThreatMetrix
   */
  function connectToThreatmetrix() {
    const { URL, ORG_ID, PAGE_ID } = THREATMETRIX_COMPONENT;
    const sessionId = uuidv4();
    setThreatMetrixSessionId(sessionId);

    const urlQuery = {
      org_id: ORG_ID, session_id: sessionId, pageid: PAGE_ID
    };

    const iframe = document.createElement('iframe');
    iframe.src = assembler(URL).query(urlQuery);
    iframe.sandbox = "allow-same-origin allow-scripts";
    iframe.style.display = "none";
    document.body.appendChild(iframe);
  }

  const onFinish = (formData) => {
    const { email } = formData;
    const valid = checkEmailCanLogin(email);

    if (!valid) {
      form.setFields([{ name: "email", errors: [userGuideLink] }]);
    }
    else {
      const isExternalEmail = checkEmailIsExternal(email);

      if (isExternalEmail) {
        const { password } = formData;
        setShowExternalFields(true);

        if (password) {
          dispatch(login(email, password, threatMetrixSessionId));
        }
      }
      else {
        dispatch(microsoftLogin(email));
      }
    }
  };

  const pageTitle = <h3 className="text-center login-logo">{LOGIN}</h3>;

  const errorAlert = <Alert
    message="Error" description={error} type="error" showIcon closable
  />;

  const errorAlertContent = (error) ? errorAlert : null;

  const finalErrorAlert = <Alert
    message={FINAL_WARNING} type="warning" showIcon closable
    description={RESET_PASSWORD}
  />;

  const finalErrorAlertContent = (failedLogins === 2) ? finalErrorAlert : null;

  const submitButton = (
    <Button
      type="primary" htmlType="submit" className="login-form-button"
      style={{ width: "100%" }}
    >
      {submitButtonLabel}
    </Button>
  );

  const forgotPwdLink = (
    <>
      <br />
      <Link to={UI_ROUTES.FORGOT_PWD}>{FORGOT_PASSWORD}</Link>
      <br />
    </>
  );

  const forgotPwdLinkContent = (showExternalFields) ? forgotPwdLink : null;

  const registerLink = (
    <>
      <br />
      <Link to={UI_ROUTES.REGISTER}>{REGISTER}</Link>
    </>
  );

  const userGuideLink = (
    <>
      <br />
      <Link to={{ pathname: USER_GUIDE_URL.INTERNAL_HOW_TO_LOGIN }} target="_blank" >{HOW_TO_LOGIN}</Link>
      <br />
    </>
  );

  return (
    <Layout >
      <AppHeader />
      <Layout className="layoutStyle" >
        <Spin spinning={loading} tip={spinningText} size="large">
          <div className="login-form shadow-lg p-3 mb-5 bg-white rounded">
            {pageTitle}
            {errorAlertContent}
            {finalErrorAlertContent}
            <Row
              type="flex" justify="center" align="middle"
              style={{ paddingTop: "5px" }}
            >
              <Col span="15">
                <Form
                  name="basic" initialValues={{ remember: true }} form={form}
                  onFinish={onFinish} layout="vertical" align="left"
                >
                  <Item
                    label={EMAIL_ADDRESS} name="email"
                    rules={[{
                      required: true, type: "email",
                      message: PROVIDE_VALID_EMAIL
                    }]}
                  >
                    <Input autoComplete="off" readOnly={false} />
                  </Item>
                  <br />
                  {showExternalFields &&
                    <Item
                      label={PASSWORD} name="password" validateTrigger
                      rules={[{ required: true, message: ENTER_PASSWORD }]}
                    >
                      <Input.Password />
                    </Item>
                  }
                  <Item>
                    {submitButton}
                    <div style={{ width: "100%", textAlign: "center" }}>
                      {forgotPwdLinkContent}
                      {registerLink}
                    </div>
                  </Item>
                </Form>
              </Col>
            </Row>
          </div>
        </Spin>
        <AppFooter />
      </Layout>
    </Layout>
  );
};

export default Login;

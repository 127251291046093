import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension";
import rootReducer from "../reducers";

const initialState = {};

const middlewares = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevToolsDevelopmentOnly(applyMiddleware(...middlewares))
);

export default store;

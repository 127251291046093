import jwt_decode from "jwt-decode";
import { UserAgentApplication } from 'msal';
import { getEmailDomain } from './sharedUtils';

import {
  INTERNAL_USER_DOMAIN, ROLE, BLOCKED_DOMAINS, BLOCKED_DOMAINS_TO_REGISTER
} from "./appConstants";

export const requiresInteraction = (errorMessage) => {
  if (!errorMessage || !errorMessage.length) return false;

  return (
    errorMessage.indexOf('consent_required') > -1 ||
    errorMessage.indexOf('interaction_required') > -1 ||
    errorMessage.indexOf('login_required') > -1
  );
};

export const fetchMsGraph = async (url, accessToken) => {
  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });

  response.setHeader(
    'Strict-Transport-Security',
    'max-age=31536000; includeSubDomains; preload'
  );

  return response.json();
};

export const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ') > -1;
  const msie11 = ua.indexOf('Trident/') > -1;

  return msie || msie11;
};

export const AUTH_SCOPES = {
  OPENID: 'openid',
  OFFLINE_ACCESS: 'offline_access',
  PROFILE: 'profile'
};

export const AUTH_REQUESTS = {
  LOGIN: {
    scopes: [AUTH_SCOPES.OPENID, AUTH_SCOPES.PROFILE],
  },
  EMAIL: {
    scopes: [],
  },
  REFRESH_TOKEN: {
    scopes: [process.env.REACT_APP_CLIENT_ID],
  },
};

export const msalApp = new UserAgentApplication({
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    validateAuthority: process.env.REACT_APP_VALIDATE_AUTHORITY === 'true',
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    navigateToLoginRequestUrl: process.env.REACT_APP_NAVIGATE_TO_LOGIN_REQUEST_URL === 'true',
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: isIE(),
  },
  system: {
    navigateFrameWait: 0,
  },
});

/**
 * @returns `true` if `email` has an internal domain
 */
export const checkEmailIsInternal = (email) => {
  if (!email) return false;

  const emailDomain = getEmailDomain(email);

  return emailDomain.endsWith(INTERNAL_USER_DOMAIN);
};

/**
 * @returns `true` if `email` has an external domain
 */
export const checkEmailIsExternal = (email) => {
  const isInternal = checkEmailIsInternal(email);

  return !isInternal;
};

/**
 * @returns `true` if `emailId` is internal & `role` is admin
 */
export const checkEmployeeIsAdmin = ({emailId, role}) => {
  const isInternalEmail = checkEmailIsInternal(emailId);
  const isAdmin = (role === ROLE.ADMIN);

  return (isInternalEmail && isAdmin);
};

/**
 * @returns `true` if `emailId` is internal & `role` is infosec
 */
export const checkEmployeeIsInfosec = ({emailId, role}) => {
  const isInternalEmail = checkEmailIsInternal(emailId);
  const isInfosec = (role === ROLE.INFOSEC);

  return (isInternalEmail && isInfosec);
};

/**
 * @returns `true` if `emailId` is internal & `userToken` belongs to a manager
 */
export const checkEmployeeIsManager = ({emailId, userToken}) => {
  const isInternalEmail = checkEmailIsInternal(emailId);

  const decodedToken = (userToken !== "")
    ? jwt_decode(userToken)
    : {};

  const isManager = decodedToken?.isManager ?? false;

  return (isInternalEmail && isManager);
};

/**
 * @param {String} email
 * @returns `true` if the email domain is blocked for login
 */
export const checkEmailCanLogin = (email) => {
  const domain = getEmailDomain(email);
  const blocked = BLOCKED_DOMAINS.includes(domain);

  return !blocked;
};

/**
* @param {String} email
* @returns `true` if the email domain is blocked for registration
*/
export const checkEmailCanRegister = (email) => {
  const domain = getEmailDomain(email);
  const blocked = BLOCKED_DOMAINS_TO_REGISTER.includes(domain);

  return !blocked;
};

import { Pagination } from "antd";

const CPagination = ({total, defaultPageSize, defaultCurrent, onChange}) => {

  return (
    total > defaultPageSize && <> <br /><Pagination
      total={total}
      onChange={onChange}
      current={defaultCurrent}
      pageSize={defaultPageSize}
    />
    </>
  );
};

export default CPagination;

import React, { useEffect } from "react";
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import { UI_ROUTES } from "../../utils/webConstants";
import { useSelector, useDispatch } from "react-redux";
import { forgotPassword } from "../../redux/actions/userActions";
import { FORGOT_PASSWORD_COMPONENT } from "../../utils/appConstants";
import { Form, Input, Button, Modal, Spin, Alert, Layout } from "antd";

const layout = { labelCol: { span: 10 }, wrapperCol: { span: 20 } };

const tailLayout = { wrapperCol: { offset: 8, span: 28 } };

const ForgotPassword = ({ location, history }) => {
  const dispatch = useDispatch();
  const userForgotPassword = useSelector((state) => state.userForgotPassword);
  const { data, loading, error, spinningText } = userForgotPassword;

  const redirectToLogin = () => history.push(UI_ROUTES.LOGIN);

  useEffect(() => {
    if (data?.payload) {
      Modal.info({
        content: (
          <div>
            <p>{FORGOT_PASSWORD_COMPONENT.MODAL_MESSAGE}</p>
          </div>
        ),
        onOk() {
          redirectToLogin();
        },
      });
    }
  }, [data]);

  const onFinish = (formData) => {
    const { emailId } = formData;
    dispatch(forgotPassword(emailId));
  };

  const onFinishFailed = (errorInfo) => {
    console.error('ForgotPassword - onFinishFailed(): ', errorInfo);
  };

  return (
    <Layout >
      <AppHeader />
      <Layout className="layoutStyle" >
        <Spin spinning={loading} tip={spinningText} size="large">
          <div className="login-form shadow-lg p-3 mb-5 bg-white rounded">
            {
              error && (
                <Alert
                  message="Error" type="error" showIcon closable
                  description={error}
                />
              )
            }
            <Form
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <h2 className="text-center login-logo">Forgot Password</h2>
              <Form.Item
                label="Email Address"
                name="emailId"
                rules={[{
                  required: true,
                  message: FORGOT_PASSWORD_COMPONENT.EMAIL_ERROR_MESSAGE
                }]}
              >
                <Input id="emailId" type="email" />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">Submit</Button>
                &nbsp;&nbsp;
                <Button onClick={redirectToLogin}>Cancel</Button>
              </Form.Item>
            </Form>
          </div>
        </Spin>
        <AppFooter />
      </Layout>
    </Layout>
  );
};

export default ForgotPassword;


import React, { Fragment } from 'react';
import { FILE_DOWNLOAD_COMPONENT } from '../../utils/appConstants';
import { Button, Progress, Skeleton, Space, Typography, Alert } from 'antd';

import {
  StopTwoTone, LoadingOutlined, WarningTwoTone
} from "@ant-design/icons";

const { MODAL_TITLES, STATUS_TYPES } = FILE_DOWNLOAD_COMPONENT;

const {
  DOWNLOAD_ACCESS_PENDING, DOWNLOAD_ACCESS_ALLOWED, DOWNLOAD_ACCESS_DENIED,
  DOWNLOAD_SUCCESS, NETWORK_ERROR
} = STATUS_TYPES;

const { Paragraph } = Typography;

export function ModalTitle({ status }) {
  const {
    DOWNLOADING, DOWNLOAD_DENIED, DOWNLOAD_COMPLETED, CHECKING_ACCESS,
    NETWORK_PROBLEM
  } = MODAL_TITLES;

  const iconStyle = { verticalAlign: 'baseline', fontSize: '1.2rem' };
  const loadingIcon = <LoadingOutlined style={iconStyle} />;
  const deniedIcon = <StopTwoTone twoToneColor="red" style={iconStyle} />;
  const errorIcon = <WarningTwoTone twoToneColor="orange" style={iconStyle} />;
  let title = null;

  switch (status) {
    case DOWNLOAD_ACCESS_ALLOWED:
      title = <>{DOWNLOADING}</>;
      break;

    case DOWNLOAD_ACCESS_DENIED:
      title = <>{DOWNLOAD_DENIED}{deniedIcon}</>;
      break;

    case DOWNLOAD_SUCCESS:
      title = <>{DOWNLOAD_COMPLETED}</>;
      break;

    case NETWORK_ERROR:
      title = <>{NETWORK_PROBLEM}{errorIcon}</>;
      break;

    case DOWNLOAD_ACCESS_PENDING:
    default:
      title = <>{CHECKING_ACCESS}{loadingIcon}</>;
  }

  return <Space>{title}</Space>;
}

export function DownloadProgress({ info }) {
  return (
    <>
      {info.map((item, index) => (
        <Fragment key={item.id}>
          <ProgressBarLabel label={item.name} />
          <ProgressBar progress={item.progress} />
          {((info.length > 1) && (info.length - 1 > index)) ? <hr /> : null}
        </Fragment>
      ))}
    </>
  );
}

function ProgressBarLabel({ label }) {
  return (
    <Paragraph ellipsis={{ tooltip: label }} style={{ marginBottom: '0em' }}>
      {label}
    </Paragraph>
  );
}

function ProgressBar({ progress }) {
  const showAnimation = (progress < 100) ? 'active' : null;

  return (
    <Progress percent={progress} status={showAnimation} strokeLinecap="butt" />
  );
}

export function ModalFooter({ status, handleCancel }) {
  const isDownloadCompleted = (status === DOWNLOAD_SUCCESS);
  const cancelButtonLabel = isDownloadCompleted ? 'Close' : 'Cancel';
  const isButtonTypeDanger = (!isDownloadCompleted);

  return (
    <Button type="primary" onClick={handleCancel} danger={isButtonTypeDanger}>
      {cancelButtonLabel}
    </Button>
  );
}

export function LoadingAnimation({ fileIds }) {
  const idCount = fileIds.length;

  return (
    <>
      {fileIds.map((item, index) => (
        <Fragment key={item}>
          <Skeleton.Button size='large' active block />
          {((idCount > 1) && (idCount - 1 > index)) ? <hr /> : null}
        </Fragment>
      ))}
    </>
  );
}

export function DeniedAccess({ fileCount }) {
  const description = (fileCount > 1)
    ? 'You do not have access to download these files.'
    : 'You do not have access to download this file.'

  return <Alert description={description} type="warning" showIcon />;
}

export function NetworkError() {
  const description = 'Please check your network connection.';

  return <Alert description={description} type="warning" showIcon />;
}

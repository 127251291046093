import axios from "axios";
import store from "../redux/store/store";
import { UI_ROUTES } from './webConstants';
import { logout } from "../redux/actions/userActions";
import { API_URL } from './appConstants';

const instance = axios.create({ baseURL: API_URL });

instance.interceptors.response.use(
    (response) => response,
    (error) => handleError(error)
);

function handleError(error) {
    const { config: originalRequest, response } = error;

    if (response.status === 401) {
        store.dispatch(logout());
        window.location.href = UI_ROUTES.LOGIN;

        return instance(originalRequest);
    }

    return Promise.reject(error);
}

export default instance;

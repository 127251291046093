import React, { useEffect } from "react";
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import { UI_ROUTES } from "../../utils/webConstants";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { resetPassword } from "../../redux/actions/userActions";
import { RESET_PASSWORD_COMPONENT } from "../../utils/appConstants";

import {
  Form, Input, Button, Modal, Spin, Alert, Divider, message, Layout, Row, Col
} from "antd";

const {
  ENTER_NEW_PASSWORD, FORM_INFO, PASSWORD_FORMAT, ENTER_VALID_PASSWORD,
  PASSWORD_UPDATED, CONFIRM_NEW_PASSWORD, PASSWORDS_DONOT_MATCH
} = RESET_PASSWORD_COMPONENT;

const columnSizes = { xs: { span: 12 }, sm: { span: 8 } };
const layout = { labelCol: columnSizes, wrapperCol: columnSizes };
const tailLayout = { wrapperCol: { offset: 8, span: 28 } };

const ResetPassword = ({ location, history }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const userResetPassword = useSelector((state) => state.userResetPassword);
  const { data, loading, error, spinningText } = userResetPassword;

  const { userInfo } = useSelector((state) => state.userLogin);
  const sessionId = userInfo?.sessionId ?? null;
  const userToken = userInfo?.userToken ?? null;

  useEffect(() => {
    if (data?.payload) {
      Modal.info({
        content: <div><p>{PASSWORD_UPDATED}</p></div>,
        onOk() {
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = UI_ROUTES.BASE;
        },
      });
    }
  }, [data]);

  const onFinish = (formData) => {
    const { password, confirmPassword } = formData;
    passwordOnfocusout();

    if (password !== confirmPassword) {
      message.error(PASSWORDS_DONOT_MATCH);
    }
    else {
      dispatch(resetPassword(userToken, password, sessionId));
    }
  };

  const onFinishFailed = (errorInfo) => console.error(errorInfo);

  const infoIconClick = () => {
    Modal.info({
      title: 'Reset Password', content: <div><p>{FORM_INFO}</p></div>
    });
  };

  const passwordValidation = (password) => {
    let count = 0;

    if (password.length < 8) count++;

    if (!/\d/.test(password)) count++;

    if (!/[a-z]/.test(password)) count++;

    if (!/[A-Z]/.test(password)) count++;

    if (! /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g.test(password)) count++;

    return count <= 1;
  };

  const passwordOnfocusout = () => {
    const value = form.getFieldValue("password");

    if (/\s/.test(value)) {
      form.setFields([{ name: "password", errors: [ENTER_VALID_PASSWORD] }]);
    }
    else if (!passwordValidation(value)) {
      form.setFields([{ name: "password", errors: [PASSWORD_FORMAT] }]);
    }
  };

  return (
    <Layout>
      <AppHeader />
      <Layout className="layoutStyle">
        <Spin spinning={loading} tip={spinningText} size="large">
          <Divider orientation="left">
            Reset Password&nbsp;
            <span>
              <Button
                type="text" shape="circle" icon={<InfoCircleOutlined />}
                onClick={infoIconClick}
              />
            </span>
          </Divider>

          <div style={{ paddingTop: "35px" }}>
            {error && <Alert
              message="Error" type="error" showIcon closable
              description={error}
            />}

            <Form
              {...layout} name="basic" initialValues={{ remember: true }}
              onFinish={onFinish} form={form} onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Enter New Password" name="password"
                rules={[{ required: true, message: ENTER_NEW_PASSWORD }]}
              >
                <Input.Password onBlur={passwordOnfocusout} />
              </Form.Item>

              <Row>
                <Col span="8"></Col>
                <Col span="8"><p>{PASSWORD_FORMAT}</p></Col>
              </Row>

              <Form.Item
                label="Confirm Password" name="confirmPassword"
                rules={[{ required: true, message: CONFIRM_NEW_PASSWORD }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">Submit</Button>
                &nbsp;&nbsp;
                <Button href={UI_ROUTES.LOGIN}>Cancel</Button>
              </Form.Item>
            </Form>
          </div>
        </Spin>
        <AppFooter />
      </Layout>
    </Layout>
  );
};

export default ResetPassword;

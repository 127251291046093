import React, { useEffect, useState } from "react";
import Pagination from "../components/Pagination";
import { useSelector } from "react-redux";
import Table from "../components/Table";
import axios from "../../utils/axios";
import assembler from "url-assembler";
import { Link, useHistory } from "react-router-dom";
import EmptyTable from '../components/EmptyTable';
import { Form, Input, Button, Popconfirm, message } from "antd";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";

import {
    INACTIVATE_REQUEST_COMPONENT, AXIOS_CONFIG_VALUES
} from "../../utils/appConstants";

import {
    API_GET_REQUEST, API_DELETE_REQUEST, UI_ROUTES
} from "../../utils/webConstants";

const { AUTHORIZATION, PUT_METHOD } = AXIOS_CONFIG_VALUES;

const {
    INACTIVATE_CONFIRM_MESSAGE, NOT_DELETED, DELETE_SUCCESS_MESSAGE,
    NO_REQUESTS
} = INACTIVATE_REQUEST_COMPONENT;

const InactivateRequests = () => {
    let history = useHistory();
    const [search, setSearch] = useState("");
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);

    const { userInfo } = useSelector((state) => state.userLogin);
    const userToken = (userInfo) ? userInfo.userToken : '';

    const columns = [
        {
            title: "File Transfer Request Name", dataIndex: "name", key: "name"
        },
        {
            title: "Actions", dataIndex: "actions", key: "actions", width: 230,
            render: (text, record) => confirmDelete(record.id)
        },
        {
            title: "Recipient(s)", dataIndex: "employees", key: "employees",
            render: (text, record) => getFormattedRecipients(record)
        }
    ];

    const getData = async () => {
        try {
            const url = assembler(API_GET_REQUEST).query({
                page, limit, search, status: NOT_DELETED
            });

            const res = await axios.get(url, {
                headers: { [AUTHORIZATION]: userToken }
            });

            setTableData(res.data.payload);
            setTotal(res.data.total);
        }
        catch (error) {
            if (error.response.status === 403) history.push(UI_ROUTES.BASE);
            else console.error(error);
        }
    };

    useEffect(() => {
        getData({ page, limit, search });
    }, [page, limit]);

    const onDeleteClick = (id) => {
        axios({
            method: PUT_METHOD,
            url: API_DELETE_REQUEST + "/" + id,
            headers: { [AUTHORIZATION]: userToken }
        })
        .then((response) => {
            if (response?.status === 200) {
                message.success(DELETE_SUCCESS_MESSAGE);
                getData({ page, limit, search });
            }
        });
    };

    function confirmDelete(id) {
        return (
            <div className="deleteAction">
                <Popconfirm
                    okText="Yes" cancelText="No"
                    title={INACTIVATE_CONFIRM_MESSAGE}
                    onConfirm={() => onDeleteClick(id)}
                    icon={<InfoCircleOutlined style={{ color: 'red' }} />}
                >
                    <Link to="#" style={{ color: "red" }}>
                        <DeleteOutlined style={{ color: "red", height: "17px" }} />
                        &nbsp;Delete
                    </Link>
                </Popconfirm>
            </div>
        );
    }

    function getFormattedRecipients(record) {
        let users = "";

        record.associatedEmployees.forEach(item => {
            users = (users === "") ? users : users + ", ";
            users = users + item.name + " (" + item.role.name + ")";
        });

        return `${users}`;
    }

    const handlePaginationChange = (page, pageSize) => {
        setPage(page);
        setLimit(pageSize);
    };

    return (
        <>
            <div>
                <Form style={{ display: "flex" }}>
                    <Form.Item>
                        <Input
                            placeholder="search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary" style={{ padding: "auto" }}
                            onClick={() => getData({ page, limit, search })}
                        >
                            Search all
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <Table
                columns={columns} dataSource={tableData}
                locale={{ emptyText: <EmptyTable text={NO_REQUESTS} /> }}
            />
            <div align={"center"}>
                <Pagination
                    total={total} defaultPageSize={10} defaultCurrent={page}
                    onChange={handlePaginationChange}
                />
            </div>
        </>
    );
};

export default InactivateRequests;

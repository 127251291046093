import React, { useState, useEffect, useRef } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import axios from "../../utils/axios";
import assembler from "url-assembler";
import Pagination from "../components/Pagination";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import CommentsModal from "./CommentsModal";
import FileUpload from "../components/FileUpload";
//import FileComment from "../components/FileComment";
import Folder from "../components/Folder";
//import ViewFile from "../components/ViewFile";
import KeyConfirmationModel from "../components/KeyConfirmationModel";
import { checkEmailIsExternal } from "../../utils/authUtils";
import FileDownload from '../components/FileDownload';
import EmptyTable from '../components/EmptyTable';

import {
  getFormattedDateTime, getRoundedFileSize
} from '../../utils/sharedUtils';

import {
  DeleteOutlined, FileOutlined, EditOutlined, UploadOutlined, CommentOutlined,
  FolderAddOutlined, FolderOutlined, InfoCircleOutlined, DownloadOutlined
} from "@ant-design/icons";

import {
  Row, Col, Button, Table, message, Popconfirm, Spin, Tree, Modal //Breadcrumb
} from "antd";

import {
  API_GET_FILE, API_DELETE_FILE, API_GET_FILE_HIERARCHY, API_FOLDER,
  API_GET_FILE_PARENT_DETAILS, UI_ROUTES, API_BASE //API_GET_KEY
} from "../../utils/webConstants";

import {
  USERTYPE, FILE_LIST_COMPONENT, REQUEST_DETAIL_TABS, AXIOS_CONFIG_VALUES,
  FOLDER_LIST_COMPONENT as CONSTANTS
} from "../../utils/appConstants";

const {
  AUTHORIZATION, CONTENT_TYPE, APPLICATION_JSON, DELETE_METHOD
} = AXIOS_CONFIG_VALUES;

// buffer needs a reference browser side
const Buffer = require('buffer/').Buffer

/*const extDoc = [
  'pdf', 'xml', 'json', 'csv', 'html', 'pdf', 'png', 'jpg', 'jpeg',
  'gif', 'txt', 'docx', 'doc', 'ppt', 'pptx', 'xls', 'xlsx'
];*/

const { confirm } = Modal;
const { DirectoryTree } = Tree;
const actionButtonStyle = { verticalAlign: 'baseline' };

const FolderList = ({
  requestId, /*taskId,*/ email, userType, customerEmailData, setSelectedFiles,
  setCanApprove, associatedEmployees, isClosedRequest, isServiceReqExternal,
  fileTabCount, selectedServiceRequestName, setSelectedServiceRequestName,
  isFileUplodaAllow
}) => {
  let history = useHistory();

  const { userInfo } = useSelector((state) => state.userLogin);
  const userToken = userInfo?.userToken ?? '';

  const decodedToken = userToken !== "" ? jwt_decode(userToken) : {};
  const userEmail = decodedToken.emailId ? decodedToken.emailId : "";
  const isExternalEmail = checkEmailIsExternal(userEmail);
  const authHeader = { [AUTHORIZATION]: userToken };

  const contentAndAuthHeaders = {
    [CONTENT_TYPE]: APPLICATION_JSON, [AUTHORIZATION]: userToken
  };

  const refFiles = useRef();
  const params = useParams();
  const navigationType = params.navType ? params.navType : "";

  const [fileTableData, setfileTableData] = useState([]);
  const [loadingFilesTab, setLoadingFilesTab] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isFileUploadModelOpen, setIsFileUploadModelOpen] = useState(false);
  const [selectedFilesForDownload, setSelectedFilesForDownload] = useState([]);
  const [selectedFilesForDelete, setSelectedFilesForDelete] = useState([]);
  const [isDisableDelete, setIsDisableDelete] = useState(true);
  const [fileCommentModalVisible, setFileCommentModalVisible] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [issFolderModelOpen, setIsFolderModelOpen] = useState(false);
  const [folderId, setFolderId] = useState(null);

  //const [showBreadCrumb, setShowBreadCrumb] = useState(true);

  const [breadCrumbData, setBreadCrumbData] = useState([{ folderId: null, folderName: "Home" }]);
  const [folderTreeData, setFolderTreeData] = useState([]);
  const [treeSelectedKey, setTreeSelectedkey] = useState([]);
  const [treeSelectedExpandKey, setTreeSelectedExpandKey] = useState([]);
  const [folderEdit, setFolderEdit] = useState(false);
  const [selectedFolderIdToEdit, setSelectedFolderIdToEdit] = useState(null);

  //const [folderPermission, setFolderPermission] = useState(null);
  /*const [isOpenViewFile, setIsOpenViewFile] = useState(false);
  const [fileId, setFileId] = useState(null);
  const [isOpenViewFileRecord, setIsOpenViewFileRecord] = useState(null);
  const [fileName, setFileName] = useState("");*/

  const [employeeEmailIds, setEmployeeEmailIds] = useState([]);
  const [isOnlyManager, setOnlyManager] = useState(false);
  const [associatedFolderIds, setAssociatedFolderIds] = useState([]);
  const [isCommentsModalVisible, setIsCommentsModalVisible] = useState(false);

  //const [isfileCommentModalVisible, setIsfileCommentModalVisible] = useState(false);
  //const [fileCommentMessage, setFileCommentMessage] = useState("");

  const [fileIdForComment, setFileIdForComment] = useState("");
  const [screenType, setScreenType] = useState("");
  const [navigationCount, setNavigationCount] = useState(1);
  const [rootFolderId, setRootFolderId] = useState(null);
  const [showKeyManagementConfirmationModal, setShowKeyManagementConfirmationModal] = useState(false);
  const [canCreateFolder, setCanCreateFolder] = useState(false);
  const [canUploadFile, setCanUploadFile] = useState(false);
  const [showAddFolderatRootLevel, setShowAddFolderatRootLevel] = useState(false);
  const [showUploadFileatRootLevel, setShowUploadFileatRootLevel] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [fileIdsForDownload, setFileIdsForDownload] = useState([]);
  const [folderIdsForDownload, setFolderIdsForDownload] = useState([]);

  const clearCheckBoxSelections = () => {
    setSelectedFilesForDownload([]);
    setSelectedFilesForDelete([]);
    setSelectedRowKeys([]);
  };

  const getData = async (folderIdValue) => {
    try {
      setLoadingFilesTab(true);

      const url = assembler(API_GET_FILE)
        .param({ id: requestId })
        .query({
          page, limit,
          // taskId,
          parentId: folderIdValue,
          // fileId: fileIdForComment ? fileIdForComment : 0
        });

      const res = await axios.get(url, { headers: authHeader });

      const values = res.data.payload.map((elem, index) => {
        elem.key = elem.id;
        return elem;
      });

      setShowAddFolderatRootLevel(
        // userType === USERTYPE.INITIATOR ? true : res.data.isManager
        // see => https://github.com/LexisNexis-RBA/SFE-UI/issues/543
        false
      );

      setShowUploadFileatRootLevel(
        (userType === USERTYPE.INITIATOR || userType === USERTYPE.FULFILLER || userType === USERTYPE.CUSTOMER) ? true : false
      );

      setfileTableData(values);
      setEmployeeEmailIds(res.data.employeeEmailIds);
      setLoadingFilesTab(false);

      const emp = associatedEmployees.find(e => e.email === userEmail);

      if (res.data.employeeEmailIds.length > 0 && !emp) setOnlyManager(true);

      setTotal(res.data.total);

      if (res.data.canApprove) setCanApprove(res.data.canApprove);

      if (res.data.associatedFolderIds) {
        setAssociatedFolderIds(res.data.associatedFolderIds);
      }
    }
    catch (error) {
      message.error(CONSTANTS.MESSAGES.ERROR_WHEN_GETTING_FILE_AND_FOLDER_DATA);
      console.error(error);
    }
  };

  const getFileParentDetails = async () => {
    const urlParam = { id: fileIdForComment };
    const url = assembler(API_GET_FILE_PARENT_DETAILS).param(urlParam);
    const fileParentDetails = await axios.get(url, { headers: authHeader });

    return fileParentDetails;
  };

  const getFolderHierarchy = () => {
    try {
      setLoadingFilesTab(true);
      const urlParam = { id: requestId, type: "withoutFile" };
      const url = assembler(API_GET_FILE_HIERARCHY).param(urlParam);

      axios.get(url, { headers: authHeader })
      .then(res => {
        const hierarchyData = [{
          title: "Home", key: "Home", folderId: null,
          children: res.data.payload.tree
        }];

        const hierarchy = addProp(
          hierarchyData, "icon", <FolderOutlined />
        );

        setFolderTreeData(hierarchy);
        setTreeSelectedExpandKey(res.data.payload.keyValues);
        setLoadingFilesTab(false);
      })
    }
    catch (error) {
      console.error(error);
    }
  };

  const addProp = (arr, name, value) => {
    arr.forEach(item => {
      item[name] = value;
      addProp(item.children, name, value);
    })

    return arr;
  };

  const getFolderDetail = async () => {
    try {
      const url = `${API_BASE}/folders/${requestId}/${folderId}`;
      const { data } = await axios.get(url, { headers: authHeader });
      //setFolderPermission(data.payload.loggedInUserPermission);
      setCanUploadFile(data.payload.canUploadFile);
      setCanCreateFolder(data.payload.canCreateFolder);
    }
    catch (error) {
      console.error(error);
    }
  };

  const CommentsModalSubmit = () => setIsCommentsModalVisible(false);

  useEffect(() => {
    if (history.location.pathname.includes(UI_ROUTES.REQUEST_DETAIL_PATH)) {
      const pathname = history.location.pathname.split(UI_ROUTES.BASE);

      if (pathname[3] === "fileComment" || pathname[3] === "file") {
        const encodedValueFile = pathname[4];
        const bufferObjFile = Buffer.from(encodedValueFile, "base64");
        const decodedStringFile = bufferObjFile.toString("utf8");

        setFileIdForComment(decodedStringFile);
        setScreenType(pathname[3]);
      }
    }
  }, []);

  useEffect(async () => {
    setNavigationCount(fileTabCount);

    if (navigationType === "Download")
      refFiles.current && refFiles.current.focus();

    if (navigationCount !== fileTabCount) {
      setFolderId(null);
      await getData(null);
      getFolderHierarchy();
      onSelect([`Home`], { node: { folderId: null } });
      setRootFolderId(null);
      setTreeSelectedkey(['Home']);
    }
    else {
      await getData(folderId);
      getFolderHierarchy();

      if (folderId) getFolderDetail();
    }
  }, [page, limit, folderId, fileTabCount]);

  useEffect(async () => {
    if (fileIdForComment) {
      const res = await getFileParentDetails();

      if (res?.data?.parentFolderId) {
        const folderId = associatedFolderIds.filter(
          x => x === res.data.parentFolderId
        );

        if (folderId?.length > 0) {
          let folderDetails;

          for (let node of folderTreeData) {
            folderDetails = searchTree(node, res.data.parentFolderId);

            if (folderDetails) break;
          }

          if (folderDetails?.key) {
            onSelect(
              folderDetails.key,
              { node: { folderId: res.data.parentFolderId } }
            );

            if (screenType === "fileComment") onFileListRowClick(res.data);

            setTreeSelectedkey([folderDetails.key]);
          }
        }
        else {
          if (screenType === "fileComment") {
            confirm({
              content: `You don't have permission to see ${res.data.file ? res.data.file.name : ""} file comments`
            });
          }
        }

        setFileIdForComment(null);
      }
      else if (res?.data?.file) {
        if (screenType === "fileComment") onFileListRowClick(res.data);

        setFileIdForComment(null);
        setTreeSelectedkey(['Home']);
      }
    }
  }, [folderTreeData]);

  const onFileListRowClick = (fileMetaData) => {
    setSelectedFileId(fileMetaData.file.id);
    setSelectedFileName(fileMetaData.file ? fileMetaData.file.name : null);
    setFileCommentModalVisible(true);
  };

  const handleFolderEdit = (fileMetaData) => {
    setIsFolderModelOpen(true);
    setSelectedFolderIdToEdit(fileMetaData.folder.id);
    setFolderEdit(true);
  };

  const handleFolderClick = (e, folderId, folderName, parentFolderId) => {
    setFolderId(folderId);
    setPage(1);
    onSelect([`${folderName}-${folderId}`], { node: { folderId: folderId } });

    if (!parentFolderId) setRootFolderId(folderId);

    // setShowBreadCrumb(true);
    //setBreadCrumbData([...breadCrumbData, { folderId, folderName, key: `${folderName}-${folderId}` }])
    // setTreeSelectedkey([`${folderName}-${folderId}`]);
    // setTreeSelectedExpandKey(breadCrumbData.map(e => e.key));
  };

  /*const canEditFolder = (record) => {
    if (!folderId && userType === "INITIATOR") {
      return false
    }
    else {
      if (folderId) {
        var folder = record.folder.folder_accesses.find(
          e => e.customer_email ? e.customer_email.email.trim().toLowerCase() === userEmail.trim().toLowerCase() : e.associated_employee.email.trim().toLowerCase() === userEmail.trim().toLowerCase()
        );

        if (folder) return folder.access !== "edit";
        else return true;
      }
      else {
        return true;
      }
    }
  };*/

  const getFileNameOrFolderName = ({ folder, parentFolderId, file }) => {
    const folderNameLink = (
      <div className="deleteAction">
        <Link
          to="#"
          style={{ textDecoration: "none" }}
          onClick={
            (e) => handleFolderClick(e, folder?.id, folder?.name, parentFolderId)
          }
        >
          <FolderOutlined style={{ height: "17px" }} /> {folder?.name}
        </Link>
      </div>
    );

    const fileName = (
      <div className="deleteAction">
        <FileOutlined style={{ height: "17px" }} /> {file?.name}
      </div>
    );

    return (folder) ? folderNameLink : fileName;
  };

  const fileColumns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "filename",
      render: (filename, record) => getFileNameOrFolderName(record),
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      render: (text, record) => getRoundedFileSize(record.file.size)
    },
    {
      title: "Last Modified On",
      dataIndex: "modifiedOn",
      key: "modifiedOn",
      className: "formatDate",
      render: (modifiedOn, record) => {
        return (record.file)
          ? getFormattedDateTime(record.file.modifiedOn)
          : getFormattedDateTime(record.folder.modifiedOn);
      }
    },
    {
      title: "Last Modified By",
      dataIndex: "modifiedBy",
      key: "modifiedBy",
      render: (text, record) => {
        let userName = (record.file)
          ? record.file.modifiedBy
          : record.folder.modifiedBy;

        let customerObject = customerEmailData.find(
          o => o.email === userName
        );

        if (customerObject?.user) {
          const { firstName, lastName } = customerObject.user;
          userName = firstName + " " + lastName;
        }
        else {
          let employeeObject = associatedEmployees.find(o => o.email === userName);

          if (employeeObject) userName = employeeObject.name;
        }

        return `${userName}`;
      }
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 200,
      render: (text, record) => {
        return (
          record.file ? <div className="deleteAction">
            <Row>
              {(record.deletePermission && !isClosedRequest) ? <Col span="10"> <Popconfirm
                title={isExternalEmail? FILE_LIST_COMPONENT.EXTERNAL_USER_DELETE_CONFIRM_MESSAGE : FILE_LIST_COMPONENT.DELETE_CONFIRM_MESSAGE}
                onConfirm={() => onDeleteFileClick(record.file.id)}
                okText="Yes"
                cancelText="No"
                icon={<InfoCircleOutlined style={{ color: 'red' }} />}
              >  <Link to="#" style={{ color: "red", textDecoration: "none" }}>
                  <DeleteOutlined style={{ color: "red" }} />&nbsp;{CONSTANTS.BUTTON_LABELS.DELETE}
                </Link>
              </Popconfirm></Col> : null}
              {record.commentPermission && <Col span="13"> <> <Link to="#" style={{ textDecoration: "none" }} onClick={() => { setIsCommentsModalVisible(true) }}><CommentOutlined />&nbsp;{CONSTANTS.BUTTON_LABELS.COMMENTS}</Link></></Col>}
              {/* {record.previewPermission && <Col span="10"><><Link to="#" style={{ textDecoration: "none" }} onClick={() => { setFileId(record.fileId); setFileName(record.name); setIsOpenViewFile(true); }} ><FileOutlined className="fileIcon" />&nbsp;Preview</Link></></Col>} */}
              {/*extDoc.indexOf(record.file.name.split('.').pop().toLowerCase()) !== -1 ? (   <Col span="10"><Link to="#" style={{ textDecoration: "none" }} onClick={() => { setFileId(record.fileId); setFileName(record.name); setIsOpenViewFileRecord(record); setIsOpenViewFile(true); }} ><FileOutlined className="fileIcon" />&nbsp;Preview</Link></Col> ) : ''*/}
              {/*record.downloadPermission && <Col span="13"><><Link to="#" style={{ textDecoration: "none" }} onClick={() => handleDownloads(record.file.id, record.file.name)} ><FileOutlined className="fileIcon" />&nbsp;Download</Link></></Col>*/}
            </Row>
          </div> :
            <div className="deleteAction">
              {record.folderEdit && <> &nbsp;&nbsp;
                <Link to="#" style={{ textDecoration: "none", color: "blue" }} onClick={() => handleFolderEdit(record)}><EditOutlined />&nbsp;{CONSTANTS.BUTTON_LABELS.EDIT}</Link>&nbsp;&nbsp;&nbsp;&nbsp;</>}
              {(record.folderDelete && !isClosedRequest) ? <> &nbsp;&nbsp;
                <Popconfirm
                  title={CONSTANTS.MESSAGES.CONFIRM_FOLDER_DELETION}
                  onConfirm={() => onDeleteFolderClick(record.folder.id)}
                  okText="Yes"
                  cancelText="No"
                  icon={<InfoCircleOutlined style={{ color: 'red' }} />}
                >
                  <Link to="#" style={{ textDecoration: "none", color: "red" }} ><DeleteOutlined style={{ color: "red", height: "17px" }} />&nbsp;{CONSTANTS.BUTTON_LABELS.DELETE}</Link>
                </Popconfirm></> : null}
            </div>
        )
      }
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedFiles(selectedRowKeys);
      setSelectedRowKeys(selectedRowKeys);
      setSelectedFilesForDownload(selectedRows);
      setSelectedFilesForDelete(selectedRows);

      if (selectedRowKeys.length > 0) disableDeleteButton(selectedRowKeys);
      else setIsDisableDelete(true);
    }
  };

  const onPaginate = (page, pageSize) => {
    setPage(page);
    setLimit(pageSize);
  };

  const onDeleteFileClick = (id) => {
    axios({
      method: DELETE_METHOD,
      url: API_DELETE_FILE + "/" + id,
      headers: contentAndAuthHeaders,
      data: JSON.stringify({ id: id, status: "DELETE" })
    })
    .then((response) => {
      if (response?.status === 200) {
        message.success(CONSTANTS.MESSAGES.FILE_DELETION_SUCCESS);
        getData(folderId);
      }
    });
  };

  const onDeleteFolderClick = (id) => {
    const urlParam = { requestId };
    const urlQuery = { folderIds: id };
    const url = assembler(API_FOLDER).param(urlParam).query(urlQuery);

    axios.delete(url, { headers: authHeader })
    .then((response) => {
      if (response?.status === 200) {
        message.success(CONSTANTS.MESSAGES.FOLDER_DELETION_SUCCESS);
        getData(folderId);
        getFolderHierarchy();
      }
    });
  };

  const disableDeleteButton = (ids) => {
    let customerFiles = false;
    const customer = customerEmailData.find(x => x.email === userEmail);
    let fileNameCount = 0;
    let fileIds = ids;

    if (customer) {
      for (let i = 0; i < fileIds.length; i++) {
        const file = fileTableData.find(
          ele => ele.createdBy !== userEmail && ele.id === fileIds[i]
        );

        if (file) fileNameCount++;
      }

      if (fileNameCount === fileIds.length) setIsDisableDelete(true);
      else setIsDisableDelete(false);
    }
    else {
      let cutomerFileCount = 0;

      for (let i = 0; i < fileIds.length; i++) {
        const file = fileTableData.find(
          ele => ele.status === "ACTIVE" && ele.id === fileIds[i]
        );

        if (file && isServiceReqExternal) {
          cutomerFileCount++;
          customerFiles = true;
        }
      }

      if (cutomerFileCount === fileIds.length) setIsDisableDelete(true);
      else setIsDisableDelete(false);
    }
  };

  const handleFilesDelete = () => {
    let [fileIds, folderIds] = ["", ""];

    for (let i = 0; i < selectedFilesForDelete.length; i++) {
      let [file, folder] = [null, null];

      if (selectedFilesForDelete[i].file) {
        file = selectedFilesForDelete[i];
        fileIds = fileIds + file.file.id + ",";
      }

      if (selectedFilesForDelete[i].folder) {
        folder = selectedFilesForDelete[i];
        folderIds = folderIds + folder.folder.id + ",";
      }
    }

    const urlParam = { serviceRequestId: requestId };

    const urlQuery = {
      fileIds: fileIds.slice(0, -1), folderIds: folderIds.slice(0, -1)
    };

    const url = assembler(`${API_BASE}/folders/:serviceRequestId`)
      .param(urlParam)
      .query(urlQuery);

    setLoadingFilesTab(true);

    axios({
      method: DELETE_METHOD,
      url: url,
      headers: contentAndAuthHeaders
    })
    .then((response) => {
      setSelectedFilesForDownload([]);
      setSelectedFilesForDelete([]);
      setSelectedRowKeys([]);
      setLoadingFilesTab(false);

      if (response?.status === 200) {
        message.success(CONSTANTS.MESSAGES.FILE_DELETION_SUCCESS);
        getData(folderId);
        getFolderHierarchy();
      }
    })
    .catch((error) => {
      console.error(error.response.data.message)
      message.error(CONSTANTS.MESSAGES.CANNOT_DELETE_FILE_OR_FOLDER);
      setSelectedFilesForDownload([]);
      setSelectedFilesForDelete([]);
      setSelectedRowKeys([]);
      setLoadingFilesTab(false);
    })
  };

  const handleModalChange = (val) => setIsFileUploadModelOpen(val);

  const handleOnSubmit = (val) => { /*setIsLoading(val);*/ };

  /*const handleBreadCrumbClick = (folderId, selectedKey) => {
    setTreeSelectedkey([selectedKey]);
    setTreeSelectedExpandKey(breadCrumbData.map(e => e.key));

    const index = [...breadCrumbData].map(item => item.folderId).indexOf(folderId);

    if (folderId && index !== breadCrumbData.length - 1) {
      //const updatedData = breadCrumbData.slice(0, index + 1);
      //setBreadCrumbData(updatedData);
      setFolderId(folderId);
    }

    if (folderId === null) {
      setBreadCrumbData(breadCrumbData.slice(0, 1));
      setFolderId(folderId);
      //setShowBreadCrumb(false);
    }
  };*/

  /*const getBreadcrumbs = () => {
    return <>
      <Breadcrumb separator=">">
        {
          breadCrumbData.length > 0 && breadCrumbData.map(
            item => <Breadcrumb.Item onClick={() => handleBreadCrumbClick(item.folderId, item.key)}>{item.folderName}</Breadcrumb.Item>
          )
        }
      </Breadcrumb>
    </>
  };*/

  const searchTree = (element, matchingTitle) => {
    if (element.folderId == matchingTitle) {
      return element;
    }
    else if (element.children != null) {
      let result = null;

      for (let i = 0; result == null && i < element.children.length; i++) {
        result = searchTree(element.children[i], matchingTitle);
      }

      return result;
    }

    return null;
  };

  /*const getPath = (data, nodeId) => {
    let node = searchTree(data, nodeId);
    const nodePath = [];

    if (node) {
      nodePath.push({
        folderId: node.folderId, folderName: node.title, key: node.key
      });
    }

    while (node && node.parentId) {
      node = searchTree(data, node.parentId);
      nodePath.push({
        folderId: node.folderId, folderName: node.title, key: node.key
      });
    }

    return nodePath;
  };*/

  /*const search = (data, val) => {
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      const res = getPath(element, val);
      if (res && res.length > 0) {
        return [{ folderId: null, folderName: "Home" }, ...res.reverse()];
      }
    }
  };*/

  const onSelect = (expandedKeys, value) => {
    setFolderId(value.node.folderId);
    setPage(1);
    //setShowBreadCrumb(true);
    //setBreadCrumbData([...search(folderTreeData, value.node.folderId)]);
    setTreeSelectedkey(expandedKeys);

    if (value.node.key === "Home") setRootFolderId(null);

    else if (!value.node.parentId) setRootFolderId(value.node.folderId);

    if (folderId === value.node.folderId) getData(folderId);
  };

  const handleAddFolder = () => {
    setIsFolderModelOpen(true);
    setFolderEdit(false);
  };

  /*const handleViewFileModalChange = () => {
    setIsOpenViewFile(false);
  }*/

  /*const fileCommentModalOk = () => {
    setIsfileCommentModalVisible(false);
  }*/

  /*const fileCommentModalCancel = () => {
    setIsfileCommentModalVisible(false);
  }*/

  /*NOTE: In the old single download function, the following bit was before the
          actual download code:

    let url = assembler(API_GET_KEY).param({ serviceRequestId: requestId });
    const res = await axios.get(url, { headers: { authorization: userToken } });

    if (res.data.payload.showKeyManagement) {
      setShowKeyManagementConfirmationModal(true);
      return;
    }
  */

  const openDownloadModal = () => setIsDownloadModalOpen(true);

  const resetIdsForDownload = () => {
    setFileIdsForDownload([]);
    setFolderIdsForDownload([]);
  };

  const closeDownloadModal = () => {
    setIsDownloadModalOpen(false);
    resetIdsForDownload();
    clearCheckBoxSelections();
  };

  const getIdsForDownload = () => {
    const fileIds = [], folderIds = [];

    selectedFilesForDownload.forEach((item) => {
      const fileId = item?.file?.id;
      const folderId = item?.folder?.id;

      if (fileId) fileIds.push(fileId);

      if (folderId) folderIds.push(folderId);
    });

    return { fileIds, folderIds };
  };

  const handleDownloads = () => {
    const { fileIds, folderIds } = getIdsForDownload();

    if (fileIds.length > 0) {
      setFileIdsForDownload(fileIds);
      setFolderIdsForDownload(folderIds);
      openDownloadModal();
    }
    else {
      message.error(CONSTANTS.MESSAGES.SELECT_FILE_TO_DOWNLOAD);
    }
  };

  const downloadModal = (
    <FileDownload
      closeDownloadModal={closeDownloadModal}
      isModalOpen={isDownloadModalOpen}
      fileIds={fileIdsForDownload}
      requestId={requestId}
      parentTab={REQUEST_DETAIL_TABS.FILES}
      folderIds={folderIdsForDownload}
    />
  );

  const downloadModalContent = (isDownloadModalOpen) ? downloadModal : null;

  return (
    <>
      <div style={{ paddingBottom:"5px" }}></div>

      {issFolderModelOpen && <Folder
        breadCrumbData={breadCrumbData}
        setSelectedFileId={selectedFileId}
        issFolderModelOpen={issFolderModelOpen}
        selectedFolderIdToEdit={selectedFolderIdToEdit}
        setFileCommentModalVisible={setFileCommentModalVisible}
        setIsFolderModelOpen={setIsFolderModelOpen}
        getFolderHierarchy={getFolderHierarchy}
        handleFolderEdit={handleFolderEdit}
        rootFolderId={rootFolderId}
        folderEdit={folderEdit}
        requestId={requestId}
        folderId={folderId}
        getData={getData}
      />}

      {showKeyManagementConfirmationModal && <KeyConfirmationModel
        selectedServiceRequestName={selectedServiceRequestName}
        serviceRequestId={requestId}
        userType={userType}
        setSelectedServiceRequestName={setSelectedServiceRequestName}
        showKeyManagementConfirmationModal={showKeyManagementConfirmationModal}
        setShowKeyManagementConfirmationModal={setShowKeyManagementConfirmationModal}
      />}

      <Spin spinning={loadingFilesTab} tip="Loading..." size="large">
        <Row>
          {folderTreeData.length > 0 && <Col xs={2} sm={4} md={4} lg={4} xl={4}>
            {/* <div style={{ textAlign: "center", backgroundColor: "#e6f7ff" }}>
              <Link to="#" onClick={() => handleBreadCrumbClick(null, null)}>Home</Link>
            </div> */}

            <DirectoryTree
              defaultExpandAll
              treeData={folderTreeData}
              onSelect={onSelect}
              selectedKeys={treeSelectedKey}
              defaultExpandedKeys={treeSelectedExpandKey}
              expandAction={true}
            />
          </Col>}

          <Col
            xs={folderTreeData.length > 0 ? 22 : 24}
            sm={folderTreeData.length > 0 ? 20 : 24}
            md={folderTreeData.length > 0 ? 20 : 24}
            lg={folderTreeData.length > 0 ? 20 : 24}
            xl={folderTreeData.length > 0 ? 20 : 24}
          >
            {/* {showBreadCrumb && getBreadcrumbs()} */}

            <Table
              rowSelection={rowSelection}
              columns={fileColumns}
              dataSource={fileTableData}
              pagination={false}
              bordered={true}
              scroll={{ x: 800, y: 450 }}
              rowKey="id"
              locale={{ emptyText: <EmptyTable text="No Files" /> }}
            />

            <br />

            {<div align={"right"}>
              <Row>
                <Col span="10" align="left">
                  {!isOnlyManager && <>
                    <Button
                      disabled={selectedRowKeys.length > 0 ? false : true}
                      type="default"
                      icon={<DownloadOutlined style={actionButtonStyle} />}
                      size="small"
                      style={{ fontWeight: "bold" }}
                      onClick={handleDownloads}
                      className="buttonWithImage"
                    >
                      {CONSTANTS.BUTTON_LABELS.DOWNLOAD}
                    </Button>

                    &nbsp;&nbsp;

                    {
                      (!isClosedRequest)
                      ? (
                        <Popconfirm
                          disabled={selectedFilesForDelete.length === 0}
                          title={CONSTANTS.MESSAGES.CONFIRM_DELETION}
                          onConfirm={handleFilesDelete}
                          okText="Yes"
                          cancelText="No"
                          icon={<InfoCircleOutlined style={{ color: 'red' }} />}
                        >
                          <Button
                            disabled={selectedFilesForDelete.length === 0}
                            type="default"
                            icon={<DeleteOutlined style={actionButtonStyle} />}
                            size="small"
                            style={{ fontWeight: "bold" }}
                            className="buttonWithImage"
                          >
                            {CONSTANTS.BUTTON_LABELS.DELETE}
                          </Button>
                        </Popconfirm>
                      )
                      : null
                    }
                  </>}
                </Col>

                {
                  (!isClosedRequest)
                  ? (
                    <Col span="14" align="right">
                      {((folderId ? canUploadFile : showUploadFileatRootLevel)) && <Button
                        disabled={userType === USERTYPE.CUSTOMER ? !isFileUplodaAllow : false}
                        className="buttonWithImage"
                        size="small"
                        ref={refFiles}
                        icon={<UploadOutlined style={actionButtonStyle} />}
                        style={{ fontWeight: "bold" }}
                        onClick={() => { setIsFileUploadModelOpen(true) }}
                      >
                        {CONSTANTS.BUTTON_LABELS.UPLOAD_FILE}
                      </Button>}

                      {(folderId ? canCreateFolder : showAddFolderatRootLevel) && <>
                        &nbsp;&nbsp;
                        <Button
                          icon={<FolderAddOutlined style={actionButtonStyle} />}
                          style={{ fontWeight: "bold" }}
                          className="buttonWithImage"
                          onClick={handleAddFolder}
                          size="small"
                        >
                          {CONSTANTS.BUTTON_LABELS.ADD_FOLDER}
                        </Button>
                      </>}
                    </Col>
                  )
                  : null
                }
              </Row>
            </div>}

            <div align="center">
              <Pagination
                total={total}
                defaultPageSize={10}
                defaultCurrent={page}
                onChange={onPaginate}
              />
            </div>
          </Col>
        </Row>
      </Spin>

      {isCommentsModalVisible && <CommentsModal
        type={"add"}
        isShowFiles={true}
        //taskId={taskId}
        requestId={requestId}
        onChange={CommentsModalSubmit}
        isCommentsModalVisible={isCommentsModalVisible}
        showCustomerCheckbox={isServiceReqExternal ? true : false}
      />}

      {isFileUploadModelOpen && <FileUpload
        isServiceReqExternal={isServiceReqExternal}
        isModalOpen={isFileUploadModelOpen}
        onChange={handleModalChange}
        rootFolderId={rootFolderId}
        parentFolderId={folderId}
        onSubmit={handleOnSubmit}
        requestId={requestId}
        userType={userType}
        //taskId={taskId}
        getData={getData}
        isReload={true}
        email={email}
      />}

      {/*selectedFileId && fileCommentModalVisible && <FileComment
        setFileCommentModalVisible={setFileCommentModalVisible}
        fileCommentModalVisible={fileCommentModalVisible}
        selectedFileId={selectedFileId}
        selectedFileName={selectedFileName}
        isServiceReqExternal={isServiceReqExternal}
      />*/}

      {/*
        (isOpenViewFile)
        ? (
          <ViewFile
            isOpenViewFile={isOpenViewFile}
            onChange={handleViewFileModalChange}
            fileId={fileId}
            fileName={fileName}
            requestId={requestId}
            isOpenViewFileRecord={isOpenViewFileRecord}
            downloadFile={(id, name) => handlePreviewDownload(id, name)}
          />
        )
        : null
      */}

      {/*<Modal
        open={isfileCommentModalVisible}
        onOk={fileCommentModalOk}
        onCancel={fileCommentModalCancel}
      >
        <p>{fileCommentMessage}</p>
      </Modal>*/}

      {downloadModalContent}
    </>
  );
};

export default FolderList;

import config from "../config";

export const API_BASE = config.HOST + "/api/v1";

export const API_GET_FILE = API_BASE + "/files/:id";
export const API_GET_FILE_PARENT_DETAILS = API_BASE + "/file/fileDirectoryDetails/:id";
export const API_GET_FILE_HIERARCHY = API_BASE + "/files/:id/hierarchy/:type";
export const API_GET_REQUEST = API_BASE + "/requests";
export const API_GET_SERVICE_REQUEST = API_BASE + "/service-request";
export const API_DELETE_FILE = API_BASE + "/deleteFile";
export const API_DELETE_REQUEST = API_BASE + "/deleteReq";
export const API_CLOSE_REQUEST = API_BASE + "/closeRequest";
export const API_REOPEN_REQUEST = API_BASE + "/reopenRequest";
export const API_GET_APP_ACCESS = API_BASE + "/check-app-access";
export const API_CREATE_FOLDER = API_BASE + "/folders";
export const API_FOLDER = API_BASE + "/folders/:requestId";
export const API_FILES_UPDATE = API_BASE + "/file/updateFiles";
export const API_GET_FILES_TO_APPROVE = API_BASE + "/getFilesToApprove/:id";
export const API_GET_KEY = API_BASE + "/key/:serviceRequestId";
export const API_GET_KEY_CONFIG = API_BASE + "/key/:serviceRequestId/config";
export const API_SAVE_KEY_CONFIG = API_BASE + "/key/:serviceRequestId/create";
export const API_UPDATE_KEY_CONFIG = API_BASE + "/key/:serviceRequestId/update";
export const API_GET_SERVICE_REQ_KEY_CONFIG = API_BASE + "/key/:serviceRequestId/get";

//export const API_GET_FOLDER = API_BASE + "/folders";
//export const API_PUT_REQUEST = API_BASE + "/request";
//export const API_FILE_PREVIEW = API_BASE + "/file/previewFile";
//export const API_GET_TASK_DETAIL = API_BASE + "/tasks/:taskId";

export const API_ROUTES = Object.freeze({
  LOGIN: `${API_BASE}/users/login`,
  REGISTER: `${API_BASE}/users/register`,
  RESET_PASSWORD: `${API_BASE}/users/change-pwd`,
  FORGOT_PASSWORD: `${API_BASE}/users/forgot-pwd`,

  POST_REQUEST: `${API_BASE}/service-requests`,
  GET_REQUEST_DETAILS_BY_ID: `${API_BASE}/requestById`,

  SOCKETIO_ENDPOINT: config.HOST,
  UPLOAD_FILES: `${API_BASE}/files/upload`,
  UPLOAD_FILES_BY_NEW_METHOD: `${API_BASE}/files/newUploadMethod`,

  GET_FILE_HIERARCHY: `${API_BASE}/files/:id/hierarchy/:type`,

  GET_KEY: `${API_BASE}/key/:serviceRequestId`,

  GET_FILE_DATA_TYPES: `${API_BASE}/fileDataTypes`,
  SAVE_FILE_DATA_TYPES: `${API_BASE}/fileDataTypes`,
  DELETE_FILE_DATA_TYPE: `${API_BASE}/fileDataType`,

  FILE_DOWNLOAD: `${API_BASE}/file/download`,
  CHECK_DOWNLOAD_ACCESS: `${API_BASE}/folders/:serviceRequestId/check-download-access`,

  TRIGGER_EMAIL: `${API_BASE}/files/triggerEmailNotification`,
  TRIGGER_POST_DOWNLOAD_PROCESS: `${API_BASE}/files/triggerPostDownloadProcess`,

  SAVE_COMMENT: `${API_BASE}/requestComments`,
  UPDATE_COMMENT: `${API_BASE}/file/updateComment`,
  DELETE_COMMENT: `${API_BASE}/file/deleteComment`,
  SAVE_FILE_COMMENT: `${API_BASE}/file/comment/:fileId`,
  GET_FILE_COMMENTS: `${API_BASE}/file/comment/:fileId`,
  GET_FILE_AND_REQUEST_COMMENTS: `${API_BASE}/getFileAndRequestComments`,

  GET_TASKS: `${API_BASE}/tasks`,

  GET_SETTINGS: `${API_BASE}/users/getSettings`,
  SAVE_SETTINGS: `${API_BASE}/users/saveSettings`,
  DEACTIVATE_AN_EXTERNAL_USER: `${API_BASE}/users/deactivate`,

  VERIFY_EMAIL_HASH: `${API_BASE}/users/verify-hash`,
  TRIGGER_VERIFICATION_EMAIL: `${API_BASE}/users/trigger-verification-email`,

  TEST_CONNECTION: `${API_BASE}/testConnection`,
  TEST_LDAP_USERS: `${API_BASE}/findADUsersAndGroups/testLdapUsers`,

  GET_ROLES: `${API_BASE}/roles`,
  GET_USER: `${API_BASE}/users/getUser`,
  GET_AD_USERS: `${API_BASE}/findUsersFromAD`,
  GET_AD_USERS_AND_GROUPS: `${API_BASE}/findADUsersAndGroups`,

  GET_AUDIT_LOGS: `${API_BASE}/logs`
});

export const UI_ROUTES = Object.freeze({
  BASE: '/',
  LOGIN: '/login',
  SETTINGS: '/settings',
  RESET_PWD: '/resetPwd',
  FORGOT_PWD: '/forgotPwd',
  REGISTER: '/registration',
  NOT_ALLOWED: '/not-allowed',
  REQUEST_DETAIL_PATH: '/requestDetail'
});

import ACTION_TYPES from "../actionTypes/userActionType";

export const requestSelected = (
    requestId, taskId, selectedTab, roleId, page, limit
) => async (dispatch) => {
    const data = { requestId, taskId, selectedTab, roleId, page, limit };

    sessionStorage.setItem('request', JSON.stringify(data));

    dispatch({ type: ACTION_TYPES.REQUEST_SELECTED, request: data });
};

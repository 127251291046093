const ACTION_TYPES = Object.freeze({
  USER_FORGOT_PASSWORD_REQUEST: 'USER_FORGOT_PASSWORD_REQUEST',
  USER_FORGOT_PASSWORD_SUCCESS: 'USER_FORGOT_PASSWORD_SUCCESS',
  USER_FORGOT_PASSWORD_FAIL: 'USER_FORGOT_PASSWORD_FAIL',

  USER_RESET_PASSWORD_REQUEST: 'USER_RESET_PASSWORD_REQUEST',
  USER_RESET_PASSWORD_SUCCESS: 'USER_RESET_PASSWORD_SUCCESS',
  USER_RESET_PASSWORD_FAIL: 'USER_RESET_PASSWORD_FAIL',

  USER_REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
  USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
  USER_REGISTER_FAIL: 'USER_REGISTER_FAIL',

  USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAIL: 'USER_LOGIN_FAIL',

  REQUEST_SELECTED: 'REQUEST_SELECTED',

  USER_LOGOUT: 'USER_LOGOUT',

  VERIFY_EMAIL: 'VERIFY_EMAIL'
});

export default ACTION_TYPES;

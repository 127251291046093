
import React, { useState, useEffect } from 'react';
import { Modal, Space } from 'antd';
import axios from "../../utils/axios";
import assembler from "url-assembler";
import lodashRound from 'lodash/round';
import { useSelector } from "react-redux";
import { API_ROUTES } from '../../utils/webConstants';
import { default as axiosWithoutInterceptor } from 'axios';

import {
  FILE_DOWNLOAD_COMPONENT, EMAIL_NOTIFICATION_TYPES, AXIOS_CONFIG_VALUES,
  REQUEST_DETAIL_TABS
} from '../../utils/appConstants';

import {
  ModalTitle, DownloadProgress, ModalFooter, LoadingAnimation, DeniedAccess,
  NetworkError
} from './FileDownload_SubComponents';

const {
  STATUS_TYPES, AXIOS_ERROR_CODES, MAX_FILE_SIZE, PROGRESS_PRECISION, FILE_INFO
} = FILE_DOWNLOAD_COMPONENT;

const {
  DOWNLOAD_ACCESS_PENDING, DOWNLOAD_ACCESS_ALLOWED, DOWNLOAD_ACCESS_DENIED,
  DOWNLOAD_SUCCESS, NETWORK_ERROR
} = STATUS_TYPES;

const {
  AUTHORIZATION, POST_METHOD, GET_METHOD, BLOB_RESPONSE
} = AXIOS_CONFIG_VALUES;

export default function FileDownload(props) {
  const {
    closeDownloadModal, isModalOpen, fileIds, requestId, parentTab, folderIds
  } = props;

  const { userInfo } = useSelector((state) => state.userLogin);
  const userToken = userInfo?.userToken ?? '';
  const configAuthHeader = { [AUTHORIZATION]: userToken };

  const [filesInfo, setFilesInfo] = useState([]);
  const [statusType, setStatusType] = useState(DOWNLOAD_ACCESS_PENDING);
  const [completedDownloadsCount, setCompletedDownloadsCount] = useState(0);
  const [isDownloadCancelled, setIsDownloadCancelled] = useState(false);

  const [checkAccessAbortController] = useState(new AbortController());
  const [downloadAbortController] = useState(new AbortController());

  const isStatusAccessPending = (statusType === DOWNLOAD_ACCESS_PENDING);
  const isStatusAccessAllowed = (statusType === DOWNLOAD_ACCESS_ALLOWED);
  const isStatusAccessDenied = (statusType === DOWNLOAD_ACCESS_DENIED);
  const isStatusDownloadSuccess = (statusType === DOWNLOAD_SUCCESS);
  const isStatusNetworkError = (statusType === NETWORK_ERROR);

  const cancelAxiosRequest = (abortController) => abortController.abort();

  useEffect(() => {
    checkAccess();
  }, []);

  useEffect(() => {
    finalStatusUpdate(completedDownloadsCount);
  }, [completedDownloadsCount]);

  function checkAccess() {
    axios(getConfigForCheckAccess())
      .then((res) => handleCheckAccessResponse(res))
      .catch((error) => handleCheckAccessError(error));
  }

  function handleDownloads(data) {
    data.forEach(({ id, name }) => {
      const fileInfo = Object.seal(new FILE_INFO(id, name));
      setFilesInfo(previousState => [...previousState, fileInfo]);
      downloadFile(fileInfo);
    });

    setStatusType(DOWNLOAD_ACCESS_ALLOWED);
  }

  function downloadFile({ id, name }) {
    axiosWithoutInterceptor(getConfigForDownload(id))
      .then(({ data }) => handleDownloadResponse(data, name))
      .catch((error) => handleDownloadError(error));
  }

  function finalStatusUpdate(completedCount) {
    const allDownloadsAreComplete = (
      (completedCount > 0) && (completedCount === fileIds.length)
    );

    if (allDownloadsAreComplete) {
      setStatusType(DOWNLOAD_SUCCESS);
      handleOptionalExtras();
    }
  }

  function handleOptionalExtras() {
    const { TASKS } = REQUEST_DETAIL_TABS;

    if (parentTab !== TASKS) triggerDownloadEmail();

    triggerPostDownloadProcess();
  }

  function triggerDownloadEmail() {
    axios(getConfigToTriggerDownloadEmail())
      .catch((error) => console.error(error));
  }

  function triggerPostDownloadProcess() {
    axios(getConfigToTriggerPostDownloadProcess())
      .catch((error) => console.error(error));
  }

  function handleCheckAccessResponse(res) {
    const statusCode = res?.status;
    const responseDataStatus = res?.data?.status;
    const responseDataPayload = res?.data?.payload;

    const isAccessAllowed = (
      (statusCode === 200) &&
      (responseDataStatus === 'success') &&
      (responseDataPayload.length > 0)
    );

    if (isAccessAllowed) handleDownloads(responseDataPayload);
    else console.error('In handleCheckAccessResponse(): ', res);
  }

  function handleDownloadResponse(data, name) {
    if (isDownloadCancelled) return;

    saveFileToClient(data, name);
    setCompletedDownloadsCount(previousState => previousState + 1);
  }

  function saveFileToClient(data, name) {
    const anchor = document.createElement('a');
    anchor.href = window.URL.createObjectURL(new Blob([data]));
    anchor.setAttribute('download', name);
    anchor.click();
    anchor.remove();
  }

  function handleCheckAccessError(error) {
    if (error.code === AXIOS_ERROR_CODES.NETWORK) {
      setStatusType(NETWORK_ERROR);
    }
    else if (error.code !== AXIOS_ERROR_CODES.CANCELLED) {
      setStatusType(DOWNLOAD_ACCESS_DENIED);
      console.error(error);
    }
  }

  function handleDownloadError(error) {
    if (error.code === AXIOS_ERROR_CODES.NETWORK) {
      setStatusType(NETWORK_ERROR);
    }
    else if (error.code !== AXIOS_ERROR_CODES.CANCELLED) {
      console.error(error);
    }
  }

  function getConfigForCheckAccess() {
    return {
      method: POST_METHOD,
      url: getUrlForCheckAccess(),
      headers: configAuthHeader,
      signal: checkAccessAbortController.signal,
      data: {
        fileIds: fileIds,
        folderIds: folderIds ? folderIds : []
      }
    };
  }

  function getConfigForDownload(id) {
    return {
      method: GET_METHOD,
      url: getUrlForDownload(id),
      responseType: BLOB_RESPONSE,
      headers: configAuthHeader,
      signal: downloadAbortController.signal,
      maxContentLength: MAX_FILE_SIZE,
      onDownloadProgress: (progressEvent) => updateProgress(progressEvent, id)
    };
  }

  function getConfigToTriggerDownloadEmail() {
    return {
      method: POST_METHOD,
      url: API_ROUTES.TRIGGER_EMAIL,
      headers: configAuthHeader,
      data: {
        requestId: window.btoa(requestId),
        emailType: window.btoa(EMAIL_NOTIFICATION_TYPES.FILE_DOWNLOADS),
        count: window.btoa(completedDownloadsCount)
      }
    };
  }

  function getConfigToTriggerPostDownloadProcess() {
    return {
      method: POST_METHOD,
      url: API_ROUTES.TRIGGER_POST_DOWNLOAD_PROCESS,
      headers: configAuthHeader,
      data: { requestId, fileData: getIdsAndNamesOfDownloadedFiles() }
    };
  }

  function getUrlForCheckAccess() {
    return assembler(API_ROUTES.CHECK_DOWNLOAD_ACCESS).param({
      serviceRequestId: requestId
    });
  }

  function getUrlForDownload(id) {
    const fileIdPart = `?fileId=${window.btoa(id)}`;
    const requestIdPart = `&requestId=${window.btoa(requestId)}`;

    return `${API_ROUTES.FILE_DOWNLOAD}${fileIdPart}${requestIdPart}`;
  }

  function updateProgress({ loaded, total }, id) {
    if (isDownloadCancelled) return;

    const percent = loaded / total * 100;
    const roundedPercent = lodashRound(percent, PROGRESS_PRECISION);

    setFilesInfo(previousState => previousState.map((item) => {
      return (item.id === id)
        ? { ...item, progress: roundedPercent }
        : item;
    }));
  }

  function getIdsAndNamesOfDownloadedFiles() {
    return filesInfo.reduce((accumulator, item) => {
      const { id, name, progress } = item;

      if (progress === 100) accumulator.push({ id, name });

      return accumulator;
    }, []);
  }

  function handleCancel() {
    setIsDownloadCancelled(true);

    if (isStatusAccessPending) {
      cancelAxiosRequest(checkAccessAbortController);
    }
    else if (isStatusAccessAllowed) {
      cancelAxiosRequest(downloadAbortController);

      if (completedDownloadsCount > 0) handleOptionalExtras();
    }

    closeDownloadModal();
  }

  const modalTitle = <ModalTitle status={statusType} />;

  const cancelButton = <ModalFooter
    status={statusType} handleCancel={handleCancel}
  />;

  const loadingContent = (isStatusAccessPending)
    ? <LoadingAnimation fileIds={fileIds} />
    : null;

  const deniedContent = (isStatusAccessDenied)
    ? <DeniedAccess fileCount={filesInfo.length} />
    : null;

  const downloadContent = (isStatusAccessAllowed || isStatusDownloadSuccess)
    ? <DownloadProgress info={filesInfo} />
    : null;

  const networkErrorContent = (isStatusNetworkError) ? <NetworkError /> : null;

  return (
    <Modal
      open={isModalOpen} title={modalTitle} onCancel={handleCancel} centered
      footer={cancelButton} maskClosable={false} keyboard={false}
      bodyStyle={{ overflowY: 'auto', maxHeight: '75vh' }}
    >
      <Space direction='vertical' style={{ width: '100%' }}>
        {loadingContent}
        {deniedContent}
        {downloadContent}
        {networkErrorContent}
      </Space>
    </Modal>
  );
}

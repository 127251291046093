import { useEffect, useState } from "react";
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import { UI_ROUTES } from '../../utils/webConstants';
import { useSelector, useDispatch } from "react-redux";
import { register } from "../../redux/actions/userActions";
import PasswordStrengthBar from 'react-password-strength-bar';
import { checkEmailCanRegister } from "../../utils/authUtils";
import { REGISTRATION_COMPONENT } from "../../utils/appConstants";

import {
  Form, Input, Button, Spin, message, Alert, Modal, Row, Col, Layout
} from "antd";

const {
  PAGE_TITLE, PASSWORD_MINIMUM_LENGTH, PASSWORD_STREGNTH, PAGE_DESCRIPTION,
  LABELS, REGISTRATION_SUCCESS, MESSAGES

} = REGISTRATION_COMPONENT;

const columnSizes = { xs: { span: 12 }, sm: { span: 8 } };
const layout = { labelCol: columnSizes, wrapperCol: columnSizes };

const Registration = ({ location, history }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const userRegister = useSelector((state) => state.userRegister);
  const { userInfo, loading, error, spinningText } = userRegister;

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const goToLogin = () => history.push(UI_ROUTES.LOGIN);

  const blankColumn = <Col span="8"></Col>;

  useEffect(() => {
    if (userInfo?.payload) {
      Modal.info({
        content: (
          <div>
            <p>{REGISTRATION_SUCCESS.LINE_1}</p>
            <p>{REGISTRATION_SUCCESS.LINE_2}</p>
          </div>
        ),
        width: "40%",
        onOk() {
          goToLogin();
        },
      });
    }
  }, [userInfo]);

  const onFinish = (formData) => {
    const { firstName, lastName, email, password, confirmPassword } = formData;

    if (/\s/.test(password)) {
      message.error(MESSAGES.ENTER_VALID_PASSWORD);
    }
    else if (!passwordValidation(password)) {
      message.error(MESSAGES.NEED_PASSWORD_ATTRIBUTES);
    }
    else {
      if (password !== confirmPassword) {
        message.error(MESSAGES.PASSWORD_MISMATCH);
      }
      else if (!checkEmailCanRegister(email)) {
        form.setFields([{
          name: "email", errors: [MESSAGES.CANNOT_REGISTER_EMAIL]
        }]);
      }
      else {
        setPasswordError("");
        dispatch(
          register(firstName, lastName, email, password, confirmPassword)
        );
      }
    }
  };

  const passwordValidation = (password) => {
    let count = 0;

    if (!/\d/.test(password)) count++;

    if (!/[a-z]/.test(password)) count++;

    if (!/[A-Z]/.test(password)) count++;

    if (! /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g.test(password)) count++;

    return count <= 1;
  };

  const onFinishFailed = (errorInfo) => console.error(errorInfo);

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");

    if (confirmPassword === e.target.value) setConfirmPasswordError("");
  };

  const onConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError("");
  };

  const passwordOnfocusout = (e) => {
    if (e.target.value !== "") {
      if (/\s/.test(e.target.value)) {
        setPasswordError(MESSAGES.ENTER_VALID_PASSWORD);
      }
      else if (!passwordValidation(e.target.value)) {
        setPasswordError(MESSAGES.NEED_PASSWORD_ATTRIBUTES);
      }
      else {
        setPasswordError("");
      }

      if (confirmPassword === e.target.value) setConfirmPasswordError("");
    }
    else {
      setPasswordError("");
    }
  };

  const confirmPasswordOnfocusout = (e) => {
    if (e.target.value !== "") {
      if (password !== e.target.value) {
        setConfirmPasswordError(MESSAGES.PASSWORD_MISMATCH);
      }
    }
    else {
      setConfirmPasswordError("");
    }
  };

  const errorAlert = <Alert
    message="Error" description={error} type="error" showIcon closable
  />;

  const errorAlertContent = (error) ? errorAlert : null;

  const submitAndCancelbuttons = (
    <Row>
      {blankColumn}
      <Col span="8" align="right">
        <Button type="primary" htmlType="submit">{LABELS.SUBMIT}</Button>
        &nbsp;&nbsp;
        <Button onClick={goToLogin}>{LABELS.CANCEL}</Button>
      </Col>
    </Row>
  );

  return (
    <Layout >
      <AppHeader />
      <Layout className="layoutStyle">
        <Spin spinning={loading} tip={spinningText} size="large">
          <div style={{ paddingTop: "35px" }}>
            {errorAlertContent}

            <Form
              {...layout} name="basic" initialValues={{ remember: true }}
              onFinish={onFinish} form={form} onFinishFailed={onFinishFailed}
            >
              <Row>
                {blankColumn}
                <Col span="8">
                  <h2 className="text-center login-logo">{PAGE_TITLE}</h2>
                </Col>
              </Row>

              <Row>
                {blankColumn}
                <Col span="8">
                  <p style={{ textAlign: "justify" }}>{PAGE_DESCRIPTION.LINE_1}</p>
                </Col>
              </Row>

              <Row>
                {blankColumn}
                <Col span="8">
                  <p style={{ textAlign: "justify" }}>{PAGE_DESCRIPTION.LINE_2}</p>
                </Col>
              </Row>

              <Form.Item
                label={LABELS.FIRST_NAME} name="firstName"
                rules={[{ required: true, message: MESSAGES.ENTER_FIRST_NAME }]}
              >
                <Input autoComplete="off" />
              </Form.Item>

              <Form.Item
                label={LABELS.LAST_NAME} name="lastName"
                rules={[{ required: true, message: MESSAGES.ENTER_LAST_NAME }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={LABELS.EMAIL_ADDRESS} name="email"
                rules={[{ required: true, type: "email" }]}
              >
                <Input autoComplete="off" />
              </Form.Item>

              <Form.Item
                label={LABELS.PASSWORD} name="password"
                style={{ marginBottom: "3px" }}
                rules={[
                  { required: true, message: MESSAGES.ENTER_NEW_PASSWORD },
                  {
                    min: PASSWORD_MINIMUM_LENGTH,
                    message: MESSAGES.PASSWORD_TOO_SHORT
                  }
                ]}
              >
                <Input.Password
                  onChange={onPasswordChange} onBlur={passwordOnfocusout}
                />
              </Form.Item>

              {passwordError ? <Row>
                {blankColumn}
                <Col span="8">
                  <div className="errorMessage" style={{ fontWeight: "normal" }}>
                    {passwordError}
                  </div>
                </Col>
              </Row> : null}

              <Row>
                {blankColumn}
                <Col span="8">
                  <p>{MESSAGES.PASSWORD_REQUIREMENTS}</p>
                </Col>
              </Row>

              {password.length > 0 && <Row>
                {blankColumn}
                <Col span="8">
                  <PasswordStrengthBar
                    password={password} scoreWords={PASSWORD_STREGNTH}
                  />
                </Col>
              </Row>}

              <Form.Item
                label={LABELS.CONFIRM_PASSWORD} name="confirmPassword"
                style={{ marginBottom: "3px" }}
                rules={[{
                  required: true, message: MESSAGES.CONFIRM_PASSWORD
                }]}
              >
                <Input.Password
                  onChange={onConfirmPasswordChange}
                  onBlur={confirmPasswordOnfocusout}
                />
              </Form.Item>

              {confirmPasswordError ? <Row>
                {blankColumn}
                <Col span="8">
                  <div className="errorMessage" style={{ fontWeight: "normal" }}>
                    {confirmPasswordError}
                  </div>
                </Col>
              </Row> : null}

              {submitAndCancelbuttons}
            </Form>
          </div>
        </Spin>
        <AppFooter />
      </Layout>
    </Layout>
  );
};

export default Registration;

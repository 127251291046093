import React from 'react';
import 'antd/dist/antd.min.css';
import '../../index.css';
import { Input } from 'antd';

const NumericInput = (props) => {
    const onChange = e => {
        const { value } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;

        if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-')
            props.onChange(value);
        else
            props.onChange(value.substring(0, value.length - 1));
    };

    // '.' at the end or only '-' in the input box.
    const onBlur = () => {
        const { value, onBlur, onChange } = props;
        let valueTemp = value.toString();

        if (valueTemp.charAt(valueTemp.length - 1) === '.' || valueTemp === '-') {
            valueTemp = value.toString().slice(0, -1);
        }

        onChange(valueTemp.replace(/0*(\d+)/, '$1'));

        if (onBlur) onBlur();
    };

    return <Input {...props} onChange={onChange} onBlur={onBlur} maxLength={25} />;
};

export default NumericInput;

import { AUDIT_SETTINGS } from '../../../utils/appConstants';
import { useEffect, useState } from 'react';
import lodashOrderBy from 'lodash/orderBy';
import lodashUnion from 'lodash/union';
import { Modal, Select } from 'antd';

const { MODAL_TITLE, SELECT_PLACEHOLDER, SORT_TYPES } = AUDIT_SETTINGS;
const { DISPLAY_ORDER, ASCENDING } = SORT_TYPES;
const { Option } = Select;
let userSelAttribs = [];

const Settings = (props) => {
  const [userSelectedCols, setUserSelectedCols] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const { visible } = props;

  const handleModalOk = (e) => {
    userSelAttribs = [...userSelectedCols];
    props.handleModalOk(e, selectedOptions);
  };

  const handleModalCancel = (e) => {
    userSelAttribs = lodashUnion(userSelAttribs);
    props.handleModalCancel();
  };

  useEffect(() => {
    if (visible) getUserSettings();
  }, [visible]);

  const getUserSettings = async () => {
    try {
      const sortedArr = lodashOrderBy(
        props.availableFields, [DISPLAY_ORDER], [ASCENDING]
      );

      const options = sortedArr.map((item) => {
        return (
          <Option key={item.col} value={item.col}>{item.displayName}</Option>
        );
      });

      setOptions(options);
    }
    catch (error) {
      console.error(error);
    }
  };

  const handleChange = (value, selectedValues) => {
    setUserSelectedCols(value);
    setSelectedOptions(selectedValues);
  };

  return (
    <Modal
      title={MODAL_TITLE} open={visible}
      onOk={handleModalOk} onCancel={handleModalCancel}
    >
      <Select
        mode="multiple" allowClear style={{ width: "100%" }}
        placeholder={SELECT_PLACEHOLDER}
        onChange={handleChange} value={userSelectedCols}
      >
        {options}
      </Select>
    </Modal>
  );
};

export default Settings;

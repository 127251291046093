import EXTERNAL_USER_GUIDE from "../Risk_Secure_File_Transfer_Tool_User_Guide_External_v1.3.pdf";
import LNRS_LOGO_ICON_ONLY from '../logo_icon_only.png';
import LNRS_LOGO from '../logo.png';

const APP_LOCATION = process.env.REACT_APP_LOCATION;
const APP_NAME = `Secure File Exchange (${APP_LOCATION})`;

const APP_OWNER = 'LexisNexis Risk Solutions';

export const STAGE = { DEV: "development", PROD: "production" };

export const BLOCKED_DOMAINS = [
  "lexisnexisrisk.com", "icis.com", "proagrica.com", "accuity.com",
  "eg.co.uk", "nextens.nl", "cirium.com", "vitalchek.com", "brightmine.com",
  "xperthr.nl", "xperthr.com", "xperthr.co.uk", "lnssi.com", "relx.com",
  "reedelsevier.com"
];

export const BLOCKED_DOMAINS_TO_REGISTER = [
  "lexisnexisrisk.com", "icis.com", "proagrica.com", "accuity.com",
  "eg.co.uk", "nextens.nl", "cirium.com", "vitalchek.com", "brightmine.com",
  "xperthr.nl", "xperthr.com", "xperthr.co.uk", "lnssi.com", "relx.com",
  "reedelsevier.com", "risk.regn.net", "b2b.regn.net", "legal.regn.net",
  "science.regn.net"
];

export const INTERNAL_USER_DOMAIN = "regn.net";

export const UI_URL = process.env.REACT_APP_REDIRECT_URI;

export const API_URL = process.env.REACT_APP_API_HOST;

const SEVEN_FIFTY = 750;
const EIGHT = 8;

export const SERVICE_REQUEST_STATUS = Object.freeze({
  INITIATOR_NEW_REQUEST_CONTRIBUTOR: "Initiator - New Request Contributor",
  INITIATOR_NEW_REQUEST_FULLFILLER: "Initiator - New Request Fulfiller",
  CONTRIBUTOR_SEND_BACK: "Contributor - Send Back",
  CONTRIBUTOR_UPLOAD_FILE: "Contributor - Upload File",
  FULLFILLER_REQUEST_INFO: "Fulfiller - Request Info",
  FULLFILLER_FILE_SHARE: "Fulfiller - File Share",
  REVIEWER_APPROVE: "Reviewer - Approve",
  REVIEWER_REJECT: "Reviewer - Reject",
  REVIEWER_REQUEST_INFO: "Reviewer - Request Info",
  INITIATOR_CLOSE_REQUEST: "Initiator - Close Request",
  INTERNAL_FILE_SHARE: "Internal - File Share",
  INITIATOR_NEW_REQUEST_WITH_FILE_UPLOAD: "Initiator - New Request Reviewer",
  INCOMPLETE_REVIEW: "Incomplete Review"
});

export const FILE_UPLOAD_OBJECT = {
  FILE_DATATYPES: "fileDataTypes",
  OTHERS: "others",
  ISTESTDATA: "isTestData",
  SERVICE_REQUEST_ID: "serviceRequestId",
  TASKID: "taskId",
  FILE_DESCRIPTION: "fileDescription",
  EMPLOYEES: "employees",
  EMAIL: "email",
  INTERNAL_PROJECT: "internalProject",
  TESTDATAONLY: "testDataOnly",
  SERVICEREQJSON: "serviceReqJson",
  FILES: "files",
}

export const FILE_UPLOAD_MESSAGES = Object.freeze({
  SELECT_DATA_TYPES: "Please select Data types included in the file",
  ENTER_OTHER_DETAILS: "Please enter other details",
  UPLOAD_FILE: "Please Upload File",
  UPLOAD_SUCCESS_MESSAGE: "File(s) uploaded successfully.",
  UPLOAD_LIMIT_EXCEEDED: "Upload Limit Exceeded!",
  NO_EMPTY_FILES: "Empty file(s) not allowed!"
});

export const FILE_UPLOAD_COMPONENT = {
  FILE_TYPE_APPLICATION_MSDOWNLOAD: "application/x-msdownload"
}

export const AUDIT_SETTINGS = Object.freeze({
  MODAL_TITLE: 'Select fields to display',
  SELECT_PLACEHOLDER: 'Please select',

  SORT_TYPES: Object.freeze({
    DISPLAY_ORDER: 'displayOrder',
    ASCENDING: 'asc'
  })
});

export const FILE_DATATYPE_COMPONENT = {
  FILE_DATATYPE_CANNOT_BE_EMPTY: "File Data Type cannot be empty",
  DESCRIPTION_MESSAGE: "No File Data Types",
  ADD_SUCCESS_MESSAGE: 'File Data Type added successfully',
  DELETE_SUCCESS_MESSAGE: 'File Data Type deleted successfully',
  DELETE_CONFIRM_MESSAGE: "Are you sure you wish to delete this Data Type?",
  ADD_FILE_DATA_TYPE: 'Add File Data Type'
};

export const INACTIVATE_REQUEST_COMPONENT = {
  NOT_DELETED: "!Deleted",
  DELETE_SUCCESS_MESSAGE: 'Request deleted successfully',
  INACTIVATE_CONFIRM_MESSAGE: "Are you sure you wish to Inactivate this Service Request?",
  NO_REQUESTS: 'No Requests'
};

export const FORGOT_PASSWORD_COMPONENT = {
  MODAL_MESSAGE: "A temporary password was sent to your registered email address. Please log in with the your temporary password",
  EMAIL_ERROR_MESSAGE: "Please provide a valid email address",
}

export const LOGIN_PAGE_COMPONENT = Object.freeze({
  HOW_TO_LOGIN: 'User Guide: How do I login?',
  RESET_PASSWORD: "Please reset your password with 'Forgot Password?'",
  PROVIDE_VALID_EMAIL: 'Please provide a valid email address',
  ENTER_PASSWORD: 'Please enter your password',
  EMAIL_ADDRESS: 'Email Address',
  FORGOT_PASSWORD: 'Forgot Password?',
  FINAL_WARNING: 'Final Warning',
  PASSWORD: 'Password',
  CONTINUE: 'Continue',
  REGISTER: 'Register',
  LOGIN: 'Login'
});

export const REGISTRATION_COMPONENT = Object.freeze({
  PAGE_TITLE: 'Registration',
  PASSWORD_MINIMUM_LENGTH: EIGHT,
  PASSWORD_STREGNTH: ['weak', 'good', 'strong', 'stronger'],

  PAGE_DESCRIPTION: Object.freeze({
    LINE_1: `Welcome to the ${APP_NAME} tool, which will allow the secure transfer of files between yourself and ${APP_OWNER}.`,
    LINE_2: "All fields are required. Upon clicking Submit, you will receive a one-time validation email that must be actioned in order to access the tool."
  }),

  LABELS: Object.freeze({
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    EMAIL_ADDRESS: 'Email Address',
    PASSWORD: 'Password',
    CONFIRM_PASSWORD: 'Confirm Password',
    SUBMIT: 'Submit',
    CANCEL: 'Cancel'
  }),

  REGISTRATION_SUCCESS: Object.freeze({
    LINE_1: 'You have successfully registered & will now receive an email with a link to verify your email address. ',
    LINE_2: 'After you click the link to verify you will be able to login. '
  }),

  MESSAGES: Object.freeze({
    PASSWORD_REQUIREMENTS: `Must contain ${EIGHT} or more characters without a space, using 3 of the following attributes: Uppercase, Lowercase, Numbers or Special Characters`,
    NEED_PASSWORD_ATTRIBUTES: "Password must contain three of the four attributes: Uppercase, Lowercase, Numbers or Special Characters",
    CANNOT_REGISTER_EMAIL: "Cannot register user with the above email address. Please use a different email address!",
    PASSWORD_TOO_SHORT: `Password must be a minimum of ${EIGHT} characters`,
    PASSWORD_MISMATCH: "Passwords do not match, please verify",
    ENTER_VALID_PASSWORD: "Please enter a valid password",
    ENTER_NEW_PASSWORD: "Please enter your new password",
    CONFIRM_PASSWORD: "Please confirm your password",
    ENTER_FIRST_NAME: "Please enter your First Name",
    ENTER_LAST_NAME: "Please enter your Last Name",
  })
});

export const RESET_PASSWORD_COMPONENT = Object.freeze({
  PASSWORD_UPDATED: 'Your password is updated successfully. Please use the updated password for login',
  ENTER_VALID_PASSWORD: 'Please enter a valid Password',
  PASSWORDS_DONOT_MATCH: 'Passwords do not match, Please verify',
  FORM_INFO: 'This form allows you to change your password',
  ENTER_NEW_PASSWORD: 'Please enter your new password',
  CONFIRM_NEW_PASSWORD: 'Please confirm your new password',
  PASSWORD_FORMAT: 'Password must contain 8 or more characters without a space, using 3 of the 4 attributes: Uppercase, Lowercase, Numbers or Special Characters'
});

export const COMMENTS_MODAL_COMPONENT = Object.freeze({
  SEND_BACK_PROP_TYPE: "sendBack",
  ADD_IFO_PROP_TYPE: "addInfo",
  APPROVE_PROP_TYPE: "approve",
  REJECT_PROP_TYPE: "reject",
  APPROVE_DEACTIVATE_USER_REQUEST_PROP_TYPE: "approveDeactivateUserRequest",
  REJECT_DEACTIVATE_USER_REQUEST_PROP_TYPE: "rejectDeactivateUserRequest",
  FILE_RETENTION_REJECT_PROP_TYPE: "fileRetentionReject",

  COMMENT_MAX_LENGTH: SEVEN_FIFTY,
  COMMENT_CANNOT_BE_EMPTY: "Comment cannot be empty",
  COMMENT_MAX_LENGTH_EXCEEDED: `Comment cannot exceed ${SEVEN_FIFTY} characters`,
  NO_SPECIAL_CHARACTERS_IN_COMMENT: 'Comment cannot have special characters',

  APPROVE_DEACTIVATE_USER_REQUEST_LABEL: 'Are you sure you wish to close this project and deactivate the external user? Please enter the approve comments',
  SELECT_FILE_FOR_COMMENT: 'Please select file for adding file comment (Optional)',
  CONFIRM_PUBLISH_COMMENT_TO_CUSTOMER: 'Only click OK if the comment should be published to the Customer.',
  PUBLISH_TO_CUSTOMER: 'Publish to External Recipient',
  ATTENTION: 'Attention',
  COMMENTS: 'Comments',

  TITLES: Object.freeze({
    ADD_COMMENT: 'Add Comment',
    SEND_BACK_TO_INITIATOR: 'Send back to Initiator',
    REQUEST_MORE_INFO: 'Request additional information',
    APPROVE_FILE: 'Approve File',
    REJECT_FILE: 'Reject File',
    REJECT_FILE_RETENTION_PERIOD: 'Reject File Retention Period',
    APPROVE_USER_DEACTIVATION: 'Approve User Deactivation',
    REJECT_USER_DEACTIVATION: 'Reject User Deactivation'
  }),

  SUCCESS_MESSAGES: Object.freeze({
    SENT_BACK: 'Sent back succesfully',
    MORE_INFO_REQUESTED: 'Additional information requested successfully',
    APPROVED_FILE: 'Approved File successfully',
    REJECTED_FILE: 'Rejected File successfully',
    APPROVED_USER_DEACTIVATION: 'Approved User Deactivation successfully',
    REJECTED_USER_DEACTIVATION: 'Rejected User Deactivation successfully',
    COMMENT_ADDED: 'Comment added successfully'
  }),

  PREFIXES: Object.freeze({
    APPROVED: 'Approved. ',
    REJECTED: 'Rejected. '
  })
});

export const CUSTOMER_EMAILS_COMPONENT = Object.freeze({
  LABELS: Object.freeze({
    ADD: 'Add',
    DELETE: 'Delete',
    EXTERNAL_USER_EMAIL: 'External User Email Address:'
  }),

  MESSAGES: Object.freeze({
    ENTER_VALID_EMAIL_ADDRESS: 'Please enter a valid Email Address.',
    ADD_EXTERNAL_USER_EMAIL: "Please add the External User's Email Address",
    INTERNAL_EMPLOYEE_CANNOT_BE_CUSTOMER: 'Internal Employee cannot be a Customer.',
    MUST_HAVE_ATLEAST_ONE_EXTERNAL_USER: 'You must have at least one External Participant.',
    CONFIRM_TO_DELETE_EXTERNAL_PARTY: 'Are you sure you wish to delete this External Party?',
    CONFIRM_TO_REACTIVATE_USER: 'is a deactivated external user. Are you sure to reactivate and add this user?'
  }),

  REGEX: Object.freeze({
    CHECK_EMAIL_ADDRESS_FORMAT: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  })
});

export const FILE_LIST_COMPONENT = {
  DOWNLOAD_INPROGRESS_MESSAGE: 'File(s) Download is in progress',
  DOWNLOAD_FAILED_ERROR_MESSAGE: 'File(s) Download Failed',
  DELETE_CONFIRM_MESSAGE: "Are you sure you wish to delete this file?",
  EXTERNAL_USER_DELETE_CONFIRM_MESSAGE: "Are you sure you wish to delete this file?",
  DELETE_SUCCESS_MESSAGE: 'File deleted successfully',
  DELETE_FILES_SUCCESS_MESSAGE: 'File(s) deleted Successfully',
  DELETE_FILES_CONFIRM_MESSAGE: "Are you sure you wish to delete selected file(s)?",
}

export const USERTYPE = Object.freeze({
  CUSTOMER: "CUSTOMER",
  FILE_APPROVER: "FILE APPROVER",
  REVIEWER: "REVIEWER",
  INITIATOR: "INITIATOR",
  FULFILLER: "FULFILLER",
  EMPLOYEE:"EMPLOYEE"
});

export const FILE_STATUS = {
  ACTIVE: "ACTIVE",
  REVIEWED: "REVIEWED",
  REJECTED: "REJECTED",
}

export const PARTICIPANTS_COMPONENT = Object.freeze({
  PARENT_DL: "(parent)",
  DL_NOT_AVAILABLE: "not available",

  DL_TYPES: Object.freeze({
    USER: "user",
    GROUP: "group"
  }),

  PLACEHOLDERS: Object.freeze({
    ROLE: "Select Role",
    EMAIL: "Employee Email or Distribution List"
  }),

  LABELS: Object.freeze({
    ADD: "Add",
    DELETE: "Delete",
    INTERNAL_EMPLOYEES: "Internal Employees",
    SELECTED_EMPLOYEES: "File transfer request will be assigned to the following employees",

    SELECT_EMPLOYEES_FOR_REQUEST: Object.freeze({
      INTERNAL: "Select employees to receive file transfers as part of this request",
      EXTERNAL: "Select employees to receive and/or approve file transfers as part of this request"
    })
  }),

  MESSAGES: Object.freeze({
    ADD_FULFILLER: "Please add Fulfiller",
    ADD_FILE_APPROVER: "Please add File Approver",
    FAILED_TO_GET_ROLES: "Failed to get Roles for Participants.",
    ONE_PARTICIPANT_REQUIRED: "You must have at least one participant.",
    ONE_FILE_APPROVER_REQUIRED: "You must have at least one file approver.",
    INITIATOR_CANNOT_BE_FILE_APPROVER: "Initiator cannot be a File Approver",
    CONFIRM_EMPLOYEE_DELETION: "Are you sure you would like to Delete this Employee?",
    ADD_AN_EMPLOYEE_AND_A_ROLE: "Please add at least one Employee and associated Role",
    EMPLOYEE_NOT_UNDER_INITIATORS_MANAGER: "Employee is not under Initiator's Manager",
    EMAIL_OR_DL_DOES_NOT_EXIST: "Given User Email or Distribution List name does not exist.",
    SELECT_INTERNAL_EMPLOYEE_ROLE: "Role must be selected for Internal Employee to continue"
  })
});

export const REQUEST_SERVICE_COMPONENT = {
  REQUEST_SUCCESS_MESSAGE: "File transfer request was created. Please navigate to the Active Requests tab to view the File Transfer Request.",
  ADD_INFO_REQUESTED_MESSAGE: "Additional information requested",
  COMMENT_MESSAGE: "A comment was added to the Comment tab that requires additional information from you. Please review and respond to the comment via the “Add Comments” button.",
  REQUEST_NAME_ALREADY_EXIST_ERROR_MESSAGE: "File Transfer Request Name already exists",
  REQUEST_CREATION_INPROGRESS_MESSAGE: "File Transfer Request creation is in progress and will be available once it is completed",
  REQUEST_UPDATE_SUCCESS_MESSAGE: "File Transfer Request updated successfully",
  TASK_CREATION_INPROGRESS_MESSAGE: "Task creation is in progress and will be available once it is completed",
  REQUEST_CLOSED_SUCCESS_MESSAGE: 'Request closed successfully',
  REQUEST_REOPEN_SUCCESS_MESSAGE: 'Request reopened Successfully',
  FULFILLMENT_TASK_CREATION_INPROGRESS_MESSAGE: "Fulfillment task creation is in progress and will be available once it is completed",
  PARENT_DISTRIBUTION_LIST: "(parent)",
  REQUEST_DETAILS_TEXT: "Request Details",
  REQUEST_FILE_TRANSFER_TEXT: "Request File Transfer",
  REQUEST_PAGE_MESSAGE_1: "The Request Details page allows you to action file transfer requests. You may upload files for transfer; preview or download transferred files; and comment on transfers and files, as necessary.",
  REQUEST_PAGE_MESSAGE_2: "On this screen you are able to create/modify/review a file transfer request with an internal or external party. In the creation of a file transfer request, all fields are required.",
  FILE_TRANSFER_NAME_CANNOT_BE_EMPTY_MESSAGE: "File transfer request name cannot be empty",
  NAME_DOESNOT_ACCEPT_SPECIAL_CHARACTERS: "File Transfer Request Name does not accept special characters",
  NAME_SHOULD_LESS_THAN_300CHARACTERS: "File Transfer Request Name should be less than 300 characters",
  REASON_CANNOT_BE_EMPTY_ERROR_MESSAGE: "The file transfer request description cannot be empty",
  REASON_DOESNOT_ACCEPT_SPECIAL_CHARACTER_MESSAGE: "The file transfer request description does not accept special characters",
  FILE_RETENTION_PERIOD_CANNOT_BE_EMPTY: "File Retention Period cannot be empty",
  EXTERNAL_PARTY_NAME_DOESNOT_ACCEPT_SPECIAL_CHARACTERS: "External Party Name does not accept special characters",
  REOPEN_CONFORM_MESSAGE: "Are you sure you wish to reopen this request?",
  CLOSE_REQUEST_CONFIRM_MESSAGE: "Are you sure you wish to close this request?",
  SUBMIT_CONFIRM_MESSAGE: "Are you sure?",
  START_FILE_SHARE_WORKFLOW: "Start File Share Workflow",
  OK_TEXT: "Ok",
  BACK_REQUEST_CONFIRM_MESSAGE: "Do you wish to discard the file transfer request?",
  BACK_REQUEST_CONFIRM_MESSAGE_AFTER_SAVE: 'If changes occurred to the file transfer request, click ‘submit’ for changes to be confirmed',
  DESCRIPTION_MAX_LENGTH_EXCEEDED_MESSAGE: `Description cannot exceed ${SEVEN_FIFTY} characters`,
  DESCRIPTION_MAX_LENGTH: SEVEN_FIFTY,

  REVIEW_REMINDER: Object.freeze({
    TITLE: 'Reminder',
    BODY: 'Please download the selected file(s) and review appropriately before approving the transfer request.'
  })
};

export const NAVIGATION_TYPE = {
  REOPEN: "Reopen",
  DOWNLOAD:"Download",
  CLOSEPROJECT:"CloseProject",
}

export const SERVICE_REQUEST_COMPONENT = {
  ASSIGNED_TO_TITLE: 'Assigned To',
  CUSTOMER_TEXT:"This menu allows you to view all details associated with your assigned file transfer requests, including active and completed requests.",
  EMPLOYEE_TEXT:"This menu allows you to view all details associated with your assigned file transfer requests and active requests."
}

export const LOGGED_IN_USER_TYPE = {
  EMPLOYEE: "EMPLOYEE",
  CUSTOMER: "CUSTOMER"
}

export const SERVICE_REQUEST_TABS={
  TASKS:"tasks",
  ACTIVE:"active",
  COMPLETED:"completed"
}

export const SERVICE_REQUEST_TABS_STATUS={
  NOT_COMPLETED:"!COMPLETED",
  COMPLETED:"COMPLETED",
  NOT_CLOSED:"NOT_CLOSED",
  CLOSED:"CLOSED"
}

export const SETTINGS_COMPONENT = Object.freeze({
  UPDATE_SUCCESS: 'Settings updated successfully.',
  FORM_SUBMIT_ERROR: 'Form submission failed.',
  PAGE_DESCRIPTION: 'The settings screen allows you to perform functions only available to internal users.',
  FILE_RETENTION_PERIOD_CANNOT_BE_EMPTY: 'Default File Retention Period Days cannot be empty',
  MAX_FILE_RETENTION_PERIOD_CANNOT_BE_EMPTY: 'Maximum File Retention Period Days cannot be empty.',

  TAB_NAMES: Object.freeze({
    FILE_RETENTION: 'File Retention',
    DEACTIVATE_USERS: 'Deactivate External Users',
    DEACTIVATE_REQUESTS: 'Deactivate Requests',
    CONFIG_FILE_DATA_TYPES: 'Configure File Data Types',
    AUDIT_TRAIL: 'Audit Trail'
  })
});

export const ROLE={
  ADMIN:"ADMIN",
  INFOSEC:"INFOSEC"
}

export const DEACTIVATE_USER_COMPONENT = Object.freeze({
  USER_MAPPED_WITH_SOME_PROJECT_MESSAGE: "User mapped with some Projects",
  USER_DEACTIVATED_SUCCESS_MESSAGE: "User Deactivated Successfully",
  FORM_SUBMISSION_ERROR: 'Form submission failed',
  INFO_MESSAGE_1: "On this screen you can request the deactivation of an external user ID so they are unable to perform file transfers or download previously transferred files. Transfer requests for which they are associated must be in closed state.",
  INFO_MESSAGE_2: "If the external user is attached to an active file transfer request, the user cannot be deactivated",
  EMAIL_ADDRESS_CANNOT_BE_EMPTY: "External User Email Address cannot be empty",
  CUSTOMER_MAPPED_WITH_REQUEST_ERROR: 'Since Customer is already mapped with File Transfer Request, You are not able to deactivate this Customer.',
  NOT_AVAILABLE: 'User is not available',
  DEACTIVATED_ALREADY: 'User deactivated already',
  REQUEST_SENT_TO_INITIATOR_SHORT: 'Request sent to Initiator',
  REQUEST_SENT_TO_INITIATOR_LONG: 'Deactivate request has been send to Initiator. After Initiator approval User will get deactivated automatically',
  REQUEST_IN_PROGRESS: 'Your request to deactivate a user is in progress'
});

export const EMAIL_VERIFICATION = {
  RESEND_EMAIL_VERIFICATION_LINK: "New email verification link sent to the registered email address. Please click link to verify your email address."
}

export const CONNECTION_TEST_MODAL_COMPONENT = {
  WIDTH: 600,
  TITLE: "Warning!",
  CONTENT: (
    <>
      Please ask your IT Department to whitelist the below application endpoints
      to ensure successful uploading and downloading of files.
      <br/><br/>
      <ul>
        <li>{UI_URL}</li>
        <li>{API_URL}</li>
      </ul>
      A test of your connection noted the <a target='_blank' rel='noopener noreferrer'
      href='https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS'>
      Cross-Origin Resource Sharing (CORS) Security Headers</a> are not detected,
      which may be removed by a web proxy on your corporate network.
    </>
  )
}

export const PROGRESS_BARS_TEXT = {
  UPLOADING: "Uploading...",
  UPLOADED: "Uploaded",
  ENCRYPTING: "Encrypting...",
  DOWNLOADING: "Downloading",
  DOWNLOADED: "Downloaded"
}

export const SOCKETIO_EVENTS = {
  UPLOAD_FORMDATA: "Uploading-Form-Data",
  ENCRYPTION_PROGRESS: "Encrypting-Files-Progress"
}

export const EMAIL_NOTIFICATION_TYPES = {
  FILE_DOWNLOADS: 'File-Downloads'
}

export const CANCELED_AXIOS_ERROR_CODE = "ERR_CANCELED";

export const ONE_KB = 1024;
export const ONE_MB = ONE_KB * ONE_KB;
export const ONE_GB = ONE_MB * ONE_KB;

export const MAX_SIZE_FOR_SINGLE_FILE_UPLOAD = ONE_GB;

const EIGHT_HUNDRED_MB = 800 * ONE_MB;
export const MAX_SIZE_FOR_MULTIPLE_FILE_UPLOAD = EIGHT_HUNDRED_MB;

export const MAX_FILES_PER_UPLOAD = 25;

export const PROGRESS_BAR_VALUE_FLOATING_POINTS = 1;

export const USER_INACTIVE_TIMEOUT_TIME = 1800;

export const USER_GUIDE_URL = {
  INTERNAL: "https://reedelsevier.sharepoint.com/sites/the_Link/CyberDefense/SitePages/Secure-File-Exchange-Tool-User-Guide.aspx?#common-issues-encountered",
  INTERNAL_HOW_TO_LOGIN: "https://reedelsevier.sharepoint.com/sites/the_Link/CyberDefense/SitePages/Secure-File-Exchange-Tool-User-Guide.aspx?#how-do-i-log-in-to-the-tool",
  EXTERNAL: EXTERNAL_USER_GUIDE
};

export const APPFOOTER_COMPONENT = Object.freeze({
  COPYRIGHT: `${APP_NAME} Portal © ${new Date().getFullYear()}`,

  COOKIE_POLICY: Object.freeze({
    TEXT: 'Cookie Policy',
    URL: 'https://risk.lexisnexis.com/group/cookie-policy'
  }),

  PRIVACY_POLICY: Object.freeze({
    TEXT: 'Privacy Policy',
    URL: 'https://risk.lexisnexis.com/group/privacy-policy',
  }),

  TERMS_AND_CONDITIONS: Object.freeze({
    TEXT: 'Terms & Conditions',
    URL: 'https://risk.lexisnexis.com/terms'
  })
});

export const REGEX_STRINGS = {
  ALLOW_LETTERS_NUMBERS_UNDERSCORE_PERIOD_AND_HYPHEN: /^[a-zA-Z0-9_.-\s]*$/i,
  ALLOW_LETTERS_NUMBERS_UNDERSCORE_PERIOD_COMMA_AND_HYPHEN: /^[a-zA-Z0-9_.,-\s]*$/i
};

export const FILE_DOWNLOAD_COMPONENT = Object.freeze({
  PROGRESS_PRECISION: PROGRESS_BAR_VALUE_FLOATING_POINTS,
  MAX_FILE_SIZE: MAX_SIZE_FOR_SINGLE_FILE_UPLOAD,

  AXIOS_ERROR_CODES: Object.freeze({
    CANCELLED: 'ERR_CANCELED',
    NETWORK: 'ERR_NETWORK'
  }),

  STATUS_TYPES: Object.freeze({
    DOWNLOAD_ACCESS_PENDING: 'Download Access Pending',
    DOWNLOAD_ACCESS_ALLOWED: 'Download Access Allowed',
    DOWNLOAD_ACCESS_DENIED: 'Download Access Denied',
    DOWNLOAD_SUCCESS: 'Download Success',
    NETWORK_ERROR: 'Network Error'
  }),

  MODAL_TITLES: Object.freeze({
    DOWNLOADING: 'Downloading',
    DOWNLOAD_DENIED: 'Download Denied',
    DOWNLOAD_COMPLETED: 'Download Completed',
    CHECKING_ACCESS: 'Checking Access',
    NETWORK_PROBLEM: 'Network Problem'
  }),

  /**
   * Definition of custom download object
   */
  FILE_INFO: class {
    constructor(id, name) {
      this.id = id;
      this.name = name;
      this.progress = 0;
    }
  }
});

export const AXIOS_CONFIG_VALUES = Object.freeze({
  APPLICATION_JSON: 'application/json',
  AUTHORIZATION: 'authorization',
  CONTENT_TYPE: 'Content-Type',
  BLOB_RESPONSE: 'blob',
  POST_METHOD: 'post',
  GET_METHOD: 'get',
  PUT_METHOD: 'put',
  DELETE_METHOD: 'delete',
  MULTIPART_FORMDATA: 'multipart/form-data'
});

export const REQUEST_DETAIL_TABS = Object.freeze({
  TASKS: 'Tasks',
  COMMENTS: 'Comments',
  FILES: 'Files',
  REQUEST_HISTORY: 'Request History',
  MANAGE_KEYS: 'Manage Keys'
});

export const THREATMETRIX_COMPONENT = Object.freeze({
  URL: process.env.REACT_APP_TMX_URL,
  ORG_ID: process.env.REACT_APP_TMX_ORG_ID,
  PAGE_ID: 1
});

export const SESSION_STORAGE_KEYS = Object.freeze({
  REQ_URL: 'requestUrl',
  MSAL_TOKEN: 'msal.idtoken',
  USER_LOGIN: 'userLogin',
  EXPIRED_TIME: '_expiredTime'
});

export const MAIN_MENU_COMPONENT = Object.freeze({
  TITLE: 'Menu',

  ITEMS: Object.freeze({
    REQUESTS: 'Requests',
    SETTINGS: 'Settings',
    USER_GUIDE: 'User Guide',
    LOGOUT: 'Logout'
  }),

  STYLES: Object.freeze({
    ICON: Object.freeze({
      verticalAlign: 'baseline', fontSize: '1.2rem'
    }),

    HEADER: Object.freeze({
      textAlign: 'center', fontWeight: 'bolder'
    })
  })
});

export const APPHEADER_COMPONENT = Object.freeze({
  LOGO_ALT: APP_OWNER,
  SMALL_DEVICE_WIDTH: 800,

  LOGO: Object.freeze({ FULL: LNRS_LOGO, SHORT: LNRS_LOGO_ICON_ONLY }),

  TITLE: Object.freeze({ FULL: APP_NAME, SHORT: 'S.F.E.'}),

  STYLES: Object.freeze({
    HEADER: Object.freeze({
      background: "#0754A0", padding: "0px 20px 0px 10px", height: "65px",
      width: "100%", position: "fixed", zIndex: "999"
    }),

    SPACE: Object.freeze({ display: 'flex', justifyContent: 'space-between' }),

    LOGO_DIV: Object.freeze({
      FULL: Object.freeze({ marginRight: '0px' }),

      SHORT: Object.freeze({ marginRight: '124px' })
    }),

    TITLE: Object.freeze({
      color: '#ffffff', fontSize: '24px', fontWeight: 'bolder'
    }),

    MENU: Object.freeze({ width: '160px', textAlign: 'right' }),

    TRANSPARENT_DIV: Object.freeze({ width: '160px', opacity: 0 })
  })
});

export const AUDIT_TRAIL_COMPONENT = Object.freeze({
  DESCRIPTION: Object.freeze({
    MANAGER: 'On this screen a manager can review the details related to all file transfer requests performed by their team.',
    ADMIN: 'On this screen an administrator can review details related to the tool.',
    INFOSEC: 'On this screen an information security user can review details related to the tool.'
  }),

  COLUMN_INFO: Object.freeze([
    {
      "col": "id",
      "displayName": "Id",
      "displayOrder": 1,
    },
    {
      "col": "componentName",
      "displayName": "Component Name",
      "displayOrder": 2,
      "width": "170px"
    },
    {
      "col": "eventName",
      "displayName": "Event Name",
      "displayOrder": 3,
      "width": "150px"
    },
    {
      "col": "userEmailId",
      "displayName": "User",
      "displayOrder": 4,
      "width": "200px"
    },
    {
      "col": "actionType",
      "displayName": "Action Type",
      "displayOrder": 5,
      "width": "140px"
    },
    {
      "col": "description",
      "displayName": "Description",
      "displayOrder": 6,
    },
    {
      "col": "createdOn",
      "displayName": "Timestamp",
      "displayOrder": 7,
      "width": "130px"
    },
    {
      "col": "serviceRequestId",
      "displayName": "Request Id",
      "displayOrder": 8,
      "width": "130px"
    }
  ])
});

export const FOLDER_COMPONENT = Object.freeze({
  VALUE: 'value',
  CUSTOMERS: 'customers',
  PERMISSION: 'permission',
  EXTERNAL_USER: 'External User',
  ADD_PERMISSIONS: 'Add Permissions',
  EDIT_PERMISSIONS: 'Edit Permissions',
  INTERNAL_EMPLOYEES: 'internalEmployees',

  TITLES: Object.freeze({
    CREATE_FOLDER: 'Create Folder',
    EDIT_FOLDER: 'Edit Folder'
  }),

  STYLES: Object.freeze({
    READ_ONLY_INPUT: Object.freeze({ width: '70%' }),
    SELECT: Object.freeze({ width: '130px' })
  }),

  MESSAGES: Object.freeze({
    FOLDER_CREATED: 'Folder created successfully.',
    FOLDER_UPDATED: 'Folder updated successfully.',
    SELECT_PERMISSION: 'Please select permission for the selected participant',
    ENTER_VALID_NAME: 'Please enter valid folder name!'
  }),

  PERMISSIONS: Object.freeze({
    NONE: 'none',
    UPLOAD: 'upload',
    DOWNLOAD: 'download',
    EDIT: 'edit'
  }),

  PERMISSION_LABELS: Object.freeze({
    NONE: 'Select',
    UPLOAD: 'Upload Only',
    DOWNLOAD: 'Download Only',
    EDIT: 'Edit'
  })
});

export const FILES_TO_APPROVE_COMPONENT = Object.freeze({
  NO_FILES: 'No Files',
  DOWNLOAD: 'Download',
  INVALID_FILE_ID: 'Invalid File Id',
  DEACTIVATE_USER_REQUEST: 'DeactivateUserRequest',

  STATUSES: Object.freeze({
    TO_BE_REVIEWED: 'TO_BE_REVIEWED',
    TO_BE_REVIEWED_EXEMPT_FILE_RETENTION_PERIOD: 'TO_BE_REVIEWED_EXEMPT_FILE_RETENTION_PERIOD'
  }),

  TASKS: Object.freeze({
    APPROVE_EXTERNAL_USER_DEACTIVATION: 'Approve to deactivate the external user.',
    REVIEW_FILE_FOR_EXTERNAL_USER: 'Review file to ensure it is appropriate before transferring externally.',
    APPROVE_FILE_RETENTION_PERIOD_EXTENSION: 'File retention period extension from <FILE_RETENTION_PERIOD_VALUE> days requested. Upon clicking Approve, the extension period can be selected.'
  })
});

export const FOLDER_LIST_COMPONENT = Object.freeze({
  BUTTON_LABELS: Object.freeze({
    UPLOAD_FILE: 'Upload File',
    ADD_FOLDER: 'Add Folder',
    DOWNLOAD: 'Download',
    COMMENTS: 'Comments',
    DELETE: 'Delete',
    EDIT: 'Edit'
  }),

  MESSAGES: Object.freeze({
    FILE_DELETION_SUCCESS: 'File(s) deleted successfully.',
    FOLDER_DELETION_SUCCESS: 'Folder deleted successfully.',
    SELECT_FILE_TO_DOWNLOAD: 'Please select File(s) to Download.',
    CONFIRM_DELETION: 'Are you sure to Delete selected File(s)/Folder(s)?',
    ERROR_WHEN_GETTING_FILE_AND_FOLDER_DATA: 'When getting File(s) & Folder(s) data.',
    CONFIRM_FOLDER_DELETION: 'Deleting this Folder will Delete all the contents inside it. Are you sure to Delete this Folder?',
    CANNOT_DELETE_FILE_OR_FOLDER: 'You are not permitted to Delete the selected File(s)/Folder(s). Please reselect the appropriate File(s).'
  })
});

import React, { useState } from "react";
import { Link } from "react-router-dom";
import assembler from "url-assembler";
import axios from "../../utils/axios";
import lodashUniqBy from 'lodash/uniqBy';
import { useSelector } from "react-redux";
import { API_ROUTES } from "../../utils/webConstants";
import { checkEmailCanLogin } from "../../utils/authUtils";
import { CUSTOMER_EMAILS_COMPONENT } from "../../utils/appConstants";

import {
    Form, Row, Col, Input, Button, Table, Popconfirm, Modal, message
} from "antd";

import {
    DeleteOutlined, InfoCircleOutlined, ExclamationCircleOutlined
} from "@ant-design/icons";

const { LABELS, MESSAGES, REGEX } = CUSTOMER_EMAILS_COMPONENT;

const CustomerEmails = (props) => {
    const [customerEmail, setCustomerEmail] = useState("");
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const { userInfo } = useSelector((state) => state.userLogin);
    const userToken = userInfo?.userToken ?? '';

    const onCustomerEmailChange = (e) => setCustomerEmail(e.target.value);

    const checkCustomerIsActive = async (email) => {
        const url = assembler(API_ROUTES.GET_USER).query({ emailId: email });

        const { data } = await axios.get(url, {
            headers: { authorization: userToken }
        });

        if (data?.payload?.status === "DEACTIVATED") {
            const { firstName, lastName } = data.payload;
            const customerName = `${firstName} ${lastName}`;

            Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                content: `${customerName} ${MESSAGES.CONFIRM_TO_REACTIVATE_USER}`,
                onOk() {
                    checkCustomerEmail();
                    return;
                },
                onCancel() {
                    return;
                },
            });
        }
        else {
            checkCustomerEmail();
        }
    };

    const handleCustomerEmail = async () => {
        if (!customerEmail) {
            setIsError(true);
            setErrorMessage(MESSAGES.ADD_EXTERNAL_USER_EMAIL);
            return;
        }

        if (customerEmail) await checkCustomerIsActive(customerEmail);
    };

    const checkCustomerEmail = async () => {
        if (customerEmail) {
            const valid = checkEmailCanLogin(customerEmail);

            if (!valid) {
                setIsError(true);
                setErrorMessage(MESSAGES.INTERNAL_EMPLOYEE_CANNOT_BE_CUSTOMER);
                return;
            }

            const isValidAddress = REGEX.CHECK_EMAIL_ADDRESS_FORMAT.test(
                String(customerEmail).toLowerCase()
            );

            if (!isValidAddress) {
                setIsError(true);
                setErrorMessage(MESSAGES.ENTER_VALID_EMAIL_ADDRESS);
                return;
            }

            setIsError(false);

            const updatedCustomerEmailData = [...props.customerEmailData];

            if (customerEmail !== "") {
                updatedCustomerEmailData.push({ email: customerEmail });
            }

            setCustomerEmail("");

            props.form.setFieldsValue({ customerEmail: undefined });

            props.onChange({
                customerEmailData: lodashUniqBy(updatedCustomerEmailData, "email")
            });
        }
    };

    const onDeleteEmailClick = (e, v) => {
        const index = props.customerEmailData.findIndex((x) => x.email === e);
        const td = [...props.customerEmailData];

        if (props.customerEmailData.length <= 1) {
           message.error(MESSAGES.MUST_HAVE_ATLEAST_ONE_EXTERNAL_USER);
           return true;
        }

        td.splice(index, 1);
        props.onChange({ customerEmailData: td });
    };

    let customerEmailColumn = [{
        title: "External User Email Address",
        dataIndex: "email",
        key: "email"
    }];

    if (!props.disableFields) {
        customerEmailColumn.push({
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (e, row) => deleteEmailContent(e, row),
            width: "80px"
        });
    }

    const deleteEmailContent = (e, record) => {
        return (
            <div className="deleteAction">
                <Popconfirm
                    title={MESSAGES.CONFIRM_TO_DELETE_EXTERNAL_PARTY}
                    onConfirm={() => onDeleteEmailClick(record.email)}
                    okText="Yes"
                    cancelText="No"
                    icon={<InfoCircleOutlined style={{ color: 'red' }} />}
                >
                    <Link to="#" style={{ color: "red" }}>
                        <DeleteOutlined style={{ color: "red", height: "17px" }} />
                        &nbsp;
                        {LABELS.DELETE}
                    </Link>
                </Popconfirm>
            </div>
        );
    };

    return (
        <Row className="row-padding">
            <Col span="8">
                <label style={{ fontWeight: "bold" }}>
                    {LABELS.EXTERNAL_USER_EMAIL}
                </label>
            </Col>
            <Col span="16">
                {!props.disableFields ? <>
                    <Input.Group compact style={{ display: "flex" }}>
                        <Form.Item noStyle name="customerEmail">
                            <Input
                                type="email"
                                id="customerEmail"
                                autoComplete="off"
                                name="customerEmail"
                                value={customerEmail}
                                readOnly={props.disableFields}
                                onChange={onCustomerEmailChange}
                            />
                        </Form.Item>
                        <Button
                            onClick={handleCustomerEmail}
                            disabled={props.disableFields}
                        >
                            {LABELS.ADD}
                        </Button>
                    </Input.Group>

                    {props.customerEmailErrorMessage === true ? <label className="errorMessage">
                        {MESSAGES.ADD_EXTERNAL_USER_EMAIL}
                    </label> : null}

                    {isError === true ? <label className="errorMessage">
                        {errorMessage}
                    </label> : null}
                </> : null}

                {props.customerEmailData.length > 0 && <Row>
                    <Col span="25">
                        <Table style={{ fontWeight: "normal", paddingTop: "5px" }}
                            dataSource={props.customerEmailData}
                            columns={customerEmailColumn}
                            scroll={{ y: 450 }}
                            pagination={false}
                            bordered={true}
                            size="small"
                            rowKey="id"
                        />
                    </Col>
                </Row>}
            </Col>
        </Row>
    );
};

export default CustomerEmails;

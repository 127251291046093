import { API_ROUTES, UI_ROUTES } from "../../utils/webConstants";
import { useDispatch, useSelector } from "react-redux";
import { SettingFilled } from "@ant-design/icons";
import EmptyTable from '../components/EmptyTable';
import { useEffect, useState } from "react";
import Table from "../components/Table";
import moment from "moment";
import "../../auditLog.css";
import axios from "../../utils/axios";
import Settings from "./settings/Settings";
import Pagination from "../components/Pagination";
import { Link, useHistory } from 'react-router-dom';
import { DatePicker, Form, Input, Button, Spin } from "antd";
import { requestSelected } from "../../redux/actions/requestActions";

import {
  ROLE, AUDIT_TRAIL_COMPONENT, AXIOS_CONFIG_VALUES
} from "../../utils/appConstants";

const { DESCRIPTION, COLUMN_INFO } = AUDIT_TRAIL_COMPONENT;

const AuditTrail = (props) => {
  let history = useHistory();
  const [visible, setVisible] = useState(false);
  let [auditData, setAuditData] = useState([]);
  const [cols, setCols] = useState([]);
  const [serverSideCurrentPage, setServerSideCurrentPage] = useState(1);
  const [serverSideLimitPerPage, setServerSideLimitPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortKey, setSortKey] = useState(null);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [range, setRange] = useState(null);
  const [availableFields, setAvailableFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const userToken = (userInfo) ? userInfo.userToken : '';

  const handleModalOk = (e, options) => {
    setVisible(false);

    const modifiedCols = options.map((item) => {
      return {
        title: item.children, sorter: true, dataIndex: item.value, width: "20%"
      };
    });

    setCols(modifiedCols);
  };

  const handleModalCancel = (e) => setVisible(false);

  const onPaginate = (page, pageSize) => {
    setServerSideCurrentPage(page);
    setServerSideLimitPerPage(pageSize);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let sortingOrder = null;

    if (sorter.order === "descend") sortingOrder = "desc";

    else if (sorter.order === "ascend") sortingOrder = "asc";

    else sortingOrder = sorter.order;

    setSortOrder(sortingOrder);
    setSortKey(sorter.field);
  };

  const getData = async (param) => {
    setLoading(true);
    let url = API_ROUTES.GET_AUDIT_LOGS;

    if (Object.keys(param).length > 0) url = `${url}?`;

    if (param.serverSideCurrentPage && param.serverSideLimitPerPage)
      url = `${url}page=${param.serverSideCurrentPage}&limit=${param.serverSideLimitPerPage}`;

    if (param.sortOrder && param.sortKey)
      url = `${url}&sortOrder=${param.sortOrder}&sortBy=${param.sortKey}`;

    if (param.search) url = `${url}&search=${param.search}`;

    if (param.from && param.to)
      url = `${url}&from=${new Date(param.from)}&to=${new Date(param.to)}`;

    const { data } = await axios.get(url, { headers: {
      [AXIOS_CONFIG_VALUES.AUTHORIZATION]: userToken
    }});

    if (param.searchAll || param.from || param.to) auditData = [];

    setAuditData(data.payload);
    setTotal(data.total);
    setLoading(false);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    const minDate = moment();
    return current >= minDate;
  };

  const searchAllData = () => {
    getData({
      serverSideCurrentPage: 1, serverSideLimitPerPage, sortOrder, sortKey,
      search, searchAll: true
    });
  };

  const findByDateRange = () => {
    if (range) {
      getData({
        serverSideCurrentPage: 1, serverSideLimitPerPage, sortOrder, sortKey,
        search, from: range[0], to: range[1]
      });
    }
  };

  const onRangeChange = (e, v) => {
    if (e?.length === 2) setRange(e);
  };

  const descriptionClick = (requestId, fileId) => {
    dispatch(requestSelected(requestId, 0, '', 0));
    let goToUrl = `${UI_ROUTES.REQUEST_DETAIL_PATH}/${window.btoa(requestId)}`;

    if (fileId) goToUrl += `/file/${window.btoa(fileId)}`;

    history.push(goToUrl);
  };

  useEffect(() => {
    initializeDisplayColumns();
  }, []);

  const getScreenDescription = ({ role, isManager }) => {
    let description = null;

    if (role === ROLE.ADMIN) description = DESCRIPTION.ADMIN;

    else if (role === ROLE.INFOSEC) description = DESCRIPTION.INFOSEC;

    else if (isManager) description = DESCRIPTION.MANAGER;

    return <div><p>{description}</p></div>;
  };

  const initializeDisplayColumns = async () => {
    try {
      let data = COLUMN_INFO;
      let savedColumns;

      if (props.role !== ROLE.ADMIN && props.role !== ROLE.INFOSEC && props.isManager) {
        savedColumns = [
          "eventName", "userEmailId", "createdOn", "description"
        ];

        data = data.filter(x => {
          if (x.col === "actionType") return false;

          else if (x.col === "componentName") return false;

          else return true;
        });
      }
      else {
        savedColumns = [
          "componentName", "eventName", "userEmailId", "createdOn",
          "description", "actionType"
        ];
      }

      const initColumns = savedColumns.map((e) => {
        const colAttrib = data.find((item) => item.col === e);

        const obj = {
          title: colAttrib.displayName,
          dataIndex: colAttrib.col,
          sorter: true,
          width: colAttrib.width,
          render: (text, record) => {
            const isAllowedUser = (
              props.role === ROLE.ADMIN || props.role === ROLE.INFOSEC || props.isManager
            );

            if (colAttrib.displayName === "Description" && isAllowedUser) {
              return (
                (record?.serviceRequestId > 0)
                  ? <Link to="#" onClick={() => descriptionClick(record.serviceRequestId, record.fileId)}> {text}</Link>
                  : <div>{text}</div>
              );
            }
            else {
              return (
                <div className="auditLogTable">
                  {text === "" ? "NA" : text}
                </div>
              );
            }
          }
        };

        if (colAttrib.col === "createdOn") {
          obj.render = (dateAndTime) => getFormattedDateTime(dateAndTime);
        }

        return obj;
      });

      setCols(initColumns);
      setAvailableFields(data);
    }
    catch (error) {
      console.error(error);
    }
  };

  function getFormattedDateTime(dateAndTime) {
    const timeFormat = 'MMMM DD, yyyy h:mm:ss A';
    const inLocalTimeZone = moment(dateAndTime).local().format(timeFormat);

    return <div className="auditLogTable">{inLocalTimeZone}</div>;
  }

  useEffect(() => {
    getData({
      serverSideCurrentPage, serverSideLimitPerPage, sortOrder, sortKey, search,
      from: range ? range[0] : null,
      to: range ? range[1] : null,
    });
  }, [serverSideCurrentPage, serverSideLimitPerPage, sortOrder, sortKey]);

  const searchByDateRange = (
    <div style={{ alignItems: "flex-start" }}>
      Date Range:&nbsp;
      <DatePicker.RangePicker
        format={"MM-DD-YYYY"} onChange={onRangeChange}
        ranges={{
          Today: [moment(), moment()],
          "This Month": [
            moment().startOf("month"), moment().endOf("month"),
          ]
        }}
        disabledDate={disabledDate}
      />
      <Button type="primary" onClick={findByDateRange}>Search</Button>
    </div>
  );

  const customizeIcon = (
    <SettingFilled style={{ verticalAlign: 'baseline', fontSize: '1.1rem' }}/>
  );

  return (
    <div className="site-layout-content">
      {getScreenDescription(props)}
      <Spin spinning={loading} tip={"loading"} size="large">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {searchByDateRange}
          <div>
            <Form style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item>
                <Input
                  placeholder="search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={searchAllData}>
                  Search all
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div>
            <Button
              type="primary" onClick={() => setVisible(true)}
              icon={customizeIcon}
            >
              Customize
            </Button>
            <Settings
              visible={visible} handleModalCancel={handleModalCancel}
              availableFields={availableFields} handleModalOk={handleModalOk}
            />
          </div>
        </div>
        <Table
          columns={cols} dataSource={auditData}
          handleTableChange={handleTableChange}
          locale={{ emptyText: <EmptyTable text="No Logs" /> }}
        />
        {auditData.length > 0 && (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div>
              <Pagination
                total={total} defaultPageSize={serverSideLimitPerPage}
                defaultCurrent={serverSideCurrentPage} onChange={onPaginate}
              />
            </div>
          </div>
        )}
      </Spin>
    </div>
  );
};

export default AuditTrail;

import React, { useEffect, useState, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import lodashUniqBy from 'lodash/uniqBy';
import axios from "../../utils/axios";

import {
    API_CREATE_FOLDER, API_BASE
} from "../../utils/webConstants";

import {
    AXIOS_CONFIG_VALUES, USERTYPE, FOLDER_COMPONENT
} from '../../utils/appConstants';

import {
    Form, Spin, Modal, Input, message, Select, //Checkbox, Row, Col
} from "antd";

const {
    APPLICATION_JSON, CONTENT_TYPE, AUTHORIZATION, POST_METHOD, PUT_METHOD
} = AXIOS_CONFIG_VALUES;


const {
    INTERNAL_EMPLOYEES, CUSTOMERS, VALUE, PERMISSION, TITLES, STYLES, MESSAGES,
    PERMISSIONS, PERMISSION_LABELS, EXTERNAL_USER, ADD_PERMISSIONS,
    EDIT_PERMISSIONS
} = FOLDER_COMPONENT;

const { REVIEWER, FILE_APPROVER, INITIATOR } = USERTYPE;
const { Option } = Select;

const Folder = ({
    issFolderModelOpen, setIsFolderModelOpen, folderId, requestId, getData,
    getFolderHierarchy, handleFolderEdit, folderEdit, selectedFolderIdToEdit,
    breadCrumbData, rootFolderId
}) => {
    const [dataTypeForm] = Form.useForm();
    const [folderForm] = Form.useForm();
    const [internalEmployees, setInternalEmployees] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [isErrorMessage, setIsErrorMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    let history = useHistory();

    const { userInfo } = useSelector((state) => state.userLogin);
    const userToken = userInfo?.userToken ?? '';

    const axiosHeaders = {
        auth: { [AUTHORIZATION]: userToken },

        contentAndAuth: {
            [CONTENT_TYPE]: APPLICATION_JSON, [AUTHORIZATION]: userToken
        }
    };

    const handleFolderModalCancel = () => setIsFolderModelOpen(false);

    useEffect(async () => {
        if (folderEdit) {
            const folder = getFolderDetail();
        }
        else {
            if (!folderId) {
                setLoading(true);
                await getParticipantList();
                setLoading(false);
            }
        }
    }, [])

    const setPermissions = (values) => {
        const permissions = [];

        for (const [key, value] of Object.entries(values)) {
            const splitArray = key.split("_");
            const isPermissionNone = (value.permission === PERMISSIONS.NONE);

            if (splitArray[0] === INTERNAL_EMPLOYEES && !isPermissionNone) {
                permissions.push({
                    associatedEmployeeId: splitArray[1],
                    access: value.permission,
                    externalUserId: null,
                });
            }

            if (splitArray[0] === CUSTOMERS && !isPermissionNone) {
                permissions.push({
                    associatedEmployeeId: null,
                    access: value.permission,
                    externalUserId: splitArray[1],
                });
            }
        }

        return permissions;
    };

    const getParticipantList = async () => {
        const url = `${API_BASE}/service-request/${requestId}/participants`;

        const { data } = await axios.get(url, { headers: axiosHeaders.auth });

        const obj = {};

        for (let i = 0; i < data.payload.associatedEmployees.length; i++) {
            const item = data.payload.associatedEmployees[i];

            if (item.role === REVIEWER) item.role = FILE_APPROVER;

            if (obj[item.email.trim().toLowerCase()]) {
                obj[item.email.trim().toLowerCase()].role = `${obj[item.email.trim().toLowerCase()].role} & ${item.role}`;
            }
            else {
                obj[item.email.trim().toLowerCase()] = item;
            }

        }

        setInternalEmployees(Object.values(obj));
        setCustomers(lodashUniqBy(data.payload.customers, (e) => e.email));
    };

    const getFolderDetail = async () => {
        try {
            setLoading(true);
            const url = `${API_BASE}/folders/${requestId}/${selectedFolderIdToEdit}`;

            if (!folderId) getParticipantList();

            const { data } = await axios.get(url, { headers: axiosHeaders.auth });

            folderForm.setFieldsValue({ folderName: data.payload.name });
            folderForm.setFieldsValue(data.payload.permissions);
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
        }
    };

    const handleFolderModalOk = (val) => {
        if (folderEdit && issFolderModelOpen) updateFolder(folderForm);

        else addFolder(folderForm);
    };

    const handleFolderUpdateError = (error) => {
        setLoading(false);
        message.error('When saving Folder data.');
        console.error(error);
    };

    const errorMessage = (
        <>
            <label className="errorMessage">
                {MESSAGES.SELECT_PERMISSION}
            </label> <br />
        </>
    );

    const errorMessageContent = (isErrorMessage) ? errorMessage : null;

    const addFolder = (folderForm) => {
        folderForm.validateFields()
            .then((values) => {
                const permissions = setPermissions(values);

                const employeesCheckbox = Object.keys(values).filter(
                    key => key.includes(`${INTERNAL_EMPLOYEES}_`) || key.includes(`${CUSTOMERS}_`)
                );

                let validForm = true;

                if (employeesCheckbox?.length > 0) {
                    const invalidPermissionValues = [null, undefined, PERMISSIONS.NONE];

                    for (let i = 0; i < employeesCheckbox.length; i++) {
                        if (invalidPermissionValues.includes(values[employeesCheckbox[i]].permission)) {
                            validForm = false;
                        }

                        /*if (values[employeesCheckbox[i]].checked !== false && values[employeesCheckbox[i]].checked !== undefined &&
                            (values[employeesCheckbox[i]].permission === null || values[employeesCheckbox[i]].permission === undefined)) {
                            validForm = false;
                        }*/
                    }
                }

                if (!validForm) {
                    setIsErrorMessage(true);
                    return false;
                }
                else {
                    setLoading(true);

                    axios({
                        method: POST_METHOD,
                        url: API_CREATE_FOLDER,
                        headers: axiosHeaders.contentAndAuth,
                        data: {
                            folderName: values.folderName,
                            serviceRequestId: requestId,
                            parentFolderId: folderId,
                            rootFolderId,
                            permissions
                        }
                    })
                    .then((response) => {
                        if (response?.status === 201) {
                            message.success(MESSAGES.FOLDER_CREATED);
                            setIsFolderModelOpen(false);
                            folderForm.resetFields();
                            getData(folderId);
                            getFolderHierarchy();
                        }

                        setLoading(false);
                    })
                    .catch((error) => handleFolderUpdateError(error));
                }
            })
    };

    const updateFolder = (folderForm) => {
        folderForm.validateFields()
            .then((values) => {
                const permissions = setPermissions(values);

                /*const employeesCheckbox = Object.keys(values).filter(
                    key => key.includes("internalEmployees_") || key.includes("customers_")
                );*/

                let validForm = true;

                /*if (employeesCheckbox && employeesCheckbox.length > 0) {
                    for (let i = 0; i < employeesCheckbox.length; i++) {
                        if (values[employeesCheckbox[i]].checked !== false && values[employeesCheckbox[i]].checked !== undefined &&
                            (values[employeesCheckbox[i]].permission === null || values[employeesCheckbox[i]].permission === undefined)) {
                            validForm = false;
                        }
                    }
                }*/

                if (!validForm) {
                    setIsErrorMessage(true);
                    return false;
                }
                else {
                    setIsErrorMessage(false);
                    setLoading(true);

                    axios({
                        method: PUT_METHOD,
                        url: API_CREATE_FOLDER,
                        headers: axiosHeaders.contentAndAuth,
                        data: {
                            folderName: values.folderName,
                            serviceRequestId: requestId,
                            folderId: selectedFolderIdToEdit,
                            permissions
                        }
                    })
                    .then((response) => {
                        if (response?.status === 200) {
                            message.success(MESSAGES.FOLDER_UPDATED);
                            setIsFolderModelOpen(false);
                            folderForm.resetFields();
                            getData(folderId);
                            getFolderHierarchy();
                            setLoading(false);
                        }
                    })
                    .catch((error) => handleFolderUpdateError(error));
                }
            })
    };

    //const customerPermisionChange = (e) => setIsErrorMessage(false);

    //const employeePermisionChange = (e) => setIsErrorMessage(false);

    /*const customerCheckboxChange = (e, id) => {
        let idValue=document.querySelector('[id*=' + id + '_permission]').id;
        if(idValue && e.target.checked){
        let dropdown = document.getElementById(idValue);
        dropdown.focus();
        }
        setIsErrorMessage(false);
    }*/

    /*const employeeCheckboxChange = (e, id) => {
        let idValue=document.querySelector('[id*=' + id + '_permission]').id;
        if(idValue && e.target.checked){
        let dropdown = document.getElementById(idValue);
        dropdown.focus();
        }
        setIsErrorMessage(false);
    }*/

    return (
        issFolderModelOpen && <Modal
            open={issFolderModelOpen}
            title={folderEdit ? TITLES.EDIT_FOLDER : TITLES.CREATE_FOLDER}
            okText="Save"
            form="folderForm"
            onCancel={handleFolderModalCancel}
            onOk={handleFolderModalOk}
            okButtonProps={{ disabled: dataTypeForm.isSubmitting }}
            width={600}
            centered
            keyboard={false}
            maskClosable={false}
        >
            <Spin spinning={loading} tip="Loading..." size="large">
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    form={folderForm}
                    layout="vertical"
                    align="left"
                >
                    <Form.Item
                        label="Name"
                        name="folderName"
                        rules={[{ required: true, message: MESSAGES.ENTER_VALID_NAME }]}
                    >
                        <Input />
                    </Form.Item>

                    {folderEdit ? !folderId && EDIT_PERMISSIONS : !folderId && ADD_PERMISSIONS}
                    <br />
                    <br />

                    {internalEmployees?.length > 0 && internalEmployees.map((ie) => (
                        <Fragment key={ie.id}>
                            <Form.Item>
                                <Input.Group compact>
                                    <Form.Item
                                        name={[`${INTERNAL_EMPLOYEES}_${ie.id}`, VALUE]}
                                        noStyle
                                        initialValue={ie.name ? `${ie.name} (${ie.role})` : `${ie.email} (${ie.role})`}
                                    >
                                        <Input style={STYLES.READ_ONLY_INPUT} readOnly />
                                    </Form.Item>
                                    <Form.Item
                                        name={[`${INTERNAL_EMPLOYEES}_${ie.id}`, PERMISSION]}
                                        noStyle
                                        initialValue={ie.role === INITIATOR ? PERMISSIONS.EDIT : PERMISSIONS.NONE}
                                    >
                                        <Select disabled={ie.role === INITIATOR} style={STYLES.SELECT}>
                                            <Option value={PERMISSIONS.NONE}>{PERMISSION_LABELS.NONE}</Option>
                                            {/* <Option value="view">View Only</Option> */}
                                            <Option value={PERMISSIONS.UPLOAD}>{PERMISSION_LABELS.UPLOAD}</Option>
                                            <Option value={PERMISSIONS.DOWNLOAD}>{PERMISSION_LABELS.DOWNLOAD}</Option>
                                            <Option value={PERMISSIONS.EDIT}>{PERMISSION_LABELS.EDIT}</Option>
                                        </Select>
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                        </Fragment>
                    ))}

                    {/* {internalEmployees && internalEmployees.length > 0 && internalEmployees.map((ie, index) => <>
                        <Row style={{ paddingBottom: "5px" }}>
                            <Col span="18">
                                <Form.Item
                                    name={[`internalEmployees_${ie.id}`, 'checked']}
                                    noStyle
                                    // rules={[{ required: true, message: 'Name is required' }]}
                                    valuePropName="checked"
                                    initialValue={ie.role === "INITIATOR"}
                                >
                                    <Checkbox style={{ lineHeight: '32px' }} disabled={ie.role === "INITIATOR"} onChange={event => employeeCheckboxChange(event, `internalEmployees_${ie.id}`)}>
                                        {ie.name ? `${ie.name} (${ie.role})` : `${ie.email} (${ie.role})`}
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span="5">
                                <Form.Item
                                    name={[`internalEmployees_${ie.id}`, 'permission']}
                                    noStyle
                                    // rules={[{ required: true, message: 'Permission is required' }]}
                                    initialValue={ie.role === "INITIATOR" ? "edit" : null}
                                >
                                    <Select placeholder="Select" valuePropName="checked" disabled={ie.role === "INITIATOR"} style={{ width: "130px" }} onChange={employeePermisionChange}
                                    >
                                        <Option value="view">View Only</Option>
                                        <Option value="upload">Upload Only</Option>
                                        <Option value="download">Download Only</Option>
                                        <Option value="edit">Edit</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>)} */}

                    {customers?.length > 0 && customers.map((c) => (
                        <Fragment key={c.id}>
                            <Form.Item>
                                <Input.Group compact>
                                    <Form.Item
                                        name={[`${CUSTOMERS}_${c.id}`, VALUE]}
                                        noStyle
                                        initialValue={c.name ? `${c.name} (${EXTERNAL_USER})` : `${c.email} (${EXTERNAL_USER})`}
                                    >
                                        <Input style={STYLES.READ_ONLY_INPUT} readOnly />
                                    </Form.Item>
                                    <Form.Item
                                        name={[`${CUSTOMERS}_${c.id}`, PERMISSION]}
                                        noStyle
                                        initialValue={PERMISSIONS.NONE}
                                    >
                                        <Select style={STYLES.SELECT}>
                                            <Option value={PERMISSIONS.NONE}>{PERMISSION_LABELS.NONE}</Option>
                                            {/* <Option value="view">View Only</Option> */}
                                            <Option value={PERMISSIONS.UPLOAD}>{PERMISSION_LABELS.UPLOAD}</Option>
                                            <Option value={PERMISSIONS.DOWNLOAD}>{PERMISSION_LABELS.DOWNLOAD}</Option>
                                            <Option value={PERMISSIONS.EDIT}>{PERMISSION_LABELS.EDIT}</Option>
                                        </Select>
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                        </Fragment>
                    ))}

                    {/* {customers && customers.length > 0 && customers.map((c, index) => <>
                        <Row style={{ paddingBottom: "5px" }}>
                            <Col span="18">
                                <Form.Item
                                    name={[`customers_${c.id}`, 'checked']}
                                    noStyle
                                    // rules={[{ required: true, message: 'Name is required' }]}
                                    valuePropName="checked"
                                >
                                    <Checkbox style={{ lineHeight: '32px' }} onChange={event => customerCheckboxChange(event, `customers_${c.id}`)}>
                                        {c.name ? `${c.name} (External User)` : `${c.email} (External User)`}
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span="5">
                                <Form.Item
                                    name={[`customers_${c.id}`, 'permission']}
                                    noStyle
                                // rules={[{ required: true, message: 'Permission is required' }]}
                                >
                                    <Select placeholder="Select" valuePropName="checked" style={{ width: "130px" }} onChange={customerPermisionChange}>
                                        <Option value="view">View Only</Option>
                                        <Option value="upload">Upload Only</Option>
                                        <Option value="download">Download Only</Option>
                                        <Option value="edit">Edit</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>)} */}

                    {errorMessageContent}
                </Form>
            </Spin>
        </Modal>
    );
};

export default Folder;

import { logout } from "../../redux/actions/userActions";
import store from "../../redux/store/store";
import { UI_ROUTES } from '../../utils/webConstants';
import { SESSION_STORAGE_KEYS } from '../../utils/appConstants';

const { EXPIRED_TIME } = SESSION_STORAGE_KEYS;

class IdleTimer {
    constructor({ timeout, onTimeout, onExpired }) {
        this.timeout = timeout;
        this.onTimeout = onTimeout;

        const expiredTime = parseInt(sessionStorage.getItem(EXPIRED_TIME), 10);

        if (expiredTime > 0 && expiredTime < Date.now()) {
            onExpired();
            return;
        }

        this.eventHandler = this.updateExpiredTime.bind(this);
        this.tracker();
        this.startInterval();
    }

    startInterval() {
        this.updateExpiredTime();

        this.interval = setInterval(() => {
            const expiredTime = parseInt(sessionStorage.getItem(EXPIRED_TIME), 10);

            if (expiredTime < Date.now()) {
                if (this.onTimeout) {
                    this.onTimeout();
                    this.cleanUp();
                }
            }
        }, 1000);
    }

    updateExpiredTime() {
        if (this.timeoutTracker) clearTimeout(this.timeoutTracker);

        this.timeoutTracker = setTimeout(() => {
            sessionStorage.setItem(EXPIRED_TIME, Date.now() + this.timeout * 1000);
        }, 300);
    }

    tracker() {
        window.addEventListener("mousemove", this.eventHandler);
        window.addEventListener("scroll", this.eventHandler);
        window.addEventListener("keydown", this.eventHandler);
    }

    cleanUp() {
        console.log("++logout++");
        clearInterval(this.interval);
        window.removeEventListener("mousemove", this.eventHandler);
        window.removeEventListener("scroll", this.eventHandler);
        window.removeEventListener("keydown", this.eventHandler);
        store.dispatch(logout());

        if (!window.location.href.toLowerCase().includes("login")) {
            window.location.href = UI_ROUTES.LOGIN;
        }
    }
}

export default IdleTimer;

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppHeader from "../components/AppHeader";
import { API_GET_APP_ACCESS, UI_ROUTES } from "../../utils/webConstants";
import AppFooter from "../components/AppFooter";
import axios from "../../utils/axios";
import { Alert, Layout } from "antd";

export default function NotAllowed() {
  let history = useHistory();
  const noAccess = 'Access Denied';

  const checkAppAccess = async () => {
    try {
      await axios.get(API_GET_APP_ACCESS);
      history.push(UI_ROUTES.BASE);
    }
    catch (error) {
      localStorage.clear();
      sessionStorage.clear();
      history.push(UI_ROUTES.NOT_ALLOWED);
    }
  };

  useEffect(() => {
    checkAppAccess();
  },[]);

  return (
    <Layout >
      <AppHeader />
      <Layout className="layoutStyle">
        <Alert
          message="Error" description={noAccess} type="error" showIcon closable
        />
        <AppFooter />
      </Layout>
    </Layout>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { useHistory } from 'react-router-dom';
import axios from "../../utils/axios";
import jwt_decode from "jwt-decode";
import assembler from "url-assembler";
import { useSelector } from "react-redux";
import { checkEmailIsInternal } from "../../utils/authUtils";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { UI_ROUTES, API_ROUTES } from "../../utils/webConstants";
import { Form, Input, message, Modal, Checkbox, TreeSelect } from "antd";

import {
    COMMENTS_MODAL_COMPONENT, REGEX_STRINGS, AXIOS_CONFIG_VALUES
} from "../../utils/appConstants";

const {
    ADD_IFO_PROP_TYPE, SELECT_FILE_FOR_COMMENT, SUCCESS_MESSAGES, ATTENTION,
    REJECT_DEACTIVATE_USER_REQUEST_PROP_TYPE, COMMENT_MAX_LENGTH, COMMENTS,
    NO_SPECIAL_CHARACTERS_IN_COMMENT, COMMENT_MAX_LENGTH_EXCEEDED, TITLES,
    APPROVE_PROP_TYPE, REJECT_PROP_TYPE, FILE_RETENTION_REJECT_PROP_TYPE,
    CONFIRM_PUBLISH_COMMENT_TO_CUSTOMER, PUBLISH_TO_CUSTOMER, PREFIXES,
    APPROVE_DEACTIVATE_USER_REQUEST_LABEL, COMMENT_CANNOT_BE_EMPTY,
    APPROVE_DEACTIVATE_USER_REQUEST_PROP_TYPE, SEND_BACK_PROP_TYPE,
} = COMMENTS_MODAL_COMPONENT;

const {
    ALLOW_LETTERS_NUMBERS_UNDERSCORE_PERIOD_COMMA_AND_HYPHEN
} = REGEX_STRINGS;

const {
    AUTHORIZATION, CONTENT_TYPE, APPLICATION_JSON, POST_METHOD
} = AXIOS_CONFIG_VALUES;

const CommentsModal = (props) => {
    let history = useHistory();

    const { userInfo } = useSelector((state) => state.userLogin);
    const userToken = userInfo?.userToken ?? '';

    const decodedToken = userToken !== "" ? jwt_decode(userToken) : {};
    const userEmail = decodedToken?.emailId ?? "";
    const isInternalEmail = checkEmailIsInternal(userEmail);

    const [form] = Form.useForm();
    const [isModalVisible, setIsCommentsModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState(TITLES.ADD_COMMENT);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isShowCustomer, setIsShowCustomer] = useState(null);
    const [folderTreeData, setFolderTreeData] = useState([]);

    const commentLabel = (props.type === APPROVE_DEACTIVATE_USER_REQUEST_PROP_TYPE)
        ? APPROVE_DEACTIVATE_USER_REQUEST_LABEL
        : COMMENTS;

    const commentRef = useRef();
    const [commentPrefix, setCommentPrefix] = useState(null);

    useEffect(() => {
        setSelectedFile(null);
        setIsShowCustomer(null);
        setIsCommentsModalVisible(props.isCommentsModalVisible);

        switch (props.type) {
            case SEND_BACK_PROP_TYPE:
                setModalTitle(TITLES.SEND_BACK_TO_INITIATOR);
                break;

            case ADD_IFO_PROP_TYPE:
                setModalTitle(TITLES.REQUEST_MORE_INFO);
                break;

            case APPROVE_PROP_TYPE:
                setModalTitle(TITLES.APPROVE_FILE);
                setCommentPrefix(PREFIXES.APPROVED);
                break;

            case REJECT_PROP_TYPE:
                setModalTitle(TITLES.REJECT_FILE);
                setCommentPrefix(PREFIXES.REJECTED);
                break;

            case FILE_RETENTION_REJECT_PROP_TYPE:
                setModalTitle(TITLES.REJECT_FILE_RETENTION_PERIOD);
                break;

            case APPROVE_DEACTIVATE_USER_REQUEST_PROP_TYPE:
                setModalTitle(TITLES.APPROVE_USER_DEACTIVATION);
                break;

            case REJECT_DEACTIVATE_USER_REQUEST_PROP_TYPE:
                setModalTitle(TITLES.REJECT_USER_DEACTIVATION);
                break;

            default:
                if (Number.isInteger(props.requestId) && Math.sign(props.requestId) === 1) {
                    getFolderHierarchy();
                }
        }
    }, [props.requestId, props.isCommentsModalVisible, props.type]);

    const handleOk = () => {
        form.validateFields()
        .then((values) => {
            const requestPayload = {
                serviceRequestId: props.requestId,
                description: values.comment,
                type: props.type
            };

            if (props.selectedFiles) {
                const fileIds = (props.selectedFiles).map((e) => e.id);

                requestPayload.fileIds = fileIds;
            }

            if (selectedFile) requestPayload.fileId = selectedFile;

            if (props.periodOfExtension) {
                requestPayload.periodOfExtension = props.periodOfExtension;
            }

            requestPayload.isPublishToCustomer = isShowCustomer ?? null;

            axios({
                method: POST_METHOD,
                url: API_ROUTES.SAVE_COMMENT,
                headers: {
                    [CONTENT_TYPE]: APPLICATION_JSON, [AUTHORIZATION]: userToken
                },
                data: JSON.stringify(requestPayload)
            })
            .then((response) => onCommentSubmitResponse(response))
            .catch((error) => {
                if (props?.setCanApproveModalInfoVisible)
                    props.setCanApproveModalInfoVisible(true);

                if (error?.response?.status === 403)
                    history.push(UI_ROUTES.BASE);

                console.error(error);
            });
        })
        .catch((errorInfo) => {
            console.error("When submitting a comment. ", errorInfo);
        });
    };

    const handleCancel = () => {
        setIsCommentsModalVisible(false);
        form.resetFields();
        props.onChange(props.type, "cancel");
    };

    function onCommentSubmitResponse(res) {
        if (res?.status === 201) {
            switch (props.type) {
                case SEND_BACK_PROP_TYPE:
                    message.success(SUCCESS_MESSAGES.SENT_BACK);
                    break;

                case ADD_IFO_PROP_TYPE:
                    message.success(SUCCESS_MESSAGES.MORE_INFO_REQUESTED);
                    break;

                case APPROVE_PROP_TYPE:
                    message.success(SUCCESS_MESSAGES.APPROVED_FILE);
                    break;

                case REJECT_PROP_TYPE:
                    message.success(SUCCESS_MESSAGES.REJECTED_FILE);
                    break;

                case APPROVE_DEACTIVATE_USER_REQUEST_PROP_TYPE:
                    message.success(SUCCESS_MESSAGES.APPROVED_USER_DEACTIVATION);
                    break;

                case REJECT_DEACTIVATE_USER_REQUEST_PROP_TYPE:
                    message.success(SUCCESS_MESSAGES.REJECTED_USER_DEACTIVATION);
                    break;

                default:
                    message.success(SUCCESS_MESSAGES.COMMENT_ADDED);
            }

            setIsCommentsModalVisible(false);
            form.resetFields();
            props.onChange(props.type, "submit");
        }
    }

    const onChange = value => setSelectedFile(value);

    const onShowCustomerCheckboxChange = (e) => {
        if (e.target.checked) {
            Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: ATTENTION,
                content: CONFIRM_PUBLISH_COMMENT_TO_CUSTOMER,
                centered: true,
                onOk() {
                    setIsShowCustomer(true);
                },
                onCancel() {
                    e.checked = false;
                    setIsShowCustomer(false);
                },
            });
        }
        else {
            setIsShowCustomer(false);
        }
    };

    const getFolderHierarchy = async () => {
        try {
            const urlParam = { id: props.requestId, type: "withFile" };
            const url = assembler(API_ROUTES.GET_FILE_HIERARCHY).param(urlParam);

            axios.get(url, { headers: { [AUTHORIZATION]: userToken } })
            .then(res => {
                const resultData = res?.data?.payload;

                function addLeafHelper(result) {
                    for (let i = 0; i < result.length; i++) {
                        const element = result[i];

                        if (element.folderId) {
                            if (element.children.length === 0) {
                                element['isLeaf'] = false;
                            }
                            else {
                                addLeafHelper(element.children);
                            }
                        }
                    }
                }

                addLeafHelper(resultData);

                const treeData = [{
                    title: "Home", value: "Home", selectable: false,
                    children: resultData
                }];

                setFolderTreeData(resultData?.length ? treeData : []);
            })
        }
        catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (isModalVisible) {
            // Auto focus cursor in comment field
            commentRef.current.focus({ cursor: 'end' });
        }
    }, [isModalVisible]);

    const commentValidationRules = [
        {
            required: true, whitespace: true, message: COMMENT_CANNOT_BE_EMPTY
        },
        {
            max: COMMENT_MAX_LENGTH, message: COMMENT_MAX_LENGTH_EXCEEDED
        },
        {
            pattern: new RegExp(ALLOW_LETTERS_NUMBERS_UNDERSCORE_PERIOD_COMMA_AND_HYPHEN),
            message: NO_SPECIAL_CHARACTERS_IN_COMMENT
        }
    ];

    const selectFileField = (
        <Form.Item>
            <TreeSelect
                treeLine={true} style={{ width: '100%' }} treeDefaultExpandAll
                value={selectedFile} treeData={folderTreeData} onChange={onChange}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }} allowClear
                placeholder={SELECT_FILE_FOR_COMMENT}
            />
        </Form.Item>
    );

    const selectFileFieldContent = (props.isShowFiles) ? selectFileField : null;

    const showCustomerField = (
        <Form.Item name="isShowCustomer">
            <Checkbox
                value={isShowCustomer} checked={isShowCustomer}
                onChange={onShowCustomerCheckboxChange}
            >
                {PUBLISH_TO_CUSTOMER}
            </Checkbox>
        </Form.Item>
    );

    const showCustomerFieldContent = (props.showCustomerCheckbox && isInternalEmail)
        ? showCustomerField
        : null;

    return (
        <Modal
            open={isModalVisible} title={modalTitle} okText="Submit"
            onCancel={handleCancel} onOk={handleOk} form="form"
        >
            <Form id="form" form={form} layout="vertical">
                {selectFileFieldContent}

                <Form.Item
                    label={commentLabel} name="comment"
                    rules={commentValidationRules} initialValue={commentPrefix}
                >
                    <Input.TextArea rows={4} ref={commentRef} />
                </Form.Item>

                {showCustomerFieldContent}
            </Form>
        </Modal>
    );
};

export default CommentsModal;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFormattedDateTime } from '../../utils/sharedUtils';
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { requestSelected } from "../../redux/actions/requestActions";
import RequestStatusTag from "../components/RequestStatusTag";
import Pagination from "../components/Pagination";
import EmptyTable from '../components/EmptyTable';
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import KeyConfig from "../components/KeyConfig";
import Table from "../components/Table";
import queryString from 'query-string';
import axios from "../../utils/axios";
import assembler from "url-assembler";
import moment from "moment-timezone";

import {
  checkEmailIsInternal, checkEmployeeIsAdmin
} from "../../utils/authUtils";

import {
  Tabs, Divider, Modal, Button, Spin, Layout, Input, message, Col, Row,
  DatePicker, Space, Popconfirm, Tooltip
} from "antd";

import {
  API_GET_REQUEST, API_DELETE_REQUEST, API_CLOSE_REQUEST, UI_ROUTES, API_ROUTES
} from "../../utils/webConstants";

import {
  SERVICE_REQUEST_COMPONENT, USERTYPE, LOGGED_IN_USER_TYPE, NAVIGATION_TYPE,
  SERVICE_REQUEST_TABS_STATUS, SERVICE_REQUEST_TABS, AXIOS_CONFIG_VALUES,
  REQUEST_SERVICE_COMPONENT, CONNECTION_TEST_MODAL_COMPONENT,
  INACTIVATE_REQUEST_COMPONENT
} from "../../utils/appConstants";

const {
  CONTENT_TYPE, APPLICATION_JSON, AUTHORIZATION, PUT_METHOD, GET_METHOD,
  BLOB_RESPONSE
} = AXIOS_CONFIG_VALUES;

const { RangePicker } = DatePicker;
const { confirm } = Modal;
const { Content } = Layout;
const { TabPane } = Tabs;

const ServiceRequest = (props) => {
  let location = useLocation();
  const queryParam = queryString.parse(location.search)
  const dispatch = useDispatch();
  let history = useHistory();

  const { userInfo } = useSelector((state) => state.userLogin);
  let userToken = "";

  if (userInfo) userToken = userInfo.userToken;
  else history.push(UI_ROUTES.LOGIN);

  const userEmail = userInfo.emailId;
  const employeeIsAdmin = checkEmployeeIsAdmin(userInfo);
  const authHeader = { [AUTHORIZATION]: userToken };

  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(queryParam.page ? parseInt(queryParam.page, 10) : 1);
  const [limit, setLimit] = useState(queryParam.limit ? parseInt(queryParam.limit, 10) : 10);
  const [total, setTotal] = useState(0);
  const params = useParams();
  const [type] = useState(params.type);
  const [selectedTab, setSelectedTab] = useState(type ? type : "active");
  //const [isCommentsModalVisible, setIsCommentsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortKey, setSortKey] = useState(null);
  const [search] = useState("");
  const [filterValues, setFilterValues] = useState(null);
  const [showKeyConfig, setShowKeyConfigs] = useState(false);
  const [selectedServiceRequestId, setSelectedServiceRequestId] = useState(null);
  const [selectedServiceRequestName, setSelectedServiceRequestName] = useState(null);
  const [tableIndex, setTableIndex] = useState(0);
  const [testConnectionPassedKey] = useState("testConnectionPassed");
  const [requestColumns, setRequestColumns] = useState([]);
  const [toolTipColorDarkBlue] = useState('#001529');

  const [serviceRequestTabs] = useState([
    SERVICE_REQUEST_TABS.COMPLETED, SERVICE_REQUEST_TABS.ACTIVE
  ]);

  const onAssignedToClick = (e, associatedUsers) => {
    e.preventDefault();

    Modal.info({
      title: SERVICE_REQUEST_COMPONENT.ASSIGNED_TO_TITLE,
      content: <div><p>{associatedUsers}</p></div>
    });
  };

  let loggedInUserType = "";

  if (userEmail !== "") {
    if (checkEmailIsInternal(userEmail)) {
      loggedInUserType = LOGGED_IN_USER_TYPE.EMPLOYEE;
    }
    else {
      loggedInUserType = LOGGED_IN_USER_TYPE.CUSTOMER;
    }
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        {dataIndex === "createdOn" || dataIndex === "modifiedOn" ?
          <RangePicker
            format={"MM-DD-YYYY"}
            ranges={{
              Today: [moment(), moment()],
              "This Month": [
                moment().startOf("month"),
                moment().endOf("month"),
              ],
            }}
            disabledDate={disabledDate}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e && e.length === 2 ? [e] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          : <Input
            // ref={node => {
            //   searchInput = node;
            // }}
            placeholder={`Search`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />}
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    // onFilter: (value, record) =>
    //   record[dataIndex]
    //     ? record[dataIndex]
    //     : '',
    onFilterDropdownVisibleChange: visible => {
      // if (visible) {
      //   setTimeout(() => searchInput.select(), 100);
      // }
    },
    render: text => text ?
      (
        text
      ) : null
  });

  // const taskColumn = {
  //   title: "Tasks",
  //   dataIndex: "name",
  //   key: "name",
  //   sorter: true,
  //   ...getColumnSearchProps('name'),
  //   filtered: filterValues && filterValues['name'] ? true : false,
  //   render: (text, record) => <Link to="#" onClick={(e) => navigateToRequestScreen(e, record.id, record.serviceRequestId, selectedTab, "", record.roleId)} >{text}</Link>,
  // };

  const assignedOnColumn = {
    title: "Assigned On",
    dataIndex: "createdOn",
    key: "createdOn",
    className: "formatDate",
    sorter: true,
    ...getColumnSearchProps('createdOn'),
    render: (createdOn) => getFormattedDateTime(createdOn)
  };

  const requestIdColumn = {
    title: "Request Id",
    dataIndex: "id",
    key: "id",
    sorter: true,
    width: 125
  };

  // const nameColumn = {
  //   title: "Request Name",
  //   dataIndex: "serviceRequest.name",
  //   key: "serviceRequest.name",
  //   sorter: true,
  //   ...getColumnSearchProps('serviceRequest.name')
  // };

  // const customerColumn = {
  //   title: "External Party Name",
  //   dataIndex: "serviceRequest.customerAccountName",
  //   key: "serviceRequest.customerAccountName",
  //   sorter: true,
  //   width: 110,
  //   ...getColumnSearchProps('serviceRequest.customerAccountName')
  // };

  // const descriptionColumn = {
  //   title: "Description",
  //   dataIndex: "serviceRequest.description",
  //   key: "serviceRequest.description",
  //   sorter: true,
  //   ...getColumnSearchProps('serviceRequest.description')
  // };

  const actionsColumn = {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render: (text, record) => getActionsElement(text, record),
    width: 150
    //width: selectedTab === "completed" ? 200 : 140
  };

  // const statusColumn = {
  //   title: "State",
  //   dataIndex: "serviceRequest.status",
  //   key: "serviceRequest.status",
  //   sorter: true,
  //   width: 120,
  //   ...getColumnSearchProps('serviceRequest.status')
  // };

  const requestStatusColumn = {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: true,
    //width: 180,
    ...getColumnSearchProps('status'),
    render: (status) => <RequestStatusTag status={status} />
    // filteredValue:null,
    // filteredValue: filterValues && filterValues["status"] ? filterValues["status"] : null,
  };

  // const requestCommentsColumn = {
  //   title: "Comments",
  //   dataIndex: "comment.description",
  //   key: "comment.description",
  //   ...getColumnSearchProps('comment.description'),
  //   render: (comments) => comments && comments.length > 0 ?
  //     comments.length < 20 ? <div>{comments}</div> : <Link to="#" onClick={(e) => commentClick(e, comments)} > {(comments.substring(0, 20) + "...")}</Link>
  //     : <></>
  // };

  // const commentsColumn = {
  //   title: "Comments",
  //   dataIndex: "comment.description",
  //   key: "comment.description",
  //   ...getColumnSearchProps('comment.description'),
  //   render: (comments) => comments && comments.length > 0 ?
  //     comments.length < 20 ? <div>{comments}</div> : <Link to="#" onClick={() => commentClick(comments)} > {(comments.substring(0, 20) + "...")}</Link>
  //     : <></>
  // };

  const employeeNamesColumn = {
    title: "Internal Users",
    dataIndex: "employees",
    key: "employees",
    width: 180,
    ...getColumnSearchProps('employees'),
    render: (text, record) => {
      return text.split(',').length > 5 ? (<Link to="#" onClick={(e) => onAssignedToClick(e, text)}>{text.split(",").slice(0, 2) + "..."}</Link>) : text
    },
  };

  const requestNameColumn = {
    title: "Request Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
    ...getColumnSearchProps('name'),
    render: (text, record) => <Link to="#" onClick={(e) => navigateToRequestScreen(e, 0, record.id, selectedTab, "", 0)} >{text}</Link>,
  };

  const customerNamesColumn = {
    title: "External Users",
    dataIndex: "customerAccountName",
    key: "customerAccountName",
    sorter: true,
    width: selectedTab === "completed" ? 200 : 110,
    ...getColumnSearchProps('customerAccountName'),
    // filteredValue: filterValues && filterValues["customerAccountName"] ? filterValues["customerAccountName"] : null,
  };

  const requestDescriptionColumn = {
    title: "Description",
    dataIndex: "description",
    key: "description",
    sorter: true,
    ...getColumnSearchProps('description'),
    render: (text, record) => getDescriptionElement(text)
  };

  const closedOnColumn = {
    title: "Closed On",
    dataIndex: "modifiedOn",
    key: "modifiedOn",
    className: "formatDate",
    sorter: true,
    ...getColumnSearchProps('modifiedOn'),
    render: (modifiedOn) => getFormattedDateTime(modifiedOn)
  }

  // const getTaskData = async (param) => {
  //   try {
  //     setIsLoading(true);
  //     let status = "";
  //     if (selectedTab === SERVICE_REQUEST_TABS.TASKS)
  //       status = SERVICE_REQUEST_TABS_STATUS.NOT_COMPLETED;
  //     let url;
  //     let filters = filterValues;
  //     url = assembler(API_ROUTES.GET_TASKS).query({
  //       page, limit, status: status,
  //       sortOrder: sortOrder, sortBy: sortKey,
  //       search,
  //       nameSearchText: filters && filters["name"] ? filters["name"][0] : "",
  //       requesNameSearchText: filters && filters["serviceRequest.name"] ? filters["serviceRequest.name"][0] : "",
  //       externalPartySearchText: filters && filters["serviceRequest.customerAccountName"] ? filters["serviceRequest.customerAccountName"][0] : "",
  //       descriptionSearchText: filters && filters["serviceRequest.description"] ? filters["serviceRequest.description"][0] : "",
  //       stateSearchText: filters && filters["serviceRequest.status"] ? filters["serviceRequest.status"][0] : "",
  //       commentSearchText: filters && filters["comment.description"] ? filters["comment.description"][0] : "",
  //       from: filters && filters["createdOn"] && filters["createdOn"][0] ? filters["createdOn"][0][0].toISOString().substring(0, 10) : "",
  //       to: filters && filters["createdOn"] && filters["createdOn"][0] ? filters["createdOn"][0][1].toISOString().substring(0, 10) : "",

  //     });

  //     const reqs = await axios.get(url, { headers: { authorization: userToken } });
  //     setTableData(reqs.data.payload);
  //     setTotal(reqs.data.total);
  //   } catch (error) {
  //   }
  //   setIsLoading(false);
  // };

  const getRequestData = async (param) => {
    try {
      setIsLoading(true);

      let status = "";
      let filters = filterValues;
      let dateRange;

      if (selectedTab === SERVICE_REQUEST_TABS.ACTIVE) {
        status = SERVICE_REQUEST_TABS_STATUS.NOT_CLOSED;

        dateRange = (filters && filters["createdOn"])
          ? filters["createdOn"]
          : null;
      }
      else {
        status = SERVICE_REQUEST_TABS_STATUS.CLOSED;

        dateRange = (filters && filters["modifiedOn"])
          ? filters["modifiedOn"]
          : null;
      }

      const url = assembler(API_GET_REQUEST).query({
        page, limit, status: status,
        sortOrder: sortOrder, sortBy: sortKey,
        searchAll: search,
        nameSearchText: filters && filters["name"] ? filters["name"][0] : "",
        // requesNameSearchText: filters && filters["serviceRequest.name"] ? filters["serviceRequest.name"][0] : "",
        // externalPartySearchText: filters && filters["serviceRequest.customerAccountName"] ? filters["serviceRequest.customerAccountName"][0] : "",
        // descriptionSearchText: filters && filters["serviceRequest.description"] ? filters["serviceRequest.description"][0] : "",
        // stateSearchText: filters && filters["serviceRequest.status"] ? filters["serviceRequest.status"][0] : "",
        requesNameSearchText: filters && filters["name"] ? filters["name"][0] : "",
        externalPartySearchText: filters && filters["customerAccountName"] ? filters["customerAccountName"][0] : "",
        descriptionSearchText: filters && filters["description"] ? filters["description"][0] : "",
        stateSearchText: filters && filters["status"] ? filters["status"][0] : "",
        commentSearchText: filters && filters["comment.description"] ? filters["comment.description"][0] : "",
        from: dateRange && dateRange[0] ? dateRange[0][0].toISOString().substring(0, 10) : "",
        to: dateRange && dateRange[0] ? dateRange[0][1].toISOString().substring(0, 10) : "",
        employeeSearchText:filters && filters["employees"] ? filters["employees"][0] : "",
      });

      const reqs = await axios.get(url, { headers: authHeader });

      setTableData(reqs.data.payload);
      setTotal(reqs.data.total);
    }
    catch (error) {
      console.error("In getRequestData(): ", error);
    }

    setIsLoading(false);
  };

  /*const commentClick = (e, text) => {
    e.preventDefault();

    Modal.info({
      title: 'Comments',
      content: (
        <div>
          <p>{text}</p>
        </div>
      )
    });
  };*/

  useEffect(() => {
    let columns = [];
    const currentTabIsActive = (selectedTab === SERVICE_REQUEST_TABS.ACTIVE);
    const currentTabIsCompleted = (selectedTab === SERVICE_REQUEST_TABS.COMPLETED);
    const userIsCustomer = (loggedInUserType === LOGGED_IN_USER_TYPE.CUSTOMER);

    /*if (selectedTab === SERVICE_REQUEST_TABS.TASKS) {
      setPage(1);
      setLimit(10);

      if (loggedInUserType === LOGGED_IN_USER_TYPE.CUSTOMER)
        columns.push(taskColumn, nameColumn, actionsColumn, descriptionColumn, statusColumn, assignedOnColumn, commentsColumn);
      else
        columns.push(taskColumn, nameColumn, actionsColumn, customerColumn, descriptionColumn, statusColumn, assignedOnColumn, commentsColumn);
    }*/

    if (currentTabIsActive && userIsCustomer) {
      columns.push(
        requestIdColumn, requestNameColumn, actionsColumn,
        requestDescriptionColumn, requestStatusColumn, assignedOnColumn
      );
    }
    else if (currentTabIsActive && !userIsCustomer) {
      columns.push(
        requestIdColumn, requestNameColumn, actionsColumn, customerNamesColumn,
        requestDescriptionColumn, requestStatusColumn, assignedOnColumn,
        employeeNamesColumn
      );
    }
    else if (currentTabIsCompleted) {
      columns.push(
        requestIdColumn, requestNameColumn, actionsColumn, customerNamesColumn,
        requestDescriptionColumn, closedOnColumn
      );
    }

    setPage(parseInt(page, 10));
    setLimit(parseInt(limit, 10));
    setRequestColumns(columns);
  }, [selectedTab, tableIndex, location]);

  useEffect(() => {
    if (serviceRequestTabs.includes(selectedTab)) {
      getRequestData({
        sortOrder,
        sortKey
      });
    }
    // else {
    //   getTaskData({ sortOrder, sortKey });
    // }
  }, [page, limit, selectedTab, filterValues, sortOrder, sortKey, tableIndex]);

  useEffect(() => {
    setTableData([]);
    setSortKey(null);
    setSortOrder(null);
    setFilterValues(null);
    setSelectedTab(params.type === "active" ? "active" : params.type === "completed" ? "completed" : "active");
    // setTableIndex(tableIndex + 1);
    setPage(queryParam.page ? parseInt(queryParam.page, 10) : 1);
    setLimit(queryParam.limit ? parseInt(queryParam.limit, 10) : 10);
  }, [location]);

  const testConnection = () => {
    axios({
      method: GET_METHOD,
      responseType: BLOB_RESPONSE,
      url: API_ROUTES.TEST_CONNECTION,
      headers: authHeader
    })
    .then((response) => {
      response = null;
      sessionStorage.setItem(testConnectionPassedKey, true);
    })
    .catch((error) => {
      sessionStorage.setItem(testConnectionPassedKey, false);
      console.error("In testConnection(): ", error);
      const { WIDTH, TITLE, CONTENT } = CONNECTION_TEST_MODAL_COMPONENT;

      Modal.warning({
        width: WIDTH,
        title: TITLE,
        content: CONTENT
      });
    });
  };

  useEffect(() => {
    const testConnectionPassedValue = sessionStorage.getItem(testConnectionPassedKey);

    if (!testConnectionPassedValue) testConnection();
  }, []);

  const callback = (key) => {
    if (key === "completed") history.push("/requests/completed?page=1&limit=10");
    else history.push("/requests/active?page=1&limit=10");
    // setTableData([]);
    // setSortKey(null);
    // setSortOrder(null);
    // setFilterValues(null);
    // setSelectedTab(key);
    // setTableIndex(tableIndex + 1);
  };

  const onPaginate = (page, pageSize) => {
    setPage(page);
    setLimit(pageSize);

    if (selectedTab === "completed" || selectedTab === "active") {
      history.push(`/requests/${selectedTab}?page=${page}&limit=${pageSize}`);
    }
    else {
      history.push(UI_ROUTES.BASE);
    }
  };

  function navigateToRequestScreen(e, taskId, requestId, selectedTab, navigationType, roleId) {
    e.preventDefault();

    dispatch(
      requestSelected(
        requestId, taskId, selectedTab, roleId, queryParam.page, queryParam.limit
      )
    );

    const url = (navigationType !== "")
      ? '/service-request/' + navigationType
      : '/service-request';

    history.push(url);
  }

  const deleteRequest = (e, requestId) => {
    e.preventDefault();

    confirm({
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this file transfer request?',
      onOk() {
        axios({
          method: PUT_METHOD,
          url: API_DELETE_REQUEST + "/" + requestId,
          headers: authHeader
        })
        .then((response) => {
          if (response?.status === 200) {
            message.success(INACTIVATE_REQUEST_COMPONENT.DELETE_SUCCESS_MESSAGE);
            getRequestData();
          }
        });
      }
    });
  };

  const onKeysClickNavigation = (e, serviceRequestId, serviceRequestName) => {
    e.preventDefault();
    setShowKeyConfigs(true);
    setSelectedServiceRequestId(serviceRequestId);
    setSelectedServiceRequestName(serviceRequestName);
  };

  const getDescriptionElement = (description) => {
    const shortDescriptionMaxSize = 40;
    const partialDescriptionMaxSize = 80;
    const descriptionSize = description.length;

    if (descriptionSize > shortDescriptionMaxSize) {
      const shortDescription = `${description.slice(0, shortDescriptionMaxSize)}...`;

      const partialDescription = (descriptionSize > partialDescriptionMaxSize)
        ? `${description.slice(0, partialDescriptionMaxSize)}...`
        : description;

      return (
        <>
          <Tooltip
            title={partialDescription}
            color={toolTipColorDarkBlue}
          >
            {shortDescription}
          </Tooltip>
        </>
      );
    }
    else {
      return description;
    }
  };

  const DownloadLinkInnerComponent = ({hasDownloadableFiles}) => {
    const downloadLinkText = 'Download';

    if (hasDownloadableFiles) {
      return downloadLinkText;
    }
    else {
      return (
        <>
          <Tooltip
            title={
              `Files in this Service Request are past their retention date
              and have been automatically deleted. New files can be added.`
            }
            color={toolTipColorDarkBlue}
          >
            {downloadLinkText}
          </Tooltip>
        </>
      );
    }
  };

  const getActionsElement = (e, record) => {
    let employeeType = "";

    if (loggedInUserType === LOGGED_IN_USER_TYPE.EMPLOYEE) {
      let createdBy = (serviceRequestTabs.includes(selectedTab))
        ? record.createdBy
        : record["serviceRequest.createdBy"];

      if (userEmail === createdBy) employeeType = USERTYPE.INITIATOR;
    }

    return (
      <>
        <div>
          {/*
            selectedTab === SERVICE_REQUEST_TABS.TASKS ? <>
              <Link to="#" onClick={() => navigateToRequestScreen(record.id, record.serviceRequestId, selectedTab, "", record.roleId)} >Upload</Link>&nbsp;|&nbsp;
              <Link to="#" onClick={() => navigateToRequestScreen(record.id, record.serviceRequestId, selectedTab, NAVIGATION_TYPE.DOWNLOAD, record.roleId)} disabled={record.fileCount === 0} >Download</Link>
              {loggedInUserType === LOGGED_IN_USER_TYPE.CUSTOMER ? <>&nbsp;|&nbsp;<Link to="#" onClick={() => sendBack(record.id, record.serviceRequestId)} >Send Back</Link></> : null}
              {record.roleId === 1 ? <>|<Link to="#" onClick={() => navigateToRequestScreen(record.id, record.serviceRequestId, selectedTab, NAVIGATION_TYPE.CLOSEPROJECT, record.roleId)}>Close File Transfer</Link></> : null}
            </> : null
          */}

          {
            (selectedTab === SERVICE_REQUEST_TABS.ACTIVE)
              ? <>
                  <Row>
                    <Col span="24">
                      {
                        (loggedInUserType === USERTYPE.CUSTOMER)
                          ? (record?.isCustomerFileUpload === 1)
                            ? (<><Link to="#" onClick={(e) => navigateToRequestScreen(e, 0, record.id, selectedTab, "", 0)} >Upload</Link>&nbsp;|&nbsp;</>)
                            : ''
                          : (<><Link to="#" onClick={(e) => navigateToRequestScreen(e, 0, record.id, selectedTab, "", 0)} >Upload</Link>&nbsp;|&nbsp;</>)
                      }

                      <Link
                        to="#"
                        onClick={(e) => navigateToRequestScreen(e, 0, record.id, selectedTab, NAVIGATION_TYPE.DOWNLOAD, 0)}
                        disabled={!record.isDownload}
                      >
                        <DownloadLinkInnerComponent hasDownloadableFiles={record.isDownload} />
                      </Link>
                    </Col>

                    {
                      record.showKeyActionItem &&
                      <Col span="24">
                        <Link
                          to="#"
                          onClick={(e) => onKeysClickNavigation(e, record.id, record.name)} disabled={!record.showKeyActionItem}
                        >
                          &nbsp;|&nbsp;Keys
                        </Link>
                      </Col>
                    }

                    {
                      (employeeType === USERTYPE.INITIATOR)
                        ? <Col span="24">
                            <>
                              &nbsp;|&nbsp;
                              <Popconfirm
                                title={REQUEST_SERVICE_COMPONENT.CLOSE_REQUEST_CONFIRM_MESSAGE}
                                onConfirm={() => onCloseProjectClick(record.id)}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Link to="#" >Close File Transfer</Link>
                              </Popconfirm>
                            </>
                          </Col>
                        : null
                    }

                    {
                      (employeeIsAdmin)
                        ? <Col span="24">
                            <>
                              &nbsp;|&nbsp;
                              <Link to="#" onClick={(e) => deleteRequest(e, record.id)}>
                                Delete File Transfer
                              </Link>
                            </>
                          </Col>
                        : null
                    }
                  </Row>
                </>
              : null
          }

          {
            (selectedTab === SERVICE_REQUEST_TABS.COMPLETED)
              ? <>
                  <Link
                    to="#"
                    onClick={(e) => navigateToRequestScreen(e, 0, record.id, selectedTab, NAVIGATION_TYPE.DOWNLOAD, 0)}
                    disabled={!record.isDownload}
                  >
                    <DownloadLinkInnerComponent hasDownloadableFiles={record.isDownload} />
                  </Link>
                  {
                    (employeeType === USERTYPE.INITIATOR)
                      ? <>
                          &nbsp;|&nbsp;
                          <Link
                            to="#"
                            onClick={(e) => navigateToRequestScreen(e, 0, record.id, selectedTab, NAVIGATION_TYPE.REOPEN, 0)}
                          >
                            Reopen
                          </Link>
                        </>
                      : null
                  }
                </>
              : null
          }
        </div>
      </>
    );
  };

  /*const CommentsModalSubmit = () => {
    setIsCommentsModalVisible(false);
    let status = "";
    // if (selectedTab === SERVICE_REQUEST_TABS.TASKS)
    //   status = SERVICE_REQUEST_TABS_STATUS.NOT_COMPLETED;

    if (selectedTab === SERVICE_REQUEST_TABS_STATUS.ACTIVE) status = SERVICE_REQUEST_TABS_STATUS.COMPLETED;

    //getTaskData({ page, limit, status });
  };*/

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(page);
    setLimit(limit);
    setFilterValues(filters);
    const sortingOrder = sorter.order === "descend" ? "desc" : "asc";
    setSortOrder(sortingOrder);
    setSortKey(sorter.field);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    const minDate = moment();
    return current >= minDate;
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => confirm();

  const handleReset = (clearFilters, dataIndex) => clearFilters();

  const resetAll = () => {
    setTableIndex(tableIndex + 1);
    setFilterValues(null);
    history.push(`/requests/${selectedTab}?page=1&limit=10`);
  };

  const onCloseProjectClick = (id) => {
    setIsLoading(true);

    axios({
      method: PUT_METHOD,
      url: API_CLOSE_REQUEST,
      data: JSON.stringify({ requestId: id }),
      headers: { [CONTENT_TYPE]: APPLICATION_JSON, [AUTHORIZATION]: userToken }
    })
    .then((response) => {
      if (response?.status === 200) {
        message.success(REQUEST_SERVICE_COMPONENT.REQUEST_CLOSED_SUCCESS_MESSAGE);
        // history.push('/requests/' + type);
        getRequestData({ sortOrder, sortKey });
      }
      // setIsLoading(false);
    });
  };

  const pageTitle = (
    <Divider
      orientation="left" className="dividerHeading" style={{ marginTop: 0 }}
    >
      Requests
    </Divider>
  );

  const resetFiltersButton = (
    <div style={{ paddingBottom: "5px" }}>
      <Button type="primary" onClick={() => resetAll()}>Reset Filters </Button>
    </div>
  );

  const resetFiltersButtonContent = (filterValues) ? resetFiltersButton : null;

  return (
    <Layout>
      <AppHeader userName={userInfo.name} userEmail={userEmail} />
      <Layout className="layoutStyle">
        <Layout>
          <Content>
            {showKeyConfig && <KeyConfig
              showKeyConfig={showKeyConfig}
              setShowKeyConfigs={setShowKeyConfigs}
              serviceRequestId={selectedServiceRequestId}
              setSelectedServiceRequestId={setSelectedServiceRequestId}
              selectedServiceRequestName={selectedServiceRequestName}
              setSelectedServiceRequestName={setSelectedServiceRequestName}
            />}
            <div
              className="site-layout-content contentStyle"
              style={{ background: "#fff", padding: '20px 25px 20px 25px' }}
            >
              {pageTitle}
              <div>
                <p>{loggedInUserType !== USERTYPE.CUSTOMER ? SERVICE_REQUEST_COMPONENT.CUSTOMER_TEXT :
                  SERVICE_REQUEST_COMPONENT.EMPLOYEE_TEXT}</p>
              </div>
              <Spin spinning={isLoading} tip="Loading..." size="large">
                {loggedInUserType === USERTYPE.EMPLOYEE &&
                  <div align="right">
                    &nbsp;&nbsp;
                    <Button type="primary">
                      <Link to="#" onClick={(e) => navigateToRequestScreen(e, "", "", "", "")}>Create New File Transfer Request</Link>
                    </Button>
                  </div>
                }
                <Tabs activeKey={selectedTab} onChange={callback}>
                  {/* <TabPane tab="Tasks" key="tasks">
                    <div key="tasks">
                      <Table
                        columns={requestColumns}
                        dataSource={tableData}
                        pagination={false}
                        bordered={true}
                        handleTableChange={handleTableChange}
                        locale={{ emptyText: <EmptyTable text="No Tasks" /> }}
                      />
                      <div align={"center"}>
                        <Pagination
                          total={total}
                          defaultPageSize={10}
                          defaultCurrent={page}
                          onChange={onPaginate}
                        />
                      </div>
                    </div>
                  </TabPane> */}

                  <TabPane tab="Active Requests" key="active">
                    <div key="active">
                      {resetFiltersButtonContent}
                      <Table
                        columns={requestColumns}
                        dataSource={tableData}
                        handleTableChange={handleTableChange}
                        locale={{ emptyText: <EmptyTable text="No Active Requests" /> }}
                        key={tableIndex}
                      />
                      <div align={"center"}>
                        <Pagination
                          total={total}
                          defaultPageSize={limit}
                          defaultCurrent={page}
                          onChange={onPaginate}
                        />
                      </div>
                    </div>
                  </TabPane>

                  <TabPane tab="Completed Requests" key="completed">
                    <div key="completed">
                      {resetFiltersButtonContent}
                      <Table
                        columns={requestColumns}
                        dataSource={tableData}
                        rowKey="id"
                        handleTableChange={handleTableChange}
                        locale={{ emptyText: <EmptyTable text="No Completed Requests" /> }}
                        key={tableIndex}
                      />
                      <div align={"center"}>
                        <Pagination
                          total={total}
                          defaultPageSize={limit}
                          defaultCurrent={page}
                          onChange={onPaginate}
                        />
                      </div>
                    </div>
                  </TabPane>

                </Tabs>
              </Spin>
              {/* <CommentsModal requestId={selectedRequestId} taskId={selectedTaskId} type={"sendBack"} isCommentsModalVisible={isCommentsModalVisible} onChange={CommentsModalSubmit} /> */}

            </div>
          </Content>
          <AppFooter />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ServiceRequest;

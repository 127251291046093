import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "../../utils/axios";
import assembler from "url-assembler";
import Table from "../components/Table";
import Pagination from "../components/Pagination";
import EmptyTable from '../components/EmptyTable';
import { Modal, Form, Input, Button, Popconfirm, message } from "antd";
import { API_ROUTES } from "../../utils/webConstants";

import {
    PlusCircleOutlined, DeleteOutlined, InfoCircleOutlined
} from "@ant-design/icons";

import {
    FILE_DATATYPE_COMPONENT, AXIOS_CONFIG_VALUES
} from "../../utils/appConstants";

const {
    AUTHORIZATION, PUT_METHOD, POST_METHOD, CONTENT_TYPE, APPLICATION_JSON
} = AXIOS_CONFIG_VALUES;

const {
    FILE_DATATYPE_CANNOT_BE_EMPTY, DESCRIPTION_MESSAGE, ADD_SUCCESS_MESSAGE,
    DELETE_SUCCESS_MESSAGE, DELETE_CONFIRM_MESSAGE, ADD_FILE_DATA_TYPE
} = FILE_DATATYPE_COMPONENT;

const FileDataTypes = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [form] = Form.useForm();

    const { userInfo } = useSelector((state) => state.userLogin);
    const userToken = (userInfo) ? userInfo.userToken : '';

    const columns = [
        {
            title: "File Data Types ", dataIndex: "fileDataType",
            key: "fileDataType",
        },
        {
            title: "Actions", dataIndex: "actions", key: "actions", width: 230,
            render: (text, record) => confirmDelete(record.id)
        }
    ];

    const getData = async () => {
        try {
            const url = assembler(API_ROUTES.GET_FILE_DATA_TYPES).query({
                page, limit
            });

            const res = await axios.get(url, {
                headers: { [AUTHORIZATION]: userToken }
            });

            setTableData(res.data.payload);
            setTotal(res.data.total);
        }
        catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getData({ page, limit });
    }, [page, limit]);

    const onDeleteClick = (id) => {
        axios({
            method: PUT_METHOD,
            url: API_ROUTES.DELETE_FILE_DATA_TYPE + "/" + id,
            headers: { [AUTHORIZATION]: userToken }
        })
        .then((response) => {
            if (response?.status === 200) {
                message.success(DELETE_SUCCESS_MESSAGE);
                getData({ page, limit });
            }
        });
    };

    function confirmDelete(id) {
        return (
            <div className="deleteAction">
                <Popconfirm
                    okText="Yes" cancelText="No"
                    title={DELETE_CONFIRM_MESSAGE}
                    onConfirm={() => onDeleteClick(id)}
                    icon={<InfoCircleOutlined style={{ color: 'red' }} />}
                >
                    <Link to="#" style={{ color: "red" }} >
                        <DeleteOutlined style={{ color: "red", height: "17px" }} />
                        &nbsp;Delete
                    </Link>
                </Popconfirm>
            </div>
        );
    }

    const handleOk = () => {
        form.validateFields()
        .then((values) => {
            axios({
                method: POST_METHOD,
                url: API_ROUTES.SAVE_FILE_DATA_TYPES,
                headers: {
                    [CONTENT_TYPE]: APPLICATION_JSON, [AUTHORIZATION]: userToken
                },
                data: JSON.stringify({ fileDataType: values.fileDataType })
            })
            .then((response) => {
                if (response?.status === 201) {
                    message.success(ADD_SUCCESS_MESSAGE);
                    setIsModalOpen(false);
                    getData({ page, limit });
                    form.resetFields();
                }
            })
            .catch((error) => {
                if (error.response.data.code === 1002) {
                    message.error(error.response.data.message);
                    form.resetFields();
                }
                else {
                    message.error('Failed to save file data type');
                }

                setIsModalOpen(false);
            })
        })
        .catch((errorInfo) => console.error(errorInfo));
    };

    const handleCancel = () => {
        form.resetFields();
        setIsModalOpen(false);
    };

    const handlePaginationChange = (page, pageSize) => {
        setPage(page);
        setLimit(pageSize);
    };

    return (
        <>
            <Modal
                open={isModalOpen} title={ADD_FILE_DATA_TYPE} okText="Submit"
                form="dataTypeForm" onCancel={handleCancel} onOk={handleOk}
            >
                <Form id="dataTypeForm" form={form} layout="vertical">
                    <Form.Item
                        label="File Data Type" name="fileDataType"
                        rules={[{
                            required: true,
                            message: FILE_DATATYPE_CANNOT_BE_EMPTY,
                        }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <div align="right" style={{ paddingBottom: "5px" }}>
                <Button
                    type="primary" icon={<PlusCircleOutlined />}
                    onClick={() => setIsModalOpen(true)}
                >
                    {ADD_FILE_DATA_TYPE}
                </Button>
            </div>
            <Table
                columns={columns} dataSource={tableData}
                locale={{ emptyText: <EmptyTable text={DESCRIPTION_MESSAGE}/> }}
            />
            <div align={"center"}>
                <Pagination
                    total={total} defaultPageSize={10} defaultCurrent={1}
                    onChange={handlePaginationChange}
                />
            </div>
        </>
    );
};

export default FileDataTypes;

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkEmailIsExternal } from "../utils/authUtils";
import { UI_ROUTES } from '../utils/webConstants';

export default function InternalUserRoute({ children, ...rest }) {
    const baseRedirect = <Redirect to={UI_ROUTES.BASE} />;
    const { userInfo } = useSelector((state) => state.userLogin);

    if (!userInfo) return baseRedirect;

    const { emailId, userToken } = userInfo;
    const isExternalUser = checkEmailIsExternal(emailId);

    return (userToken && !isExternalUser) ? <Route {...rest} /> : baseRedirect;
}

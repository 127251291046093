import React, { useState, useEffect } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import {
    Form,
    Row,
    Col,
    Input,
    Button,
    Modal,
    Spin,
} from "antd";
import axios from "../../utils/axios";
import assembler from "url-assembler";
import CopyToClipboard from "react-copy-to-clipboard";
import { API_GET_KEY_CONFIG } from "../../utils/webConstants";
import { useSelector } from "react-redux";


const KeyConfig = ({ showKeyConfig, setShowKeyConfigs, serviceRequestId, setSelectedServiceRequestId, selectedServiceRequestName, setSelectedServiceRequestName }) => {

    const loginedUser = useSelector((state) => state.userLogin);
    let userToken = "";
    if (loginedUser.userInfo)
        userToken = loginedUser.userInfo.userToken
    const [loading, setLoading] = useState(true);
    const [ivCopied, setIvCopied] = useState(false);
    const [secretCopied, setSecretCopied] = useState(false);
    const [iv, setIV] = useState(null);
    const [secretKey, setSecretKey] = useState(null);
    const [keyForm] = Form.useForm();

    const handleModalCancel = () => {
        setShowKeyConfigs(false);
        setSelectedServiceRequestId(null);
        setSelectedServiceRequestName(null);
    };

    const getKeyConfig = async (serviceRequestId) => {
        try {
            setLoading(true);
            let url = assembler(API_GET_KEY_CONFIG).param({ serviceRequestId });
            const response = await axios.get(url, { headers: { authorization: userToken } });
            keyForm.setFieldsValue({ iv: response.data.payload.encryptedIv })
            keyForm.setFieldsValue({ secretkey: response.data.payload.encryptedSecret })
            setLoading(false);
            setSecretKey(response.data.payload.encryptedSecret);
            setIV(response.data.payload.encryptedIv);
        } catch (error) {
            console.log("Error :: ", error.message);
        }
    };


    useEffect(() => {
        getKeyConfig(serviceRequestId);
    }, []);

    return (
        <Modal
            open={showKeyConfig}
            title={selectedServiceRequestName}
            form="keyForm"
            onCancel={handleModalCancel}
            footer={[
                <Button key="back" onClick={handleModalCancel}>
                  Cancel
                </Button>,
              ]}
        >
            <Spin spinning={loading} tip="Loading...">
                <Form id="dataTypeForm" form={keyForm} layout="horizontal">
                    <Row>
                        <Col span='20'>
                            <Form.Item
                                name="iv"
                                label="IV"
                                labelCol={{span: 5}}
                            >
                                <Input.Password
                                    id="iv"
                                    readOnly
                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <CopyToClipboard text={iv} onCopy={() => setIvCopied(!ivCopied)}>
                                <Button style={{ width: '100%' }}>{ivCopied ? 'Copied' : 'Copy'}</Button>
                            </CopyToClipboard>
                        </Col>
                    </Row>

                    <Row>
                        <Col span='20'>
                            <Form.Item
                                name="secretkey"
                                label="Secret Key"
                                labelCol={{span: 5}}
                            >
                                <Input.Password
                                    id="secretkey"
                                    readOnly
                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <CopyToClipboard text={secretKey} onCopy={() => setSecretCopied(!secretCopied)}>
                                <Button style={{ width: '100%' }}>{secretCopied ? 'Copied' : 'Copy'}</Button>
                            </CopyToClipboard>
                        </Col>
                    </Row>


                </Form>
            </Spin>
        </Modal>
    );
};
export default KeyConfig;

import { Route, Switch, useHistory } from "react-router-dom";
import ServiceRequest from "./pages/service-requests/ServiceRequest";
import RequestService from "./pages/service-request/RequestService";
import Settings from "./pages/settings/Settings";
import Login from "./pages/login/LoginPage";
import ForgotPassword from "./pages/login/ForgotPassword";
import ResetPassword from "./pages/login/ResetPassword";
import Registration from "./pages/login/Registration";
import NotAllowed from "./pages/login/NotAllowed";
import VerifyEmail from "./pages/login/VerifyEmail";
import { useDispatch, useSelector } from "react-redux";
import { login } from "./redux/actions/userActions";
import { useEffect, useState } from "react";
import UserRoute from "./routes/UserRoute";
import InternalUserRoute from "./routes/InternalUserRoute";
import axios from "./utils/axios";
import { API_GET_APP_ACCESS, UI_ROUTES } from "./utils/webConstants";
import { requestSelected } from "./redux/actions/requestActions";
import IdleTimer from "./pages/components/IdleTimer";

import {
  msalApp, requiresInteraction, AUTH_REQUESTS
} from './utils/authUtils';

import {
  USER_INACTIVE_TIMEOUT_TIME, SESSION_STORAGE_KEYS
} from "./utils/appConstants";

const { REQ_URL, MSAL_TOKEN } = SESSION_STORAGE_KEYS;

// buffer needs a reference browser side
const Buffer = require('buffer/').Buffer

const App = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [isTimeout, setIsTimeout] = useState(false);

  if (history?.location?.pathname.includes(UI_ROUTES.REQUEST_DETAIL_PATH)) {
    const pathValues = history.location.pathname.split(UI_ROUTES.BASE);

    if (pathValues[2]) {
      const decoded = Buffer.from(pathValues[2], "base64").toString("utf8");
      dispatch(requestSelected(decoded, 0, '', 0));
    }

    sessionStorage.setItem(REQ_URL, history.location.pathname);
  }

  const { userInfo } = useSelector((state) => state.userLogin);

  const checkAppAccess = async () => {
    try {
      await axios.get(API_GET_APP_ACCESS);
    }
    catch (error) {
      localStorage.clear();
      sessionStorage.clear();
      history.push(UI_ROUTES.NOT_ALLOWED);
    }
  };

  useEffect(() => {
    checkAppAccess();

    if (!userInfo?.userToken) {
      msalApp.handleRedirectCallback((error) => {
        if (error) console.log('Unable to acquire access token:', error);
      });

      const account = msalApp.getAccount();
      const now = Math.round(new Date().getTime() / 1000);

      if (account?.idToken.exp > now) {
        dispatch(
          login(account.userName, sessionStorage.getItem(MSAL_TOKEN))
        );
      }
      else {
        acquireToken(AUTH_REQUESTS.REFRESH_TOKEN);
      }
    }

    const timer = new IdleTimer({
      timeout: USER_INACTIVE_TIMEOUT_TIME,
      onTimeout: () => {
        setIsTimeout(true);
      },
      onExpired: () => {
        setIsTimeout(true);
      }
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  const acquireToken = async (request) => {
    try {
      const loginResponse = await msalApp.acquireTokenSilent(request);

      if (loginResponse) {
        dispatch(
          login(loginResponse.account.userName, sessionStorage.getItem(MSAL_TOKEN))
        );
      }
    }
    catch (error) {
      if (requiresInteraction(error.errorCode)) history.push(UI_ROUTES.LOGIN);
    }
  };

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgotPwd" component={ForgotPassword} />
      <Route exact path="/registration" component={Registration} />
      <Route exact path="/not-allowed" component={NotAllowed} />
      <Route exact path="/user/verify/:hash" component={VerifyEmail} />
      <UserRoute exact path="/resetPwd" component={ResetPassword} />
      <UserRoute exact path="/" component={ServiceRequest} />
      <UserRoute exact path="/requests/:type" component={ServiceRequest} />
      <UserRoute exact path="/service-request" component={RequestService} />
      <UserRoute exact path="/service-request/:navType" component={RequestService} />
      <UserRoute exact path="/requestDetail/:requestId" component={RequestService} />
      <UserRoute exact path="/requestDetail/:requestId/:type/:fileId" component={RequestService} />
      <UserRoute exact path="/requestDetail/:requestId/:type" component={RequestService} />
      <InternalUserRoute exact path="/settings" component={Settings} />
    </Switch>
  );
};

export default App;

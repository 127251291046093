import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { Button, Drawer, Space } from 'antd';
import { useHistory } from 'react-router-dom';
import { msalApp } from "../../utils/authUtils";
import { UI_ROUTES } from '../../utils/webConstants';
import { logout } from "../../redux/actions/userActions";
import { openUrlInNewTab } from '../../utils/sharedUtils';
import { USER_GUIDE_URL, MAIN_MENU_COMPONENT } from '../../utils/appConstants';

import {
  MenuOutlined, DesktopOutlined, SettingOutlined, QuestionCircleOutlined,
  LogoutOutlined
} from '@ant-design/icons';

const { TITLE, ITEMS, STYLES } = MAIN_MENU_COMPONENT;

export default function MainMenu({ isExternal, isSmallDevice }) {
  let history = useHistory();
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  const openMenuLabel = (isSmallDevice) ? null : TITLE;

  const userGuideUrl = (!isExternal)
    ? USER_GUIDE_URL.INTERNAL
    : USER_GUIDE_URL.EXTERNAL;

  const toggleMenu = () => setOpenMenu(prev => !prev);

  const goToRequests = () => history.push(UI_ROUTES.BASE);

  const goToSettings = () => {
    history.push(UI_ROUTES.SETTINGS);
    toggleMenu();
  };

  const goToUserGuide = () => {
    openUrlInNewTab(userGuideUrl);
    toggleMenu();
  };

  const logoutUser = () => {
    if (!isExternal) {
      localStorage.clear();
      sessionStorage.clear();
      msalApp.logout();
    }

    dispatch(logout());
    window.location.href = UI_ROUTES.LOGIN;
  };

  const buttons = {
    openMenu: (
      <Button
        onClick={toggleMenu} icon={<MenuOutlined style={STYLES.ICON} />}
        size='large'
      >
        {openMenuLabel}
      </Button>
    ),
    logout: (
      <Button
        onClick={logoutUser} icon={<LogoutOutlined style={STYLES.ICON} />}
        type='primary' size='large' block danger
      >
        {ITEMS.LOGOUT}
      </Button>
    ),
    requests: (
      <Button
        onClick={goToRequests} icon={<DesktopOutlined style={STYLES.ICON} />}
        size='large' block
      >
        {ITEMS.REQUESTS}
      </Button>
    ),
    settings: (
      <Button
        onClick={goToSettings} icon={<SettingOutlined style={STYLES.ICON} />}
        size='large' block disabled={isExternal}
      >
        {ITEMS.SETTINGS}
      </Button>
    ),
    userGuide: (
      <Button
        onClick={goToUserGuide} size='large' block
        icon={<QuestionCircleOutlined style={STYLES.ICON} />}
      >
        {ITEMS.USER_GUIDE}
      </Button>
    )
  };

  return (
    <>
      {buttons.openMenu}
      <Drawer
        open={openMenu} width={200} placement='right' onClose={toggleMenu}
        closable={false} title={TITLE} headerStyle={STYLES.HEADER}
        footer={buttons.logout}
      >
        <Space style={{ display: 'flex' }} direction='vertical' size='large'>
          {buttons.requests}
          {buttons.settings}
          {buttons.userGuide}
        </Space>
      </Drawer>
    </>
  );
}

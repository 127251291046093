import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from 'react-router-dom';
import axios from "../../utils/axios";
import assembler from "url-assembler";
import { ExclamationCircleOutlined, WarningTwoTone } from "@ant-design/icons";
import Comments from "./Comments";
import FolderList from "./FolderList";
import FilesToApprove from "./FilesToApprove";
import CommentsModal from "./CommentsModal";
import Participants from "./Participants";
import CustomerEmails from "./CustomerEmails";
import History from "./serviceHistory";
import FileUpload from "../components/FileUpload";
import NumericInput from "../components/NumericInput";
import { useDispatch, useSelector } from "react-redux";
import { requestSelected } from "../../redux/actions/requestActions";
import jwt_decode from "jwt-decode";
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import KeyConfigEdit from "../components/KeyConfigEdit";
import lodashUniqBy from 'lodash/uniqBy';

import {
  Form, Row, Col, Spin, Tabs, Popconfirm, Input, Badge, Button, message,
  Layout, Radio, Modal, Divider, Switch, Space
} from "antd";

import {
  API_GET_SERVICE_REQUEST, API_REOPEN_REQUEST, API_GET_FILES_TO_APPROVE,
  API_CLOSE_REQUEST, API_FILES_UPDATE, API_UPDATE_KEY_CONFIG, UI_ROUTES,
  API_ROUTES
} from "../../utils/webConstants";

import {
  REQUEST_SERVICE_COMPONENT, USERTYPE, SERVICE_REQUEST_STATUS, FILE_STATUS,
  NAVIGATION_TYPE, SERVICE_REQUEST_TABS, REGEX_STRINGS, AXIOS_CONFIG_VALUES
} from "../../utils/appConstants";

const {
  ALLOW_LETTERS_NUMBERS_UNDERSCORE_PERIOD_AND_HYPHEN,
  ALLOW_LETTERS_NUMBERS_UNDERSCORE_PERIOD_COMMA_AND_HYPHEN
} = REGEX_STRINGS;

const {
  AUTHORIZATION, CONTENT_TYPE, APPLICATION_JSON, PUT_METHOD, POST_METHOD
} = AXIOS_CONFIG_VALUES;

const {
  NAME_SHOULD_LESS_THAN_300CHARACTERS, REQUEST_CREATION_INPROGRESS_MESSAGE,
  REQUEST_NAME_ALREADY_EXIST_ERROR_MESSAGE, REQUEST_REOPEN_SUCCESS_MESSAGE,
  PARENT_DISTRIBUTION_LIST, REQUEST_SUCCESS_MESSAGE, REQUEST_DETAILS_TEXT,
  REASON_DOESNOT_ACCEPT_SPECIAL_CHARACTER_MESSAGE, REQUEST_PAGE_MESSAGE_1,
  FILE_TRANSFER_NAME_CANNOT_BE_EMPTY_MESSAGE, REQUEST_FILE_TRANSFER_TEXT,
  NAME_DOESNOT_ACCEPT_SPECIAL_CHARACTERS, REQUEST_UPDATE_SUCCESS_MESSAGE,
  CLOSE_REQUEST_CONFIRM_MESSAGE, BACK_REQUEST_CONFIRM_MESSAGE_AFTER_SAVE,
  REQUEST_CLOSED_SUCCESS_MESSAGE, REASON_CANNOT_BE_EMPTY_ERROR_MESSAGE,
  DESCRIPTION_MAX_LENGTH_EXCEEDED_MESSAGE, REQUEST_PAGE_MESSAGE_2,
  DESCRIPTION_MAX_LENGTH, FILE_RETENTION_PERIOD_CANNOT_BE_EMPTY,
  EXTERNAL_PARTY_NAME_DOESNOT_ACCEPT_SPECIAL_CHARACTERS,
  BACK_REQUEST_CONFIRM_MESSAGE, REVIEW_REMINDER
} = REQUEST_SERVICE_COMPONENT;

const { TextArea } = Input;
const { TabPane } = Tabs;
const { Content } = Layout;
const { confirm } = Modal;

const layout = { labelCol: { span: 8 }, wrapperCol: { span: 20 } };

const RequestService = () => {
  const selectedRequest = useSelector((state) => state.request);
  const { userInfo } = useSelector((state) => state.userLogin);
  const dispatch = useDispatch();

  let history = useHistory();
  let requestIdentifier = 0;
  let screenType = "";
  let userToken = "";

  if (userInfo) userToken = userInfo.userToken
  else history.push(UI_ROUTES.LOGIN);

  if (history.location.pathname.includes(UI_ROUTES.REQUEST_DETAIL_PATH)) {
    const pathname = history.location.pathname.split("/");

    if (pathname[3] === "comment") {
      screenType = "fileComment";
    }
  }

  const decodedToken = userToken !== "" ? jwt_decode(userToken) : {};
  const userEmail = decodedToken.emailId ?? "";

  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [fileRetentionPeriod, setfileRetentionPeriod] = useState("99");
  const params = useParams();

  const [type] = useState(
    selectedRequest?.request?.selectedTab?.length ?
    selectedRequest.request.selectedTab :
    SERVICE_REQUEST_TABS.ACTIVE
  );

  const authHeader = { [AUTHORIZATION]: userToken };

  const contentAndAuthHeaders = {
    [CONTENT_TYPE]: APPLICATION_JSON, [AUTHORIZATION]: userToken
  };

  const [page] = useState(selectedRequest?.request?.page ?? 1);
  const [limit] = useState(selectedRequest?.request?.limit ?? 10);
  const [requestId, setRequestId] = useState(selectedRequest?.request?.requestId ?? (requestIdentifier !== 0 ? requestIdentifier : ""));
  const [roleId, setRoleId] = useState(selectedRequest?.request?.roleId ?? 0);
  const navigationType = params?.navType ?? "";
  const [disableFields, setdisableFields] = useState(false);
  const [isRPModalVisible, setIsRPModalVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState(screenType !== "" ? screenType : "files");
  const [isLoading, setIsLoading] = useState(false);
  const [isCommentsModalVisible, setIsCommentsModalVisible] = useState(false);
  const [customerEmailData, setCustomerEmailData] = useState([]);
  const [customerEmailErrorMessage, setCustomerEmailErrorMessage] = useState(false);
  const [commentType, setCommentType] = useState("");
  const [userType, setUserType] = useState("");
  const [associatedEmployeeErrorMessage, setAssociatedEmployeeErrorMessage] = useState(false);
  const [isServiceReqExternal, setIsServiceReqExternal] = useState(true);
  const [isCustomerFileUpload, setIsCustomerFileUpload] = useState(true);
  const [isFileUploadModelOpen, setIsFileUploadModelOpen] = useState(false);
  const [serviceReqFormData, setServiceReqFormData] = useState({});
  const [reviewerErrorMessage, setReviewerErrorMessage] = useState(false);
  const [initiatorEmail, setInitiatorEmail] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [canApprove, setCanApprove] = useState(false);
  const [isReviewReminderModalOpen, setIsReviewReminderModalOpen] = useState(false);
  const [isServiceReqCreateInvoked, setIsServiceReqCreateInvoked] = useState(false);
  const [isFileTransferRequestNameValid, setIsFileTransferRequestNameValid] = useState(false);
  const [isClosedRequest, setIsClosedRequest] = useState(false);
  const [requestName, setRequestName] = useState("");
  const [fileList, setFileList] = useState([]);
  const [isExemptRetentionPeriodApprove, setIsExemptRetentionPeriodApprove] = useState(false);
  const [approveExemptRetionPeriodText, setApproveExemptRetionPeriodText] = useState("");
  const [exemptForm] = Form.useForm();
  const [periodOfExtension, setPeriodOfExtension] = useState(null);
  const [mangersFiles, setMangersFiles] = useState([]);
  const [folderList, setFolderList] = useState([]);
  const [selectedApproveFiles, setSelectedApproveFiles] = useState([]);
  const [recordType, setRecordType] = useState("");
  const [filesToApprove, setFilesToApprove] = useState([]);
  const [deactivatedUsers, setDeactivatedUsers] = useState([]);
  const [fileTabCount, setFileTabCount] = useState(1);
  const [nonReadCommentCount, setNonReadCommentCount] = useState(0);
  const [selectedServiceRequestName, setSelectedServiceRequestName] = useState(null);
  const [keyConfig, setKeyConfig] = useState({});
  const [keyConfigForm] = Form.useForm();
  const [loadingKeyConfigEditForm, setLoadingKeyConfigEditForm] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isCheckChange, setIsCheckChange] = useState(false);
  const [resetCountForParticipants, setResetCountForParticipants] = useState(0);

  const handleModalChange = (val) => setIsFileUploadModelOpen(val);

  const handleOnSubmit = (val) => {
    if (isServiceReqCreateInvoked) {
      setIsLoading(true);
    }
    else {
      setIsLoading(false);
      history.push(UI_ROUTES.BASE);
    }
  };

  const redirectToMainPage = () => {
    const mainPagePath =  `/requests/${type}?page=${page}&limit=${limit}`;

    history.push(mainPagePath);
  };

  const handleFileUpload = () => {
    form.validateFields();
    const employeeList = treeViewToArray(tableData);

    const reviewer = employeeList.find(
      obj => obj.roleName === USERTYPE.FILE_APPROVER
    );

    if (isServiceReqExternal && customerEmailData.length === 0) {
      setCustomerEmailErrorMessage(true);
    }

    if (employeeList.length === 0) {
      setAssociatedEmployeeErrorMessage(true);

      if (isServiceReqExternal && !reviewer) {
        console.log("associatedEmployeeErrorMessage", associatedEmployeeErrorMessage);
      }

      return;
    }
    else {
      if (isServiceReqExternal && !reviewer) {
        if (employeeList.findIndex(i => i.roleName === "FILE APPROVER") === -1) {
          if (employeeList.length > 0) setReviewerErrorMessage(true);
        }

        return;
      }
    }

    if ((isServiceReqExternal && customerEmailData.length === 0)) return false;

    const serviceReqFormValues = {
      name: form.getFieldsValue().serviceName,
      description: form.getFieldsValue().descriptionOfService,
      associatedEmployees: employeeList,
      retentionPeriod: form.getFieldsValue().fileRetentionPeriod,
      customerAccountName: form.getFieldsValue().customerAccountName,
      customerEmailAddress: form.getFieldsValue().customerEmail,
      customerEmail: customerEmailData,
      isExternal: isServiceReqExternal
    };

    if (!form.getFieldsValue().descriptionOfService) return;

    if (!form.getFieldsValue().serviceName) return;

    setIsFileUploadModelOpen(true);
    setServiceReqFormData({ ...serviceReqFormData, serviceReqFormValues });
  };

  useEffect(() => {
    let interval = null;

    if (isServiceReqCreateInvoked && !requestId) {
      interval = setInterval(async () => {
        try {
          if (isServiceReqCreateInvoked) {
            const res = await axios.post(
              API_GET_SERVICE_REQUEST,
              { serviceRequestName: form.getFieldValue('serviceName') },
              { headers: authHeader }
            );

            if (res?.data?.payload?.length === 1) {
              message.success(REQUEST_SUCCESS_MESSAGE);
              setIsServiceReqCreateInvoked(false)
              dispatch(requestSelected(res?.data?.payload[0]?.id, 0, '', 0));
              setRequestId(res?.data?.payload[0]?.id);
              getRequestByDetail();

              if (isSave) history.push(UI_ROUTES.BASE);

              setIsLoading(false);
            }
          }
        }
        catch (error) {
          console.error(error);
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isServiceReqCreateInvoked]);

  const getFilesToApprove = async () => {
    try {
      const urlParam = { id: requestId };
      const url = assembler(API_GET_FILES_TO_APPROVE).param(urlParam).query({});
      const res = await axios.get(url, { headers: authHeader });

      const values = res.data.payload.map((elem, index) => {
        elem.key = elem.id;
        return elem;
      });

      setFilesToApprove(values);
      let pathname = [];

      if (history.location.pathname.includes(UI_ROUTES.REQUEST_DETAIL_PATH)) {
        pathname = history.location.pathname.split("/");
      }

      if (pathname.length > 0 && pathname[3] && (pathname[3] === "fileComment" || pathname[3] === "file")) {
        setSelectedTab("files");
      }
      else if (pathname.length > 0 && pathname[3] && pathname[3] === "comment") {
        setSelectedTab("comments");
      }
      else if (values.length > 0) {
        setSelectedTab("tasks");

        confirm({
          content: 'Please proceed to the Tasks tab to review files requiring your attention.'
        });
      }
      else setSelectedTab("files");
    }
    catch (error) {
      if (error?.response?.data?.status === 403) history.push(UI_ROUTES.BASE);
    }
  };

  useEffect(() => {
    if (requestId && requestId !== "") {
      getFilesToApprove();
      getCommentsCount();
    }
  }, [])

  const getRequestByDetail = useCallback(
    () => {
      if (requestId && requestId !== "") {
        setdisableFields(true);
        setIsLoading(true);

        axios.get(API_ROUTES.GET_REQUEST_DETAILS_BY_ID + "/" + requestId, { headers: authHeader })
        .then((response) => {
          if (response.data.payload.keyConfig) {
            setKeyConfig(response.data.payload.keyConfig)
          }

          setInitiatorEmail(response.data.payload.createdBy);
          setIsServiceReqExternal(response.data.payload.isExternal);

          form.setFieldsValue({
            serviceName: response.data.payload.name,
            descriptionOfService: response.data.payload.description,
            fileRetentionPeriod: response.data.payload.retentionPeriod,
            customerAccountName: response.data.payload.customerAccountName,
            customerEmail: response.data.payload.customerEmailAddress,
            isCustomerFileUpload: response.data.payload.isCustomerFileUpload,
          });

          setFileList(response.data.payload.files);
          setSelectedServiceRequestName(response.data.payload.name);

          const employees = [];

          response.data.payload.associatedEmployees.forEach(value => {
            employees.push({
              id: value.id,
              name: value.name,
              email: value.email,
              roleId: value.roleId,
              distributionListName: value.distributionListName,
              emailToCommunicate: value.emailToCommunicate,
              roleName: (value.role.name === USERTYPE.REVIEWER)
                ? USERTYPE.FILE_APPROVER
                : value.role.name
            });
          });

          setTableData(lodashUniqBy(employees, "id"));
          listToTree(lodashUniqBy(employees, "id"));
          setCustomerEmailData(lodashUniqBy(response.data.payload.customerEmail, "email"));

          const customerObject = response.data.payload.customerEmail.find(
            o => o.email.toLowerCase() === userEmail.toLowerCase()
          );

          const customerData = response.data.payload.customerEmail;
          let inactiveUsers = [];

          if (customerData) {
            for (let i = 0; i < customerData.length; i++) {
              if (customerData[i].user && customerData[i].user.status === "DEACTIVATED") {
                inactiveUsers.push(customerData[i].user);
              }
            }

            setDeactivatedUsers(inactiveUsers);
          }

          let userRoleId = 0;

          if (customerObject) {
            setUserType(USERTYPE.CUSTOMER);
          }
          else {
            let employee = response.data.payload.associatedEmployees.filter(
              o => o.email.toLowerCase() === userEmail.toLowerCase()
            );

            const initiator = employee.find(e => e.roleId === 1);

            if (initiator) {
              setRoleId(1);
              userRoleId = 1;
            }
            else {
              let approver = employee.find(e => e.roleId === 3);

              if (approver) {
                setRoleId(3);
                userRoleId = 3;
              }
              else {
                let emp = response.data.payload.associatedEmployees.find(
                  o => o.email.toLowerCase() === userEmail.toLowerCase()
                );

                if (emp) {
                  setRoleId(emp.roleId);
                  userRoleId = emp.roleId;
                }
              }
            }

            if (userRoleId === 1) {
              setdisableFields(false);
              setUserType(USERTYPE.INITIATOR);

              axios.get(API_ROUTES.GET_SETTINGS, { headers: authHeader })
              .then((response) => {
                if (response?.data) {
                  setfileRetentionPeriod(response.data.payload[0].maxFileRetentionPeriod);
                }
              });

              GetLdapUsers();
            }
            else if (userRoleId === 2) {
              setUserType(USERTYPE.FULFILLER);
            }
            else if (userRoleId === 3) {
              setUserType(USERTYPE.REVIEWER);
            }
            else {
              setUserType("none");
            }
          }

          if (requestId === 0) {
            setdisableFields(false);
            setUserType(userType.INITIATOR);
            GetLdapUsers();
          }

          if (response.data.payload.status === SERVICE_REQUEST_STATUS.INITIATOR_CLOSE_REQUEST) {
            setIsClosedRequest(true);

            const initiator = (response.data.payload.associatedEmployees).find(
              ele => ele.roleId === 1
            );

            if (initiator?.email === userEmail) {
              setUserType(USERTYPE.INITIATOR);
              GetLdapUsers();
            }
            else {
              setUserType("");
            }

            setdisableFields(true);
          }

          setIsLoading(false);
        })
        .catch((error) => {
          if (error?.response?.status === 403) history.push(UI_ROUTES.BASE);
        });
      }

      if (!requestId) {
        setInitiatorEmail(userEmail);
        setUserType(USERTYPE.INITIATOR);
        GetLdapUsers();

        axios.get(API_ROUTES.GET_SETTINGS, { headers: authHeader })
        .then((response) => {
          if (response?.data) {
            setfileRetentionPeriod(response.data.payload[0].maxFileRetentionPeriod);

            form.setFieldsValue({
              fileRetentionPeriod: response.data.payload[0].defaultFileRetentionPeriod
            });
          }
        });
      }
    },
    [selectedRequest, requestId],
  );

  useEffect(() => {
    getRequestByDetail()
  }, [getRequestByDetail]);

  const GetLdapUsers = () => {
    axios.get(API_ROUTES.TEST_LDAP_USERS, { headers: authHeader });
  };

  const onRetentionPeriodChange = (e) => {
    if (e === 0 || e > fileRetentionPeriod) {
      if (e > fileRetentionPeriod) showMaxFileRetentionPeriodModal();

      form.setFieldsValue({
        fileRetentionPeriod: fileRetentionPeriod.toString()
      });
    }
  };

  const onFinish = async (values) => {
    setIsSave(true);

    const employeeList = treeViewToArray(tableData);

    const reviewer = employeeList.find(
      obj => obj.roleName === USERTYPE.FILE_APPROVER
    );

    if (isServiceReqExternal && !reviewer) setReviewerErrorMessage(true);

    if (isServiceReqExternal && customerEmailData.length === 0) {
      setCustomerEmailErrorMessage(true);
    }

    if (employeeList.length === 0) setAssociatedEmployeeErrorMessage(true);

    if ((isServiceReqExternal && customerEmailData.length === 0) || employeeList.length === 0 || (isServiceReqExternal && !reviewer)) {
      return false;
    }

    setIsLoading(true);

    const reqBody = {
      id: requestId ? requestId : 0,
      name: values.serviceName,
      description: values.descriptionOfService,
      associatedEmployees: employeeList,
      retentionPeriod: values.fileRetentionPeriod,
      customerAccountName: values.customerAccountName,
      customerEmailAddress: values.customerEmail,
      isCustomerFileUpload: isCustomerFileUpload,
      customerEmail: customerEmailData,
      distributionList: "",
      isExternal: isServiceReqExternal
    };

    try {
      if (!requestId && !isFileTransferRequestNameValid) {
        form.setFields([{
          name: "serviceName",
          errors: [REQUEST_NAME_ALREADY_EXIST_ERROR_MESSAGE]
        }]);

        setIsLoading(false);
      }
      else {
        if (!requestId) {
          await axios.post(API_ROUTES.POST_REQUEST, reqBody, { headers: authHeader });

          setIsServiceReqCreateInvoked(true);
          message.success(REQUEST_CREATION_INPROGRESS_MESSAGE);
        }
        else {
          const { data } = await axios.put(API_GET_SERVICE_REQUEST, reqBody, {
            headers: authHeader
          });

          if (data) {
            message.success(REQUEST_UPDATE_SUCCESS_MESSAGE);
            history.push(UI_ROUTES.BASE);
          }
        }
      }
    }
    catch (error) {
      setIsLoading(false);
    }
  };

  const onFinishFailed = (e) => {
    const employeeList = treeViewToArray(tableData);

    const reviewer = employeeList.find(
      obj => obj.roleName === USERTYPE.FILE_APPROVER
    );

    if (isServiceReqExternal && !reviewer) setReviewerErrorMessage(true);

    if (isServiceReqExternal && customerEmailData.length === 0) {
      setCustomerEmailErrorMessage(true);
    }

    if (employeeList.length === 0) setAssociatedEmployeeErrorMessage(true);

    if ((isServiceReqExternal && customerEmailData.length === 0) || employeeList.length === 0 || (isServiceReqExternal && !reviewer)) {
      return false;
    }
  };

  const callback = (key) => {
    if (key === "files") setFileTabCount(fileTabCount + 1);

    setSelectedTab(key);
  };

  const CommentsModalSubmit = (commentType, buttonType) => {
    setIsCommentsModalVisible(false);

    if (buttonType === "submit"){
      if (commentType !== 'approve') {
        redirectToMainPage();
      }
      else {
        getRequestByDetail();
        getCommentsCount();
        getFilesToApprove();
      }
    }
  };

  const onCustomerEmailChange = ({ customerEmailData }) => {
    setCustomerEmailData(customerEmailData);

    if (customerEmailData.length > 0) setCustomerEmailErrorMessage(false);
    else setCustomerEmailErrorMessage(true);

    setIsCheckChange(true);
  };

  const onCloseProjectClick = () => {
    setIsLoading(true);

    axios({
      method: PUT_METHOD,
      url: API_CLOSE_REQUEST,
      headers: contentAndAuthHeaders,
      data: JSON.stringify({ requestId: requestId })
    })
    .then((response) => {
      if (response?.status === 200) {
        message.success(REQUEST_CLOSED_SUCCESS_MESSAGE);
        redirectToMainPage();
      }

      setIsLoading(false);
    });
  };

  const reopenRequest = () => {
    let message = "";
    const confirmIcon = <ExclamationCircleOutlined style={{ height: "50px" }} />;

    if (deactivatedUsers?.length > 0) {
      const userName = `${deactivatedUsers[0].firstName} ${deactivatedUsers[0].lastName}`;
      message = `User '${userName}' is already deactivated. Are you sure to reactivate this user and reopen this service request?`;

      confirm({
        icon: confirmIcon,
        content: message,
        onOk() {
          setIsLoading(true);
          reopen();
        }
      });
    }
    else {
      message = "Are you sure you would like to reopen this request?";

      confirm({
        icon: confirmIcon,
        content: message,
        onOk() {
          setIsLoading(true);
          reopen();
        }
      });
    }
  };

  const reopen = () => {
    axios({
      method: PUT_METHOD,
      url: API_REOPEN_REQUEST,
      headers: contentAndAuthHeaders,
      data: JSON.stringify({ requestId: requestId })
    })
    .then((response) => {
      if (response?.status === 200) {
        message.success(REQUEST_REOPEN_SUCCESS_MESSAGE);
        redirectToMainPage();
      }

      setIsLoading(false);
    });
  };

  const onPartcipantChange = (props) => {
    if (props.deleteObj) {
      let participantData = [...tableData];

      const matchEmailAndNameAndRoleAndDl = (x) => {
        const emailMatch = (x.email === props.deleteObj.email);
        const nameMatch = (x.name === props.deleteObj.name);
        const roleIdMatch = (x.roleId === props.deleteObj.roleId);

        const dlNameMatch = (
          x.distributionListName === props.deleteObj.distributionListName
        );

        return (emailMatch && nameMatch && roleIdMatch && dlNameMatch);
      };

      const index = participantData.findIndex(matchEmailAndNameAndRoleAndDl);

      if (index !== -1) {
        participantData.splice(index, 1);
      }
      else {
        for (const cm of participantData) {
          if (cm.children) {
            const index = cm.children.findIndex(matchEmailAndNameAndRoleAndDl);

            if (index !== -1) cm.children.splice(index, 1);
          }
        }
      }

      setTableData(participantData);
    }
    else {
      listToTree(props.ParticipantsList);

      if (props.ParticipantsList.length === 0 && !props.error) {
        setAssociatedEmployeeErrorMessage(true);
      }
      else {
        setAssociatedEmployeeErrorMessage(false);
      }

      setReviewerErrorMessage(false);
    }

    setIsCheckChange(true)
  };

  const listToTree = (data) => {
    const objDL = data.filter(
      item => item.distributionListName === PARENT_DISTRIBUTION_LIST
    );

    const uniqueDLName = [...new Set(objDL.map(item => item.name))];
    let treeView = [];

    const userList = treeView.concat(
      data.filter(item => {
        const dlNameEmpty = (item.distributionListName === "");
        const dlNameNull = (item.distributionListName === null);
        const emailNotEmpty = (item.email !== "");

        return ((dlNameEmpty || dlNameNull) && emailNotEmpty);
      })
    );

    treeView = userList;

    uniqueDLName.forEach((ele, i) => {
      if (ele != null) {
        const dlMembers = data.filter(
          item => item.distributionListName === ele
        );

        const uniqueRole = [...new Set(dlMembers.map(item => item.roleId))];

        uniqueRole.forEach((eleRole, i) => {
          let dlObj = data.filter(
            item => item.name === ele && item.roleId === eleRole
          );

          const members = data.filter(item => {
            const dlNameMatch = (item.distributionListName === ele);
            const roleMatch = (item.roleId === eleRole);

            return (dlNameMatch && roleMatch);
          });

          let obj = {};

          if (dlObj?.length > 0) obj = dlObj[0];

          obj.children = members;
          treeView.push(obj);
        });
      }
    });

    setTableData(treeView);
  };

  const treeViewToArray = (value) => {
    if (value?.length > 0) {
      let obj = value;

      value.forEach(({ children }) => {
        if (children?.length > 0) obj = [...obj, ...children];
      });

      return obj;
    }
    else {
      return [];
    }
  };

  const validateFileTransferReqName = async (e) => {
    /* disable unique Service Request naming restriction
       API lookups are done by Service Request ID in GET: /api/v1/service-request
    */

    setIsFileTransferRequestNameValid(true);

    /*
    const requestBody = { serviceRequestName: e.target.value };
    if (requestId) {
      requestBody.serviceRequestId = requestId;
    }
    const res = await axios.post(API_GET_SERVICE_REQUEST, requestBody, { headers: { authorization: userToken } });
    if (res && res.data && res.data.payload && res.data.payload.length === 0) {
      setIsFileTransferRequestNameValid(true);
    } else {
      form.setFields([
        {
          name: "serviceName",
          errors: [REQUEST_SERVICE_COMPONENT.REQUEST_NAME_ALREADY_EXIST_ERROR_MESSAGE],
        },
      ]);
      setIsFileTransferRequestNameValid(false);
    }*/
  };

  const handleRequestNameChange = (e) => setRequestName(e.target.value);

  const approveDeactivateUserRequest = () => {
    setCommentType("approveDeactivateUserRequest");
    setIsCommentsModalVisible(true);
  };

  const approve = () => {
    let uploadedByCurrentUser = false;
    let fileNames = "";
    let filenameCount = 0;
    let fileIds = selectedApproveFiles.map((e) => e.id);

    for (let i = 0; i < fileIds.length; i++) {
      const file = fileList.find(
        ele => ele.createdBy === userEmail && ele.id === fileIds[i]
      );

      if (file) {
        filenameCount++;
        uploadedByCurrentUser = true;

        if (fileNames === "") {
          fileNames = file.name;
        }
        else {
          if (i === fileIds.length - 1)
            fileNames = fileNames + " and " + file.name;
          else
            fileNames = fileNames + ", " + file.name;
        }
      }
    }

    if (uploadedByCurrentUser) {
      const verb = (filenameCount === 1) ? 'is' : 'are';
      const textBeforeVerb = "[" + fileNames + "]";
      const textAfterVerb = "unable to be approved by you as the Fulfiller.";

      message.error(`${textBeforeVerb} ${verb} ${textAfterVerb}`);
      return;
    }

    let alreadApproved = false;
    let approvedFileNames = "";
    let approvedFileNameCount = 0;
    let approvedUserNames = [];

    for (let i = 0; i < fileIds.length; i++) {
      const file = fileList.find(
        ele => ele.status === FILE_STATUS.REVIEWED && ele.id === fileIds[i]
      );

      if (file) {
        approvedFileNameCount++;
        alreadApproved = true;

        if (file.approvedBy) {
          const participant = tableData.find(
            ele => ele.email === file.approvedBy
          );

          const approvedUserName = (participant)
            ? participant.name
            : file.approvedBy;

          approvedUserNames.push(approvedUserName);
        }

        if (approvedFileNames === "") {
          approvedFileNames = file.name;
        }
        else {
          if (i === fileIds.length - 1)
            approvedFileNames = approvedFileNames + " and " + file.name;
          else
            approvedFileNames = approvedFileNames + ", " + file.name;
        }
      }
    }

    if (alreadApproved) {
      const usernames = [...new Set(approvedUserNames)];
      let names = "";
      let count = 0;

      usernames.forEach(element => {
        if (names === "") {
          names = element;
        }
        else {
          if (count === usernames.length - 1)
            names = names + " and " + element;
          else
            names = names + ", " + element;
        }

        count++;
      });

      const verb = (approvedFileNameCount === 1) ? 'was' : 'were';
      const textBeforeVerb = "[" + approvedFileNames + "]";
      const textAfterVerb = "approved by [" + names + "].";

      message.error(`${textBeforeVerb} ${verb} ${textAfterVerb}`);
      return;
    }

    let customerFiles = false;
    let customerFileNames = "";
    let customerFileNameCount = 0;

    for (let i = 0; i < fileIds.length; i++) {
      const file = fileList.find(
        ele => ele.status === FILE_STATUS.ACTIVE && ele.id === fileIds[i]
      );

      if (file) {
        customerFileNameCount++;
        customerFiles = true;

        if (customerFileNames === "") {
          customerFileNames = file.name;
        }
        else {
          if (i === fileIds.length - 1)
            customerFileNames = customerFileNames + " and " + file.name;
          else
            customerFileNames = customerFileNames + ", " + file.name;
        }
      }
    }

    if (customerFiles) {
      const noun = (customerFileNameCount === 1) ? 'file' : 'files';
      const textBeforeNoun = "Customer Uploaded";
      const textAfterNoun = "[" + customerFileNames + "] cannot be approved.";

      message.error(`${textBeforeNoun} ${noun} ${textAfterNoun}`);
      return;
    }

    setCommentType("approve");
    setIsCommentsModalVisible(true);
  };

  const rejectClick = () => {
    const filesWithExempt = [];
    const filesWithoutExempt = [];

    if (recordType === "DeactivateUserRequest") {
      setCommentType("rejectDeactivateUserRequest");
      setIsCommentsModalVisible(true);
    }
    else {
      selectedApproveFiles.forEach(element => {
        const file = fileList.find(x => x.id === element.id);

        if (file.status === "TO_BE_REVIEWED_EXEMPT_FILE_RETENTION_PERIOD")
          filesWithExempt.push(file);
        else if (file.status === "TO_BE_REVIEWED")
          filesWithoutExempt.push(file);
      });

      if (filesWithExempt.length > 0 && filesWithoutExempt.length > 0) {
        message.error("Please select same approval type of files");
      }
      else if (filesWithExempt.length > 0) {
        setCommentType("fileRetentionReject");
        setIsCommentsModalVisible(true);
      }
      else if (filesWithoutExempt.length > 0) {
        let uploadedByCurrentUser = false;
        let fileNames = "";
        let fileNameCount = 0;

        for (let i = 0; i < selectedFiles.length; i++) {
          const file = fileList.find(
            ele => ele.createdBy === userEmail && ele.id === selectedFiles[i]
          );

          if (file) {
            fileNameCount++;
            uploadedByCurrentUser = true;

            if (fileNames === "") {
              fileNames = file.name;
            }
            else {
              if (i === selectedFiles.length - 1)
                fileNames = fileNames + " and " + file.name;
              else
                fileNames = fileNames + ", " + file.name;
            }
          }
        }

        if (uploadedByCurrentUser) {
          const verb = (fileNameCount === 1) ? 'is' : 'are';
          const textBeforeVerb = "[" + fileNames + "]";
          const textAfterVerb = "unable to be rejected by you as the fulfiller. If the file is no longer necessary, delete the file.";

          message.error(`${textBeforeVerb} ${verb} ${textAfterVerb}`);
          return;
        }

        let alreadRejected = false;
        let approvedFileNames = "";
        let approvedFileNameCount = 0;

        for (let i = 0; i < selectedFiles.length; i++) {
          const file = fileList.find(
            ele => ele.status === "REJECTED" && ele.id === selectedFiles[i]
          );

          if (file) {
            approvedFileNameCount++;
            alreadRejected = true;

            if (approvedFileNames === "")
              approvedFileNames = file.name;
            else {
              if (i === selectedFiles.length - 1)
                approvedFileNames = approvedFileNames + " and " + file.name;
              else
                approvedFileNames = approvedFileNames + ", " + file.name;
            }
          }
        }

        if (alreadRejected) {
          const verb = (approvedFileNameCount === 1) ? 'was' : 'were';
          const textBeforeVerb = "[" + approvedFileNames + "]";
          const textAfterVerb = "already rejected.";

          message.error(`${textBeforeVerb} ${verb} ${textAfterVerb}`);
          return;
        }

        let customerFiles = false;
        let customerFileNames = "";
        let customerFileNameCount = 0;

        for (let i = 0; i < selectedFiles.length; i++) {
          const file = fileList.find(
            ele => ele.status === "ACTIVE" && ele.id === selectedFiles[i]
          );

          if (file) {
            customerFileNameCount++;
            customerFiles = true;

            if (customerFileNames === "")
              customerFileNames = file.name;
            else {
              if (i === selectedFiles.length - 1)
                customerFileNames = customerFileNames + " and " + file.name;
              else
                customerFileNames = customerFileNames + ", " + file.name;
            }
          }
        }

        if (customerFiles) {
          const noun = (customerFileNameCount === 1) ? 'file' : 'files';
          const textBeforeNoun = "Customer Uploaded";
          const textAfterNoun = "[" + customerFileNames + "] cannot be approved.";

          message.error(`${textBeforeNoun} ${noun} ${textAfterNoun}`);
          return;
        }

        setCommentType("reject");
        setIsCommentsModalVisible(true);
      }
    }
  };

  const approveExemptClick = () => {
    if (recordType === "DeactivateUserRequest") {
      approveDeactivateUserRequestClick();
    }
    else {
      const filesWithExempt = [];
      const filesWithoutExempt = [];

      selectedApproveFiles.forEach(element => {
        const file = fileList.find(x => x.id === element.id);

        if (file.status === "TO_BE_REVIEWED_EXEMPT_FILE_RETENTION_PERIOD")
          filesWithExempt.push(file);
        else if (file.status === "TO_BE_REVIEWED")
          filesWithoutExempt.push(file);
      });

      if (filesWithExempt.length > 0 && filesWithoutExempt.length > 0) {
        message.error("Please select same approval type of files");
      }
      else if (filesWithoutExempt.length > 0) {
        approveClick();
      }
      else if (filesWithExempt.length > 0) {
        setApproveExemptRetionPeriodText("Upon approval of the file retention period extension, please enter the new extension period (in days).");
        setIsExemptRetentionPeriodApprove(true);
      }
    }
  };

  const approveClick = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: 'Your responsibility as a reviewer is to ensure the file(s) is/are being sent to the correct recipients and contains the correct data.',
      onOk() {
        approve();
      }
    });
  };

  const approveDeactivateUserRequestClick = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: 'Your responsibility as a initiator to approve/reject deactivate user request.',
      onOk() {
        approveDeactivateUserRequest();
      }
    });
  };

  const handleExemptFileRetentionPeriodApprove = () => {
    exemptForm.validateFields()
    .then((values) => {
      setPeriodOfExtension(values.periodOfExtension);

      const requestPayload = {
        serviceRequestId: requestId,
        periodOfExtension: values.periodOfExtension
      };

      if (selectedApproveFiles) {
        requestPayload.fileIds = selectedApproveFiles.map(e => e.id);
      }

      axios({
        method: POST_METHOD,
        url: API_FILES_UPDATE,
        headers: contentAndAuthHeaders,
        data: JSON.stringify(requestPayload)
      })
      .then((response) => {
        setIsExemptRetentionPeriodApprove(false);
        exemptForm.resetFields();
        redirectToMainPage();
      });
    });
  };

  const handleExemptFileRetentionPeriodCancel = () => {
    setIsExemptRetentionPeriodApprove(false);
    exemptForm.resetFields();
  };

  const onPeriodOfExtension = (e) => {
    exemptForm.setFieldsValue({ periodOfExtension: e });
  };

  const managerFiles = (e) => setMangersFiles(e);

  const updateCommentCount = (count) => setNonReadCommentCount(count);

  const getCommentsCount = async () => {
    try {
      const urlQuery =  { id: requestId, type: "onlyCount" };
      const url = assembler(API_ROUTES.GET_FILE_AND_REQUEST_COMMENTS).query(urlQuery);
      const res = await axios.get(url, { headers: authHeader });

      setNonReadCommentCount(res.data.nonReadCommentsCount);
    }
    catch (error) {
      console.error("In getCommentsCount(): ", error);
    }
  };

  const onKeyManageEdit = () => {
    keyConfigForm.validateFields().then(async (values) => {
      setLoadingKeyConfigEditForm(true);
      const { keyManagement, publicKey, iv, secretKey } = values;
      const urlParam = { serviceRequestId: requestId };
      const url = assembler(API_UPDATE_KEY_CONFIG).param(urlParam);
      const requestBody = { keyManagement: keyManagement };

      if (keyManagement === "userManaged") {
        requestBody.publicKey = publicKey;
        requestBody.iv = iv;
        requestBody.secretKey = secretKey;
      }

      await axios.post(url, requestBody, { headers: authHeader });

      setLoadingKeyConfigEditForm(false);
      message.success('Key Management Configuration Successfully Updated');
    })
  };

  const handleRequestTypeChange = (e) => {
    setIsServiceReqExternal(e.target.value);

    if (form.isFieldsTouched()) resetFormInputValues();
  };

  const resetFormInputValues = () => {
    form.setFieldsValue({
      serviceName: null,
      descriptionOfService: null,
      fileRetentionPeriod: 15,
      customerAccountName: null,
      customerEmail: null,
      isCustomerFileUpload: true,
    });

    setRequestName('');
    setCustomerEmailData([]);
    setTableData([]);

    // Trigger input field resets in the child components "Participants" & "DebounceSelect"
    setResetCountForParticipants(previousValue => previousValue + 1);
  };

  const onSwitchChange = (checked) => {
    console.log(`Allow upload = ${checked}`);
    setIsCustomerFileUpload(checked)
  };

  const hideReviewReminderModal = () => setIsReviewReminderModalOpen(false);

  const reviewReminderModalTitle = (
    <Space>
      {REVIEW_REMINDER.TITLE}
      <WarningTwoTone style={{ fontSize: '1.3rem' }} twoToneColor="orange" />
    </Space>
  );

  const reviewReminderModal = (
    <Modal
      onOk={hideReviewReminderModal} onCancel={hideReviewReminderModal}
      open={isReviewReminderModalOpen} title={reviewReminderModalTitle}
    >
      <p>{REVIEW_REMINDER.BODY}</p>
    </Modal>
  );

  const reviewReminderModalContent = (isReviewReminderModalOpen)
    ? reviewReminderModal
    : null;

  const pageHeadingText = (requestId)
    ? `${REQUEST_DETAILS_TEXT} ( # ${requestId} )`
    : REQUEST_FILE_TRANSFER_TEXT;

  const pageHeading = (
    <Divider
      orientation="left" className="dividerHeading" style={{ marginTop: 0 }}
    >
      {pageHeadingText}
    </Divider>
  );

  const pageDescriptionText = (requestId)
    ? REQUEST_PAGE_MESSAGE_1
    : REQUEST_PAGE_MESSAGE_2;

  const pageDescription = <div><p>{pageDescriptionText}</p></div>;

  const customerEmails = (
    <CustomerEmails
      disableFields={disableFields} onChange={onCustomerEmailChange}
      customerEmailErrorMessage={customerEmailErrorMessage}
      customerEmailData={customerEmailData} form={form}
    />
  );

  const customerEmailsContent = (isServiceReqExternal) ? customerEmails : null;

  const showMaxFileRetentionPeriodModal = () => setIsRPModalVisible(true);

  const hideMaxFileRetentionPeriodModal = () => setIsRPModalVisible(false);

  const maxFileRetentionPeriodModal = (
    <Modal
      open={isRPModalVisible} onOk={hideMaxFileRetentionPeriodModal}
      onCancel={hideMaxFileRetentionPeriodModal}
    >
      <p>Maximum file retention period is {fileRetentionPeriod} days.</p>
    </Modal>
  );

  const participants = (
    <Participants
      disableFields={disableFields} userType={userType}
      initiatorEmailId={initiatorEmail} tableData={tableData}
      associatedEmployeeErrorMessage={associatedEmployeeErrorMessage}
      reviewerErrorMessage={reviewerErrorMessage} requestId={requestId}
      resetCountForParticipants={resetCountForParticipants} form={form}
      isServiceReqExternal={isServiceReqExternal} onChange={onPartcipantChange}
    />
  );

  const participantsContent = (userType && userType !== USERTYPE.CUSTOMER)
    ? participants
    : null;

  const commentsModal = (
    <CommentsModal
      requestId={requestId} type={commentType} onChange={CommentsModalSubmit}
      setCanApproveModalInfoVisible={setIsReviewReminderModalOpen}
      isCommentsModalVisible={isCommentsModalVisible}
      periodOfExtension={periodOfExtension}
      selectedFiles={selectedApproveFiles}
    />
  );

  const disableReviewButtons = (selectedApproveFiles.length > 0) ? false : true;

  return (
    <Layout>
      <AppHeader userName={userInfo.name} userEmail={userEmail} />
      <Layout className="layoutStyle">
        <Layout>
          <Content>
            {reviewReminderModalContent}
            <div
              className="site-layout-content contentStyle"
              style={{ background: "#fff", padding: '20px 25px 20px 25px' }}
            >
              {pageHeading}
              {pageDescription}
              {isServiceReqExternal !== null && <Spin spinning={isLoading} tip="Loading..." size="large">
                <Form form={form}
                  style={{ paddingTop: "5px" }}
                  {...layout}
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  labelAlign="left"
                  colon={false}
                >
                  <Row>
                    <Col span="15">
                      <section className="fieldset" id="sectionId">
                        <h2 style={{ fontWeight: "bold" }}>Request Details </h2>
                        {requestId ? null : <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                          Will files for this file transfer request be sent outside of the organization?
                          <br />
                          <Radio.Group onChange={(e) => handleRequestTypeChange(e)} value={isServiceReqExternal}>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </div>}
                        <br />
                        <Form.Item
                          label="File Transfer Request Name:"
                          name="serviceName"
                          style={{ fontWeight: "bold" }}
                          extra={requestName.length > 0 && 300 - requestName.length > 0 ? `${300 - requestName.length} characters remaining` : ""}
                          rules={[
                            {
                              required: !disableFields,
                              message: FILE_TRANSFER_NAME_CANNOT_BE_EMPTY_MESSAGE,
                            },
                            {
                              pattern: new RegExp(ALLOW_LETTERS_NUMBERS_UNDERSCORE_PERIOD_AND_HYPHEN),
                              message: NAME_DOESNOT_ACCEPT_SPECIAL_CHARACTERS,
                            },
                            {
                              max: 300,
                              message: NAME_SHOULD_LESS_THAN_300CHARACTERS,
                            }
                          ]}
                        >
                          {!disableFields ?
                            <Input autoComplete="off" readOnly={disableFields} id="serviceName" placeholder={"Special Characters are not accepted"} onBlur={validateFileTransferReqName} onChange={handleRequestNameChange} /> :
                            <label className="request-label">{form.getFieldValue("serviceName")}</label>
                          }
                        </Form.Item>
                        <Form.Item
                          label="Describe the reason for the file transfer request:"
                          name="descriptionOfService"
                          style={{ fontWeight: "bold", whiteSpace: "normal", height: "auto" }}
                          rules={[
                            {
                              required: !disableFields,
                              message: REASON_CANNOT_BE_EMPTY_ERROR_MESSAGE,
                            },
                            {
                              pattern: new RegExp(ALLOW_LETTERS_NUMBERS_UNDERSCORE_PERIOD_COMMA_AND_HYPHEN),
                              message: REASON_DOESNOT_ACCEPT_SPECIAL_CHARACTER_MESSAGE,
                            },
                            {
                              max: DESCRIPTION_MAX_LENGTH,
                              message: DESCRIPTION_MAX_LENGTH_EXCEEDED_MESSAGE,
                            }
                          ]}
                        >
                          {!disableFields ?
                            <TextArea autoComplete="off" readOnly={disableFields} rows={4} id="descriptionOfService" placeholder={"Description is required"} /> :
                            <label className="request-label">{form.getFieldValue("descriptionOfService")}</label>
                          }
                        </Form.Item>
                        {userType !== USERTYPE.CUSTOMER &&
                          <Form.Item
                            label="File Retention Period (in days)"
                            name="fileRetentionPeriod"
                            style={{ fontWeight: "bold" }}
                            rules={[{
                              required: !disableFields,
                              message: FILE_RETENTION_PERIOD_CANNOT_BE_EMPTY,
                            }]}
                          >
                            {!disableFields ?
                              <NumericInput style={{ width: 120 }} readOnly={disableFields} onChange={onRetentionPeriodChange} /> :
                              <label className="request-label">{form.getFieldValue("fileRetentionPeriod")}</label>
                            }
                          </Form.Item>}
                        {isServiceReqExternal && userType !== USERTYPE.CUSTOMER &&
                          <Form.Item
                            style={{ fontWeight: "bold" }}
                            label="Can External User upload files?"
                            name="isCustomerFileUpload"
                            valuePropName="checked"
                            initialValue
                          >
                            {
                              (!disableFields)
                                ? <Switch
                                    onChange={onSwitchChange}
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                  />
                                : <label className="request-label">
                                    {(form.getFieldValue("isCustomerFileUpload")) ? "Yes" : "No"}
                                  </label>
                            }
                          </Form.Item>
                        }
                        {isServiceReqExternal && <Form.Item
                          label="External Party Name:"
                          name="customerAccountName"
                          style={{ fontWeight: "bold" }}
                          rules={[{
                            pattern: new RegExp(ALLOW_LETTERS_NUMBERS_UNDERSCORE_PERIOD_AND_HYPHEN),
                            message: EXTERNAL_PARTY_NAME_DOESNOT_ACCEPT_SPECIAL_CHARACTERS,
                          }]}
                        >
                          {!disableFields ?
                            <Input autoComplete="off" readOnly={disableFields} id="customerAccountName" placeholder={"Ex. Business Name"} /> :
                            <label className="request-label">{form.getFieldValue("customerAccountName")}</label>
                          }
                        </Form.Item>}
                        {customerEmailsContent}
                      </section>
                      <br />
                    </Col>
                  </Row>
                  <Row>
                    <Col span="15">
                      {maxFileRetentionPeriodModal}
                      {participantsContent}
                    </Col>
                  </Row>

                  {requestId && requestId !== "" ?
                    <div id="fileDetails">
                      <Row>
                        <Col span="30">
                          <Tabs className="filesTable" activeKey={selectedTab} type="card" onChange={callback}>
                            {filesToApprove.length > 0 &&
                              <TabPane tab="Tasks" key="tasks">
                                <FilesToApprove
                                  requestId={requestId}
                                  email={userEmail}
                                  userType={userType}
                                  customerEmailData={customerEmailData}
                                  setSelectedApproveFiles={setSelectedApproveFiles}
                                  associatedEmployees={tableData}
                                  isClosedRequest={isClosedRequest}
                                  isServiceReqExternal={isServiceReqExternal}
                                  managerFiles={managerFiles}
                                  setFolderList={setFolderList}
                                  fileType="FilesToApprove"
                                  filesToApprove={filesToApprove}
                                  setRecordType={setRecordType}
                                  fileRetentionPeriod={form.getFieldValue("fileRetentionPeriod")}
                                />
                              </TabPane>}
                            <TabPane tab={<> Comments&nbsp; {nonReadCommentCount > 0 && <Badge count={nonReadCommentCount} />} </>} key="comments">
                              {selectedTab === "comments" &&
                                <Comments requestId={requestId}
                                  customerEmailData={customerEmailData}
                                  associatedEmployees={tableData}
                                  isClosedRequest={isClosedRequest}
                                  previousTab={selectedTab}
                                  updateCommentCount={updateCommentCount}
                                  isServiceReqExternal={isServiceReqExternal} />
                              }
                            </TabPane>
                            {!filesToApprove.length &&
                              <TabPane tab="Files" key="files">
                                {fileTabCount && tableData.length > 0 && userType !== "" && <FolderList
                                  requestId={requestId}
                                email={userEmail}
                                userType={userType}
                                customerEmailData={customerEmailData}
                                setSelectedFiles={setSelectedFiles}
                                setCanApprove={setCanApprove}
                                associatedEmployees={tableData}
                                isClosedRequest={isClosedRequest}
                                isServiceReqExternal={isServiceReqExternal}
                                managerFiles={managerFiles}
                                setFolderList={setFolderList}
                                fileType="AllFiles"
                                fileTabCount={fileTabCount}
                                selectedServiceRequestName={selectedServiceRequestName}
                                setSelectedServiceRequestName={setSelectedServiceRequestName}
                                isFileUplodaAllow={form.getFieldValue("isCustomerFileUpload")}
                                />}
                              </TabPane>
                            }
                            <TabPane tab="Request History" key="history">
                              {selectedTab === "history" && <History requestId={requestId} />}
                            </TabPane>
                            {userType === USERTYPE.CUSTOMER &&
                              <TabPane tab="Manage Keys" key="manageKeys">
                                {selectedTab === "manageKeys" && <KeyConfigEdit keyConfig={keyConfig} serviceRequestId={requestId} keyConfigForm={keyConfigForm} loadingKeyConfigEditForm={loadingKeyConfigEditForm} setLoadingKeyConfigEditForm={setLoadingKeyConfigEditForm} />}
                              </TabPane>}
                          </Tabs>
                        </Col>
                      </Row>
                    </div>
                    : null}
                  <Form.Item wrapperCol={requestId && requestId !== "" ? { span: 30 } : { span: 15 }} style={{ paddingTop: "5px" }}>
                    {isClosedRequest === true && userType === USERTYPE.INITIATOR ? <>
                      <Button className="buttonStyle" type="primary" onClick={() => reopenRequest()}>
                        Reopen
                      </Button>
                    </> : null}
                    {disableFields === false ?
                      <Button disabled={form.isSubmitting} className="buttonStyle" type="primary" htmlType="submit">
                        Submit
                      </Button> : null}
                    {!requestId && userType === USERTYPE.INITIATOR && <Button className="buttonStyle" type="secondary" onClick={handleFileUpload}>Upload</Button>}
                    {isFileUploadModelOpen && <FileUpload isServiceReqExternal={isServiceReqExternal} isModalOpen={isFileUploadModelOpen} onChange={handleModalChange} serviceReqFormData={serviceReqFormData} onSubmit={handleOnSubmit} setIsServiceReqCreateInvoked={setIsServiceReqCreateInvoked} setIsLoading={setIsLoading} requestId={requestId} userType={userType} setRequestId={setRequestId} isReload={false} />}
                    {disableFields === false && requestId && userType === USERTYPE.INITIATOR && navigationType !== NAVIGATION_TYPE.REOPEN ?
                      <Popconfirm
                        title={CLOSE_REQUEST_CONFIRM_MESSAGE}
                        onConfirm={() => onCloseProjectClick()}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button className="buttonStyle" type="primary" danger>
                          Close File Transfer
                        </Button>
                      </Popconfirm> : null}

                    {selectedTab === "manageKeys" && <Popconfirm
                      title={<span>Are you sure you would like to edit the keys used to encrypt/decrypt your files?</span>}
                      onConfirm={onKeyManageEdit}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button className="buttonStyle" type="primary" htmlType="submit">
                        Update
                      </Button>
                    </Popconfirm>}

                    {filesToApprove.length > 0 && selectedTab === "tasks" ? <>
                      <Button className="buttonStyle" type="primary" danger disabled={disableReviewButtons} onClick={rejectClick}>
                        Reject
                      </Button>
                      <Button className="buttonStyle" type="primary" disabled={disableReviewButtons} onClick={approveExemptClick}>
                        Approve
                      </Button>
                    </> : null}

                    {!requestId ? (<Popconfirm
                      title={BACK_REQUEST_CONFIRM_MESSAGE}
                      onConfirm={() => redirectToMainPage()}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button className="buttonStyle">Back to Main Screen</Button>

                    </Popconfirm>) : isCheckChange ? (<Popconfirm
                      title={BACK_REQUEST_CONFIRM_MESSAGE_AFTER_SAVE}
                      onConfirm={() => redirectToMainPage()}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button className="buttonStyle">Back to Main Screen</Button>

                    </Popconfirm>) :  (
                      <Button className="buttonStyle" onClick={redirectToMainPage}>Back to Main Screen</Button>
                    )}
                  </Form.Item>

                </Form>
              </Spin>}
              {commentsModal}
              <Modal
                open={isExemptRetentionPeriodApprove}
                title={""}
                okText="Submit"
                form="form"
                onCancel={handleExemptFileRetentionPeriodCancel}
                onOk={handleExemptFileRetentionPeriodApprove}
              >
                <>
                  <br />
                  <div>
                    {approveExemptRetionPeriodText}
                  </div>
                  <br />
                  <Form id="exemptForm" form={exemptForm} layout="horizontal">
                    <Form.Item label="New File Retention Period" name="periodOfExtension" rules={[
                      {
                        required: true,
                        message: "New File Retention Period cannot be empty",
                      },
                      ({ getFieldValue }) => ({
                        validator() {
                          if (getFieldValue('periodOfExtension')>180) {
                            return Promise.reject(new Error("New File Retention Period should not exceed 180 days"))
                          }
                          return Promise.resolve();
                        }
                      })
                    ]}>
                      <NumericInput style={{ width: 120 }} onChange={onPeriodOfExtension} />
                    </Form.Item>
                  </Form>
                </>
              </Modal>
            </div>
          </Content>
          <AppFooter />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default RequestService;

import moment from "moment-timezone";
import lodashRound from 'lodash/round';
import { ONE_KB, ONE_MB, ONE_GB } from "./appConstants";

/**
 * @param {String} dateAndTime
 * @returns {String} `dateAndTime` in local time-zone.
 */
export const getFormattedDateTime = (dateAndTime) => {
  const timeFormat = 'MMMM DD, yyyy[\r\n]h:mm A';

  return moment(dateAndTime).local().format(timeFormat);
};

/**
 * @param {String} url to open in a new tab.
 */
export const openUrlInNewTab = (url) => window.open(url, '_blank');

/**
 * @param {String} email
 * @returns the trimmed lowercase `email domain`
 */
export const getEmailDomain = (email) => email.trim().toLowerCase().split("@")[1];

/**
 * @param {String} fileSize
 */
export const getRoundedFileSize = (size) => {
  if (size >= ONE_GB) return `${lodashRound((size / ONE_GB), 2)} GB`;

  if (size >= ONE_MB) return `${lodashRound((size / ONE_MB), 2)} MB`;

  return `${lodashRound((size / ONE_KB), 2)} KB`;
};

import { STAGE } from "../utils/appConstants";

const config = {
  HOST: process.env.REACT_APP_API_HOST,
  STAGE: process.env.REACT_APP_STAGE || STAGE.DEV,
};



export default config;

import { Layout, Space } from "antd";
import { Link } from "react-router-dom";
import { UI_ROUTES } from '../../utils/webConstants';
import { APPFOOTER_COMPONENT } from "../../utils/appConstants";

const {
    COPYRIGHT, COOKIE_POLICY, PRIVACY_POLICY, TERMS_AND_CONDITIONS
} = APPFOOTER_COMPONENT;

const AppFooter = () => (
    <Layout.Footer style={{ textAlign: "center", height: "65px" }}>
        <Space size="middle" split="|">
            <Link to={UI_ROUTES.BASE}>{COPYRIGHT}</Link>
            <NewTabLink values={COOKIE_POLICY} />
            <NewTabLink values={PRIVACY_POLICY} />
            <NewTabLink values={TERMS_AND_CONDITIONS} />
        </Space>
    </Layout.Footer>
);

const NewTabLink = ({ values: { URL, TEXT } }) => (
    <Link to={{ pathname: URL }} target="_blank" rel="noreferer">{TEXT}</Link>
);

export default AppFooter;

import React, { useState } from "react";
import axios from "../../utils/axios";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Form, Input, Button, message, Modal, Spin } from "antd";
import { UI_ROUTES, API_ROUTES } from "../../utils/webConstants";

import {
  DEACTIVATE_USER_COMPONENT, AXIOS_CONFIG_VALUES
} from "../../utils/appConstants";

const {
  INFO_MESSAGE_1, INFO_MESSAGE_2, EMAIL_ADDRESS_CANNOT_BE_EMPTY, NOT_AVAILABLE,
  REQUEST_SENT_TO_INITIATOR_LONG, REQUEST_IN_PROGRESS, DEACTIVATED_ALREADY,
  CUSTOMER_MAPPED_WITH_REQUEST_ERROR, REQUEST_SENT_TO_INITIATOR_SHORT,
  USER_MAPPED_WITH_SOME_PROJECT_MESSAGE, FORM_SUBMISSION_ERROR,
} = DEACTIVATE_USER_COMPONENT;

const tailLayout = { wrapperCol: { span: 16 } };

const layout = { labelCol: { span: 10 }, wrapperCol: { span: 10 } };

const DeactivateUser = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { userInfo } = useSelector((state) => state.userLogin);
  const userToken = (userInfo) ? userInfo.userToken : '';

  const resetForm = () => form.resetFields();

  const closeModal = () => setIsModalOpen(false);

  const onFinishFailed = (errorInfo) => message.error(FORM_SUBMISSION_ERROR);

  const onFinish = async (values) => {
    try {
      setLoading(true);

      await axios.post(API_ROUTES.DEACTIVATE_AN_EXTERNAL_USER, values, {
        headers: { [AXIOS_CONFIG_VALUES.AUTHORIZATION]: userToken }
      })
      .then((response) => {
        setLoading(false);
        const resDataMsg = response.data.payload.message;

        if ([NOT_AVAILABLE, DEACTIVATED_ALREADY].includes(resDataMsg)) {
          message.error(resDataMsg);
        }
        else if (resDataMsg === REQUEST_SENT_TO_INITIATOR_SHORT) {
          message.success(REQUEST_SENT_TO_INITIATOR_LONG);
          resetForm();
        }
        else if (resDataMsg === USER_MAPPED_WITH_SOME_PROJECT_MESSAGE) {
          setIsModalOpen(true);
        }
        else {
          message.success(REQUEST_IN_PROGRESS);
          resetForm();
        }
      });
    }
    catch (error) {
      setLoading(false);
      message.error(error.response.data.message);
    }
  };

  const descriptionContent = (
    <div>
      <p>
        {INFO_MESSAGE_1}<br />
        <span style={{ fontWeight: "bold", color: "black" }}>
          Please note:&nbsp;
        </span>
        {INFO_MESSAGE_2}.
      </p>
    </div>
  );

  const modalContent = (
    <Modal open={isModalOpen} onCancel={closeModal} onOk={closeModal}>
      <br /><p>{CUSTOMER_MAPPED_WITH_REQUEST_ERROR}</p>
    </Modal>
  );

  return (
    <>
      {descriptionContent}
      <Spin spinning={loading} tip="Loading..." size="large">
        <Form
          form={form} name="basic" layout="vertical" {...layout}
          style={{ paddingTop: "5px" }} initialValues={{ remember: true }}
          onFinish={onFinish} onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="External User Email Address" name="email"
            style={{ fontWeight: "bold" }}
            rules={[{ required: true, message: EMAIL_ADDRESS_CANNOT_BE_EMPTY }]}
          >
            <Input id="email" type="email" />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">Deactivate</Button>
            <Link to={UI_ROUTES.BASE}>
              <Button style={{ margin: "0 8px" }} onClick={resetForm}>
                Cancel
              </Button>
            </Link>
          </Form.Item>
          {modalContent}
        </Form>
      </Spin>
    </>
  );
};

export default DeactivateUser;

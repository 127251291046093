import { UI_ROUTES, API_ROUTES } from "../../utils/webConstants";
import { Spin, Layout, Alert, Button } from "antd";
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../utils/axios";

import {
    EMAIL_VERIFICATION, AXIOS_CONFIG_VALUES
} from "../../utils/appConstants";

const { CONTENT_TYPE, APPLICATION_JSON } = AXIOS_CONFIG_VALUES;

const VerifyEmail = ({ history }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertType, setAlertType] = useState("info");
    const [showLoginButton, setShowLoginButton] = useState(false);
    const contentHeader = { [CONTENT_TYPE]: APPLICATION_JSON };
    const params = useParams();

    useEffect(() => {
        setLoading(true);

        axios.post(
            API_ROUTES.VERIFY_EMAIL_HASH, { hash: params.hash },
            { headers: contentHeader }
        )
        .then(({ data }) => {
            if (data.status === "success") {
                setLoading(false);

                if (data.payload) {
                    setAlertMsg(data.payload.message);
                    setShowLoginButton(true);
                }
            }
        })
        .catch((error) => {
            console.error('VerifyEmail - useEffect(): ', error);

            if(error.response.status === 400) setError(true);

            setAlertType("error");
            setAlertMsg(error.response.data.message);
            setLoading(false);
        })
    }, []);

    const onResendClick = () => {
        axios.post(
            API_ROUTES.TRIGGER_VERIFICATION_EMAIL, { email: params.hash },
            { headers: contentHeader }
        )
        .then(({ data }) => {
            setLoading(false);
            setAlertType("info");
            setAlertMsg(EMAIL_VERIFICATION.RESEND_EMAIL_VERIFICATION_LINK);
        })
    };

    return (
        <Layout >
            <AppHeader />
            <Layout className="layoutStyle" style={{ textAlign: "center" }}>
                <Spin tip="Loading..." spinning={loading}>
                    <Alert
                        description={alertMsg} type={alertType} closable
                        onClose={() => history.push(UI_ROUTES.BASE)}
                    />
                    <br />
                    {
                        error &&
                        <Button type="primary" onClick={onResendClick}>
                            Resend Verification Link
                        </Button>
                    }
                    {
                        !error && showLoginButton &&
                        <Button
                            type="primary"
                            onClick={() => history.push(UI_ROUTES.LOGIN)}
                        >
                            Click to Login
                        </Button>
                    }
                </Spin>
                <AppFooter />
            </Layout>
        </Layout>
    );
};

export default VerifyEmail;

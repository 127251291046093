import ACTION_TYPES from "../actionTypes/userActionType";

const request = sessionStorage.getItem('request');
const initialState = { request: JSON.parse(request) };

export const requestReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.REQUEST_SELECTED:
            return { request: action.request };

        default:
            return state;
    }
};

import React, { useState, useEffect, useRef } from "react";
import axios from "../../utils/axios";
import assembler from "url-assembler";
import { useDispatch, useSelector } from "react-redux";
import { requestSelected } from "../../redux/actions/requestActions";
import { useHistory } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { io } from 'socket.io-client';
import lodashRound from 'lodash/round';
import { default as axiosWithoutInterceptor } from "axios";
import { API_ROUTES } from "../../utils/webConstants";

import {
  UploadOutlined, ExclamationCircleOutlined, EyeInvisibleOutlined, EyeTwoTone
} from "@ant-design/icons";

import {
  Form, Row, Col, Input, Button, Modal, Checkbox, Tooltip, Progress, Radio,
  Upload, message, Spin
} from "antd";

import {
  FILE_UPLOAD_OBJECT, FILE_UPLOAD_MESSAGES, FILE_UPLOAD_COMPONENT, USERTYPE,
  SOCKETIO_EVENTS, CANCELED_AXIOS_ERROR_CODE, MAX_SIZE_FOR_SINGLE_FILE_UPLOAD,
  PROGRESS_BAR_VALUE_FLOATING_POINTS, MAX_SIZE_FOR_MULTIPLE_FILE_UPLOAD,
  MAX_FILES_PER_UPLOAD, AXIOS_CONFIG_VALUES
} from "../../utils/appConstants";

const { AUTHORIZATION, CONTENT_TYPE, MULTIPART_FORMDATA } = AXIOS_CONFIG_VALUES;

const { confirm } = Modal;
const { Dragger } = Upload;

const FileUpload = (props) => {
  const currentUser = useSelector((state) => state.userLogin);
  const userToken = currentUser?.userInfo?.userToken ?? '';
  const configAuthHeader = { [AUTHORIZATION]: userToken };
  const dispatch = useDispatch();
  let history = useHistory();

  const [socket, setSocket] = useState(null);
  const [dataTypeForm] = Form.useForm();
  const [isPIIOther, setPIIOther] = useState(false);
  const [isInternalProject] = useState(false);
  const [isTestDataOnly, setIsTestDataOnly] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileDataTypes, setFileDataTypes] = useState([]);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isExemptRetentionPeriod, setIsExemptRetentionPeriod] = useState(false);
  const [userGeneratedKeys, setUserGeneratedKeys] = useState(false);
  const [publicKeyFile, setPublicKeyFile] = useState([]);
  const [publicKey, setPublicKey] = useState(null);
  const [manageKeyConfig, setManageKeyConfig] = useState(false);
  const [LNPublicKey, setLNPublicKey] = useState(null);
  const [copied, setCopied] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [progressBarText, setProgressBarText] = useState("Uploading File:");
  const [progressValue, setProgressValue] = useState(0);
  const [abortController] = useState(new AbortController());
  const [cancelButtonDisabled, setCancelButtonDisabled] = useState(false);
  const progressBarRef = useRef();

  React.useEffect(() => {
    const socket = io(API_ROUTES.SOCKETIO_ENDPOINT, { transports: ["polling"] });
    setSocket(socket);

    // Update second half of upload progress (saving to cloud)
    socket.on(SOCKETIO_EVENTS.ENCRYPTION_PROGRESS, (percent) => {
      setProgressValue(percent);
    });
  }, []);

  const handleUploadModalOk = () => {
    const { res, msg } = checkUploadSizeLimits();

    if (res) submitUpload();
    else message.error(msg);
  };

  const checkUploadSizeLimits = () => {
    const fileCount = fileList.length;
    const fileCountIsOne = (fileCount === 1);
    const fileCountIsMoreThanOne = (fileCount > 1);
    const fileCountLimitExceeded = (fileCount > MAX_FILES_PER_UPLOAD);

    const { UPLOAD_LIMIT_EXCEEDED, NO_EMPTY_FILES } = FILE_UPLOAD_MESSAGES;
    const upperLimitHit = { res: false, msg: UPLOAD_LIMIT_EXCEEDED };
    const lowerLimitHit = { res: false, msg: NO_EMPTY_FILES };
    const checksPassed = { res: true, msg: '' };

    let totalSize = 0;

    for (const { size } of fileList) {
      if (size == 0) return lowerLimitHit;

      totalSize += size;
    }

    const singleFileSizeLimitExceeded = (totalSize > MAX_SIZE_FOR_SINGLE_FILE_UPLOAD);
    const multipleFileSizeLimitExceeded = (totalSize > MAX_SIZE_FOR_MULTIPLE_FILE_UPLOAD);

    if (fileCountLimitExceeded) return upperLimitHit;

    if (fileCountIsMoreThanOne && multipleFileSizeLimitExceeded) return upperLimitHit;

    if (fileCountIsOne && singleFileSizeLimitExceeded) return upperLimitHit;

    return checksPassed;
  };

  const handleUploadModalCancel = () => {
    if (confirmLoading && !cancelButtonDisabled) abortController.abort();

    dataTypeForm.resetFields();
    props.onChange(false);
    setFileList([]);
    setChecked(false);
    setPIIOther(false);
    setIsTestDataOnly(false);
    setIsExemptRetentionPeriod(false);
    socket.close();
  };

  const submitUpload = () => {
    dataTypeForm.validateFields()
    .then((values) => {
      const uploadFilesData = new FormData();
      let filesTotalSize = 0;
      let uploadFormData = { body: {} };
      const filesCount = values.upload.length;

      uploadFormData.body[FILE_UPLOAD_OBJECT.FILE_DATATYPES] = (values.fileDataTypes)
        ? values.fileDataTypes.toString()
        : "undefined";

      uploadFormData.body[FILE_UPLOAD_OBJECT.OTHERS] = values.otherDetails;
      uploadFormData.body[FILE_UPLOAD_OBJECT.ISTESTDATA] = checked;
      uploadFormData.body[FILE_UPLOAD_OBJECT.FILE_DESCRIPTION] = "test";
      uploadFormData.body[FILE_UPLOAD_OBJECT.EMPLOYEES] = "";
      uploadFormData.body[FILE_UPLOAD_OBJECT.TESTDATAONLY] = isTestDataOnly;
      uploadFormData.body.exemptRetentionPeriod = isExemptRetentionPeriod;

      if (props?.serviceReqFormData?.serviceReqFormValues) {
        const serviceReqJson = JSON.stringify(
          props.serviceReqFormData.serviceReqFormValues
        );

        uploadFormData.body[FILE_UPLOAD_OBJECT.SERVICEREQJSON] = serviceReqJson;
      }

      if (publicKey && values.iv && values.secret) {
        uploadFormData.body.publicKey = publicKey.toString();
        uploadFormData.body.iv = values.iv;
        uploadFormData.body.secret = values.secret;
      }

      if (props.requestId)
        uploadFormData.body[FILE_UPLOAD_OBJECT.SERVICE_REQUEST_ID] = props.requestId;

      if (props.email)
        uploadFormData.body[FILE_UPLOAD_OBJECT.EMAIL] = props.email;

      if (props.userType && props.userType === "FULFILLER")
        uploadFormData.body[FILE_UPLOAD_OBJECT.INTERNAL_PROJECT] = isInternalProject;

      if (props.parentFolderId)
        uploadFormData.body.parentFolderId = props.parentFolderId;

      if (values.keyManagement)
        uploadFormData.body.keyManagement = values.keyManagement;

      if (props.rootFolderId)
        uploadFormData.body.rootFolderId = props.rootFolderId;

      if (filesCount > 1) setProgressBarText(`Uploading ${filesCount} Files:`);

      values.upload.forEach((item) => {
        filesTotalSize += item.size;
        uploadFilesData.append(FILE_UPLOAD_OBJECT.FILES, item.originFileObj);
      });

      uploadFormData.body.filesCount = filesCount;
      uploadFormData.body.filesTotalSize = filesTotalSize;
      uploadFormData.userToken = userToken;
      setConfirmLoading(true);

      // Scroll to progress bar
      progressBarRef.current.scrollIntoView({
        behavior: 'smooth', block: 'start'
      });

      // Upload files
      axiosWithoutInterceptor(getConfigForUploadingFiles(uploadFilesData))
      .then((response) => {
        uploadFormData.files = response.data;

        // Upload form data
        socket.emit(
          SOCKETIO_EVENTS.UPLOAD_FORMDATA, uploadFormData,
          (callback) => handleUploadResponse(callback)
        );
      })
      .catch((error) => {
        if (error?.code !== CANCELED_AXIOS_ERROR_CODE) {
          console.error('During file upload. ', error);
          setConfirmLoading(false);
          socket.close();
        }
      });
    })
    .catch((errorInfo) => {
      console.error("When submitting file upload form. ", errorInfo);
      setConfirmLoading(false);
      socket.close();
    });
  };

  function getConfigForUploadingFiles(fileData) {
    return {
      method: "post",
      url: API_ROUTES.UPLOAD_FILES,
      //url: API_ROUTES.UPLOAD_FILES_BY_NEW_METHOD,
      data: fileData,
      signal: abortController.signal,
      maxBodyLength: MAX_SIZE_FOR_SINGLE_FILE_UPLOAD,
      headers: {
        [CONTENT_TYPE]: MULTIPART_FORMDATA, [AUTHORIZATION]: userToken
      },
      onUploadProgress: (progressEvent) => handleUploadProgress(progressEvent)
    };
  }

  function handleUploadProgress({ loaded, total }) {
    const percentage = loaded / total * 50;
    const roundedPercentage = lodashRound(percentage, PROGRESS_BAR_VALUE_FLOATING_POINTS);

    // Update first half of upload progress (saving to server)
    setProgressValue(roundedPercentage);

    // Disable the Cancel button
    if (!cancelButtonDisabled && roundedPercentage > 49.5) {
      setCancelButtonDisabled(true);
    }
  }

  function handleUploadResponse(callback) {
    const serviceIdFromApi = callback?.payload?.serviceId;
    setConfirmLoading(false);
    socket.close();
    message.success(FILE_UPLOAD_MESSAGES.UPLOAD_SUCCESS_MESSAGE);

    if (props.serviceReqFormData) {
      props.setIsServiceReqCreateInvoked(true);
      props.setIsLoading(true);
    }
    else {
      dataTypeForm.resetFields();
      setFileList([]);
      setChecked(false);
      setPIIOther(false);
      setIsTestDataOnly(false);
      setIsExemptRetentionPeriod(false);
      props.onSubmit(true);
    }

    props.onChange(false);

    if (serviceIdFromApi) {
      dispatch(requestSelected(serviceIdFromApi, 0, '', 0));
      props.setRequestId(serviceIdFromApi);
    }

    if (props.isReload) props.getData();
  }

  const onChange = (e) => {
    if (e.indexOf("other") > -1) setPIIOther(true);
    else setPIIOther(false);
  };

  const onTestDataCheckboxChange = (e) => {
    if (e.target.checked) {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: 'Only select OK if the file being attached contains non-real/fictitious data',
        centered: true,
        onOk() {
          setIsTestDataOnly(true);
        },
        onCancel() {
          e.checked = false;
          setIsTestDataOnly(false);
        },
      });
    }
    else {
      setIsTestDataOnly(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) return e;

    return e && e.fileList;
  };

  const handleUploadChange = (info) => {
    const filesList = info.fileList.filter(
      file => file.type !== FILE_UPLOAD_COMPONENT.FILE_TYPE_APPLICATION_MSDOWNLOAD
    );

    const fileMap = new Map();

    // lowercase filenames & replace non ascii characters with an underscore
    filesList.forEach(
      item => fileMap.set(item.name.toLowerCase().replace(/[^\x00-\x7F]/g, "_"), item)
    );

    setFileList(Array.from(fileMap.values()));
  };

  const handleKeyUploadChange = (info) => {
    const fileList = info.fileList.filter(
      (file, index) => (info.fileList.length - 1) === index
    );

    if (fileList.length > 0) {
      const fileNameArray = fileList[0].name.split('.');

      if (fileNameArray[fileNameArray.length - 1] !== "pem") {
        message.error("Please upload public key in pem format");
        return;
      }
    }

    setPublicKeyFile(fileList);
    const reader = new FileReader();

    reader.onload = (e) => {
      setPublicKey(e.target.result.toString());
    }

    reader.readAsText(info.file.originFileObj);
  };

  const customRequest = ({ file, onSuccess }) => onSuccess("ok");

  const getfileDataTypes = async () => {
    try {
      setLoading(true);
      const fetchedFileDataTypes = [];
      const urlQuery = { orderBy: ['fileDataType', 'ASC'] };
      const url = assembler(API_ROUTES.GET_FILE_DATA_TYPES).query(urlQuery);
      const res = await axios.get(url, { headers: configAuthHeader });

      res.data.payload.forEach(({ id, fileDataType }) => {
        fetchedFileDataTypes.push({
          key: id, value: id, label: fileDataType
        });
      });

      setFileDataTypes(fetchedFileDataTypes);
      setLoading(false);
    }
    catch (error) {
      console.error("When getting File Data types. ", error);
    }
  };

  const getKeyConfig = async () => {
    try {
      setLoading(true);
      const urlParam = { serviceRequestId: props.requestId };
      const url = assembler(API_ROUTES.GET_KEY).param(urlParam);
      const res = await axios.get(url, { headers: configAuthHeader });

      setManageKeyConfig(res.data.payload.showKeyManagement);
      setLNPublicKey(res.data.payload.publicKey);
      setLoading(false);
    }
    catch (error) {
      console.error("When getting Key Config. ", error);
    }
  };

  useEffect(() => {
    getfileDataTypes();

    if (props.userType === USERTYPE.CUSTOMER) getKeyConfig();
  }, []);

  /* TEMPORARILY DISABLED
  const onExemptRetentionPeriodChange = (e) => {
    if (!isExemptRetentionPeriod) setIsExemptRetentionPeriod(true);
    else setIsExemptRetentionPeriod(false);
  };*/

  const onRadioButtonChange = (e) => {
    if (e.target.value === "userManaged") {
      setUserGeneratedKeys(true);

      dataTypeForm.setFieldsValue({
        LNPublicKey: LNPublicKey, keyManagement: "userManaged"
      });
    }
    else {
      setUserGeneratedKeys(false);
    }
  }

  const progressBar = (
    <span ref={progressBarRef}>
      {progressBarText}
      <Progress
        percent={progressValue} strokeLinecap="butt"
        status={(progressValue < 100) ? "active" : null}
      />
    </span>
  );

  const progressBarContent = (confirmLoading) ? progressBar : null;

  return (
    <Modal
      open={props.isModalOpen}
      title="File Upload"
      okText="Upload"
      form="dataTypeForm"
      onCancel={handleUploadModalCancel}
      onOk={handleUploadModalOk}
      okButtonProps={{ disabled: dataTypeForm.isSubmitting }}
      keyboard={false}
      maskClosable={false}
      confirmLoading={confirmLoading}
      style={{ top: "7vh" }}
      bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 25vh)' }}
      cancelButtonProps={{ disabled: cancelButtonDisabled }}
      closable={cancelButtonDisabled ? false : true}
    >
      <Spin spinning={loading} tip="Loading...">
        <Form
          id="dataTypeForm"
          form={dataTypeForm}
          layout="vertical"
          initialValues={{ LNPublicKey: LNPublicKey, keyManagement: "systemManaged" }}
        >
          {
            !isTestDataOnly && <Form.Item
              name="fileDataTypes"
              label=""
              className="fileUploadLabel"
              rules={[
                {
                  required: true,
                  message: FILE_UPLOAD_MESSAGES.SELECT_DATA_TYPES
                },
              ]}
            >
              <Checkbox.Group
                className="fileDataTypesCheckbox"
                onChange={onChange}
              >
                <section className="fieldset" id="fieldsetId">
                  <h2 className="displayAsterisk">
                    Data types included in the file{" "}
                  </h2>

                  <Row>
                    {fileDataTypes.map(function (dataType, index) {
                      return (
                        <Col key={dataType.value} span={20}>
                          <Checkbox
                            disabled={false}
                            key={dataType.value}
                            value={dataType.value}
                          >
                            {dataType.label}
                          </Checkbox>
                        </Col>
                      );
                    })}

                    <Col span={5}>
                      <Checkbox disabled={false} key="other" value="other">
                        Other
                      </Checkbox>
                    </Col>

                    <Col span={10}>
                      {isPIIOther === true ? (
                        <Form.Item
                          label=""
                          name="otherDetails"
                          rules={[
                            {
                              required: true,
                              message: FILE_UPLOAD_MESSAGES.ENTER_OTHER_DETAILS,
                            },
                          ]}
                        >
                          <Input
                            disabled={false}
                            value="otherDetails"
                            style={{ marginLeft: 10 }}
                          />
                        </Form.Item>
                      ) : null}
                    </Col>
                  </Row>
                </section>
              </Checkbox.Group>
            </Form.Item>
          }

          <Form.Item name="testDataOnly">
            <Checkbox
              value={isTestDataOnly}
              checked={isTestDataOnly}
              onChange={onTestDataCheckboxChange}
            >
              Fictitious/False Data Only
            </Checkbox>
          </Form.Item>

          {
            /* TEMPORARILY DISABLE UNTIL THE API IS FIXED
            props.userType !== USERTYPE.CUSTOMER && props.isServiceReqExternal && props.isServiceReqExternal === true && <Form.Item name="exemptRetentionPeriod">
              <Checkbox
                value={isExemptRetentionPeriod}
                checked={isExemptRetentionPeriod}
                onChange={onExemptRetentionPeriodChange}
              >
                Select if a file retention period extension is required
              </Checkbox>
            </Form.Item>
            */
          }

          {
            props.userType === USERTYPE.CUSTOMER && manageKeyConfig && <Form.Item name="keyManagement" label={
              <div>
                {"Manage Keys "}
                <Tooltip
                  title={`Files are encrypted before upload. By selecting System Managed Keys, the system will manage the encryption/decryption keys. By selecting User Managed Keys, you must provide encryption/decryption key details.`}
                  color="blue"
                >
                  <ExclamationCircleOutlined className="information-icon" />
                </Tooltip>
              </div>
            } rules={[
              {
                required: true,
                message: "Please select",
              },
            ]}>
              <Radio.Group onChange={onRadioButtonChange}>
                <Radio value={"systemManaged"} name="systemManagedKey">System Managed Keys</Radio>
                <Radio value={"userManaged"} name="userManagedKey">User Managed Keys</Radio>
              </Radio.Group>
            </Form.Item>
          }

          {
            props.userType === USERTYPE.CUSTOMER && userGeneratedKeys && manageKeyConfig && <Row>
              <Col span='20'>
                <Form.Item
                  name="LNPublicKey"
                  className="fileUploadLabel"
                >
                  <Input.Password
                    id="LNPublicKey"
                    readOnly
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
              </Col>

              <Col>
                <CopyToClipboard text={LNPublicKey} onCopy={() => setCopied(!copied)}>
                  <Button style={{ width: '100%' }}>{copied ? 'Copied' : 'Copy'}</Button>
                </CopyToClipboard>
              </Col>
            </Row>
          }

          {
            props.userType === USERTYPE.CUSTOMER && userGeneratedKeys && manageKeyConfig && <Form.Item
              name="iv"
              label=""
              className="fileUploadLabel"
              rules={[
                {
                  required: true,
                  message: "Please provide initialization vector",
                },
              ]}
            >
              <Input autoComplete="off" id="iv" placeholder={"Please provide encrypted initialization vector"} />
            </Form.Item>
          }

          {
            props.userType === USERTYPE.CUSTOMER && userGeneratedKeys && manageKeyConfig && <Form.Item
              name="secret"
              label=""
              className="fileUploadLabel"
              rules={[
                {
                  required: true,
                  message: "Please provide secret key",
                },
              ]}
            >
              <Input autoComplete="off" id="secret" placeholder={"Please provide encrypted secret key"} />
            </Form.Item>
          }

          {
            props.userType === USERTYPE.CUSTOMER && userGeneratedKeys && manageKeyConfig && <Form.Item
              name="uploadPublicKey"
              label=""
              getValueFromEvent={normFile}
              className="fileUploadLabel"
              rules={[
                {
                  required: true,
                  message: "Please upload public key in pem format",
                },
              ]}
            >
              <Upload
                onChange={handleKeyUploadChange}
                multiple={false}
                fileList={publicKeyFile}
                customRequest={customRequest}
              >
                <Button icon={<UploadOutlined />} style={{ fontWeight: "bold" }}>
                  Click to Upload Public Key
                </Button>
              </Upload >
            </Form.Item>
          }

          <Form.Item
            name="upload"
            label=""
            getValueFromEvent={normFile}
            className="fileUploadLabel"
            rules={[
              {
                required: true,
                message: FILE_UPLOAD_MESSAGES.UPLOAD_FILE,
              },
            ]}
          >
            <Dragger
              onChange={handleUploadChange}
              multiple={true}
              fileList={fileList}
              customRequest={customRequest}
            >
              <Button icon={<UploadOutlined />} style={{ fontWeight: "bold" }}>
                Drag and Drop or Click to Upload File
              </Button>
            </Dragger >
          </Form.Item>

          {progressBarContent}
        </Form>
      </Spin>
    </Modal>
  );
};

export default FileUpload;

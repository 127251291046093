import { Tag } from "antd";
import { SERVICE_REQUEST_STATUS as STATUS } from "../../utils/appConstants";

import {
  CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";

const cssClass = Object.freeze({
  tag: 'requestStatusTag', icon: 'requestStatusTagIcon'
});

/**
 * @returns custom tag matched by request status
 * @param {String} status
 */
export default function RequestStatusTag({ status }) {
  const { color, icon } = getColorAndIcon(status);

  return (
    <Tag color={color} icon={icon} className={cssClass.tag}>
      {status}
    </Tag>
  );
}

/**
 * @returns color & icon matched by status
 * @param {String} status
 */
function getColorAndIcon(status) {
  switch (status) {
    case STATUS.REVIEWER_APPROVE:
      return {
        color: 'green',
        icon: <CheckCircleOutlined className={cssClass.icon} />
      };

    case STATUS.REVIEWER_REJECT:
      return {
        color: 'red',
        icon: <CloseCircleOutlined className={cssClass.icon} />
      };

    case STATUS.INCOMPLETE_REVIEW:
      return {
        color: 'volcano',
        icon: <ExclamationCircleOutlined className={cssClass.icon} />
      };

    // Initiator - New Request Reviewer
    case STATUS.INITIATOR_NEW_REQUEST_WITH_FILE_UPLOAD:
      return {
        color: 'gold',
        icon: <ClockCircleOutlined className={cssClass.icon} />
      };

    case STATUS.INITIATOR_NEW_REQUEST_FULLFILLER:
      return {
        color: 'purple',
        icon: <ClockCircleOutlined className={cssClass.icon} />
      };

    case STATUS.INITIATOR_NEW_REQUEST_CONTRIBUTOR:
      return {
        color: 'blue',
        icon: <ClockCircleOutlined className={cssClass.icon} />
      };

    default:
      return { color: 'default', icon: null };
  }
}
